import { AccessibleTerminalView } from "ide/TerminalPane/AccTerminal/AccTermModel";
import { TerminalView } from "ide/TerminalPane/Terminal/XTermModel";
import { IDEContext } from "ide/contexts/IDEContext";
import { useContext } from "react";

export const useGetTerminalRef = (runCode, isKarel, endRepl) => {
    const { pyodideClientRef, setIsRunning, screenReadableEditorOn } = useContext(IDEContext);

    const getTerminalRef = () => {
        const pyodideClient = pyodideClientRef.current;
        const handleRepl =  async (mode) => {
            if (mode) {
              setIsRunning(true);
              pyodideClient.setReplSession(endRepl);
            } else {
              setIsRunning(false);
              await pyodideClient.endRepl();
            }
          };


          if(screenReadableEditorOn) {
            return new AccessibleTerminalView(
                "user command :",
                () => runCode(true),
                () => {},
                () => pyodideClient.raisePyStopFlag(),
                isKarel,
                handleRepl,
                (cmd) => pyodideClient.handleReplCommand(cmd)
                );
          } else {
            return new TerminalView(
                "%",
                () => runCode(true),
                () => {},
                () => pyodideClient.raisePyStopFlag(),
                isKarel,
                handleRepl,
                (cmd) =>  pyodideClient.handleReplCommand(cmd)
                );
          }
    };


    return getTerminalRef;
}