import { Message } from 'firebase/realtime/types';
import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, InputGroup } from 'react-bootstrap';
import { FaPaperPlane } from 'react-icons/fa';

interface Props {
    // the user id of the current user. Chat will be from their perspective
    userId: string;
    messages: Message[];
    onSendMessage: (msg: string) => Promise<any>;
}

export const ChatRoom = ({userId, messages, onSendMessage} : Props) => {

    return (
        <div style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <div style={{flex: '10', overflow: 'scroll', marginBottom: '10px', borderBottom: '1px solid lightgray'}}>
                <MessageList messages={messages} userId={userId} />
            </div>
            <div style={{flex: '1'}}>
                <MessageInput onSendMessage={onSendMessage} />
            </div>
        </div>
    )
}

export const MessageList = ({messages, userId} : {messages: Message[], userId: string}) => {
    /*
     * Display the messages as chat bubbles, with the sender's name under the
     * bubble. The current user's messages will be on the right, and other users
     * messages will be on the left.
     *
     * This function also scrolls to the bottom of the chat when a new message
     * is added.
    */

    const messagesEndRef = React.useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    useEffect(scrollToBottom, [messages]);

    return (
        <>
        {messages.map((msg, i) => {
            const isCurrentUser = msg.senderId === userId;
            const isCourseStaff = msg.senderId === 'simba';
            return (
                <MessageBubble key={i} message={msg} isCurrentUser={isCurrentUser} isCourseStaff={isCourseStaff} />
            )
        })}
        <div ref={messagesEndRef} />
        </>
    )
}

const MessageBubble = ({message, isCurrentUser, isCourseStaff} : {message: Message, isCurrentUser: boolean, isCourseStaff: boolean}) => {
    /*
    * Display a single message as a chat bubble. If isCurrentUser = true,
    * messages will be on the right, otherwise messages will be on the left.
    *
    * The sender's name will be displayed under the bubble.
    */

    const style = {
        display: 'flex',
        justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
    } as any

    const backgroundColor = isCourseStaff ? '#efcbef' : isCurrentUser ? 'lightblue' : 'lightgrey';

    return (
        <div style={style}>
        <div style={{
                margin: '5px',
                width: '60%',
            }}
        >
            <div style={{
                whiteSpace: 'pre-line',
                backgroundColor: backgroundColor, borderRadius: '10px', padding: '10px'}}>
                {message.text}
            </div>
            <div style={{fontSize: '10px', color: 'grey'}}>
                {message.senderName}
            </div>
        </div>
        </div>
    )
}




export const MessageInput = ({onSendMessage} : {onSendMessage: (msg: string) => void}) => {
    const MAX_ROWS = 4;
    const [text, setText] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    }

    useEffect(() => {
        // Scroll textarea to bottom when text changes
        inputRef.current.scrollTop = inputRef.current.scrollHeight;

        // const MAX_HEIGHT = 200;
        // @ts-ignore
        // setTextAreaHeight(Math.min(inputRef.current.scrollHeight, MAX_HEIGHT));

    }, [text])


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSendMessage(text);
        setText('');
    }

    const computeNumRows = (text: string) => {
        const lines = text.split("\n");
        return Math.min(lines.length, MAX_ROWS);
    }

    const inputRef = React.useRef(null);


    const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        // Send the message when user preseses Cmd+Enter or Ctrl+Enter (Mac and Windows)
        console.log(event)
        if (event.shiftKey && event.key === 'Enter') {
            // Enter newline
            event.preventDefault();
            setText(text + '\n');

        } else if(event.key == 'Enter') {
            handleSubmit(event as any);
        }
    }
    return (
        <div style={{display: 'flex', alignItems: 'flex-end', height: '100%'}}>
        <Form onSubmit={handleSubmit} style={{width: '100%'}}>
        <FormGroup>
        <InputGroup>
            <Form.Control
                as="textarea"
                rows={computeNumRows(text)}
                value={text}
                onChange={handleChange}
                ref={inputRef}
                onKeyDown={handleEnterSend}
                style={{
                    resize: 'none',
                    height: 'auto',
                    marginRight: '5px'
                }}
            />
            <div style={{marginTop: 'auto'}}>
                <Button style={{display: "block", margin: 'auto', height: '35px', fontSize: '15px'}} type="submit">
                    <FaPaperPlane />
                </Button>
            </div>

        </InputGroup>
        </FormGroup>
        </Form>
        </div>
    )
}
