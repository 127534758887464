import React, { useReducer, useLayoutEffect } from "react";
import { v4 } from "uuid";
import { ThemeProvider } from "styled-components";

import { useDidMountEffect } from "./utils";
import { TreeContext, reducer } from "./state";

import { StyledTree } from "./Tree.style";
import { Folder } from "./Folder/TreeFolder";
import { File } from "./File/TreeFile";

const Tree = ({
  fileStructure,
  onNodeClick,
  onUpdate,
  onChangeFile,
  currFile,
  projectId,
  fullControl,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    currentStructure: fileStructure,
    lastAction: null,
  });

  useLayoutEffect(() => {
    // @ts-ignore
    dispatch({ type: "SET_DATA", payload: fileStructure });
  }, [fileStructure]);

  useDidMountEffect(() => {
    onUpdate && onUpdate(state);
  }, [state]);

  return (
    <ThemeProvider theme={{ indent: 20 }}>
      <TreeContext.Provider
        value={{
          state,
          dispatch,
          onNodeClick: (node) => {
            onNodeClick && onNodeClick(node);
          },
        }}
      >
        <StyledTree>
          <TreeRecusive
            data={state.currentStructure}
            parentNode={state.currentStructure}
            onChangeFile={onChangeFile}
            currFile={currFile}
            origin={true}
            projectId={projectId}
            fullControl={fullControl}
          />
        </StyledTree>
      </TreeContext.Provider>
    </ThemeProvider>
  );
};

const TreeRecusive = ({
  data,
  parentNode,
  onChangeFile,
  currFile,
  origin,
  projectId,
  fullControl,
}) => {
  return data.map((item) => {
    item.parentNode = parentNode;
    if (!parentNode) {
      item.parentNode = data;
    }
    if (!item.id) item.id = v4();

    if (item.type === "file") {
      return (
        <File
          key={item.id}
          id={item.id}
          projectId={projectId}
          name={item.name}
          format={item.format}
          url={item.url}
          node={item}
          currFile={currFile}
          starterFile={item.starterFile}
        />
      );
    }
    if (item.type === "folder") {
      return (
        <Folder
          key={item.id}
          id={item.id}
          name={item.name}
          node={item}
          rootFolder={origin}
          fullControl={fullControl}
          projectId={projectId}
        >
          <TreeRecusive
            parentNode={item}
            data={item.files}
            onChangeFile={onChangeFile}
            currFile={currFile}
            origin={false}
            projectId={projectId}
            fullControl={fullControl}
          />
        </Folder>
      );
    }
  });
};

Tree.File = File;
Tree.Folder = Folder;

export default Tree;
