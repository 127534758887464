import { useContext, useEffect } from "react";
import { CollabSession } from "../Session";
import { CollabContext } from "../CollabContext";
import { IDEContext } from "../../contexts/IDEContext";
import { getAuth } from "firebase/auth";


export const useCollabSession = (onFileChange, currentFile) => {
    const { sessionRef, setIsSessionRefLoaded, setCollaborators, clientId, setClientId } = useContext(CollabContext)
    const { projectData, terminalRef } = useContext(IDEContext) 
    const projectId = projectData?.uid;

    // Setup collab session
    useEffect(() => {
        const auth = getAuth()
        const user = auth.currentUser
        const roomName = getUniqueProviderRoom(projectId)
        /** @typedef {CollabSession} */
        // onUpdate function is unused currently
        sessionRef.current = new CollabSession(roomName, user, (updateData) => {}, projectData)
        setIsSessionRefLoaded(true)

        return () => {
            sessionRef.current.destroy()
        }

    }, [])

}


/**
 * 
 * @param {*} firebaseUrl 
 * @returns a room name
 */
const getUniqueProviderRoom = (firebaseUrl) => {
    const SALT = "codeinplace250419829";
    return SALT + firebaseUrl.replaceAll("/", "-");
  };