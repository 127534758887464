import React, { useState, useContext } from "react";
import { Button, Form } from 'react-bootstrap';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { LoginContext, LoginScreen } from "./LoginContext";
import { LogInLinkButton, PrimaryLogInButton } from "./SignInButton";

export const EmailPasswordForm = ({
  auth,
  showSignIn,
}) => {
  const [loginState, setLoginState] = useContext(LoginContext)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordMatch, setPasswordMatch] = useState("")
  const [error, setError] = useState("")
  const url = window.location.origin + window.location.pathname
  const errorMessages = [
    "invalid-password",
    "auth/invalid-email",
    "auth/user-not-found",
    "auth/wrong-password",
    "auth/missing-password",
  ]
  const actionCodeSettings = {
    url: url,
    handleCodeInApp: true,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (showSignIn) {
        await signInWithEmailAndPassword(auth, email, password)
        setLoginState(LoginScreen.UNVERIFIED)

      } else { //sign up page
        if (password.length < 8) {
          throw new Error("invalid-password")
        }
        if (password != passwordMatch) {
          throw new Error("no-match")
        }
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        await sendEmailVerification(userCredential.user, actionCodeSettings)
        setLoginState(LoginScreen.UNVERIFIED)
      }
    } catch (error) {
      setError(error.code ?? error.message)
    }
  }


  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control
          type="email"
          placeholder="Enter email"
          onChange={(e) => setEmail(e.target.value)}
          required
          isInvalid={error == "auth/email-already-in-use"}
          aria-label="Enter email"
        />
        <Form.Control.Feedback type="invalid">
          This email is already in use.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Control
          type="password"
          placeholder="Enter password"
          onChange={(e) => setPassword(e.target.value)}
          isInvalid={errorMessages.includes(error)}
          aria-label="Enter password"
        />
        <Form.Control.Feedback type="invalid">
          {error == "invalid-password" ? "Invalid password. Make sure your password is at least 8 characters long." :
            "Incorrect login credentials. If you forgot your password or did not set one, click the Forgot Password button below."
          }
        </Form.Control.Feedback>
      </Form.Group>
      {showSignIn ?
        <LogInLinkButton
          onClick={() =>
            setLoginState(LoginScreen.FORGOT_PASWORD)
          }
          size='sm'
          variant="link"
          className="mb-3">
          Forgot password?
        </LogInLinkButton>
        :
        <Form.Group className="mb-3" controlId="formPasswordVerification">
          <Form.Control
            type="password"
            placeholder="Re-enter password"
            onChange={(e) => setPasswordMatch(e.target.value)}
            isInvalid={error == "no-match"}
            aria-label="Re-enter password"
            required
          />
          <Form.Control.Feedback type="invalid">
            Passwords don't match.
          </Form.Control.Feedback>
        </Form.Group>
      }
      <PrimaryLogInButton variant="primary" type="submit" style={{ width: "100%" }}>
        {showSignIn ? "Sign In" : "Create Account"}
      </PrimaryLogInButton>
    </Form>
  )
}