import React, { useRef, createContext, useState, useEffect } from "react";


type CollabContextType = {
    sessionRef: any,
    isSessionRefLoaded: boolean,
    setIsSessionRefLoaded: (val: boolean) => void,
    collaborators: any,
    setCollaborators: (val: any) => void,
    clientId: string,
    setClientId: (val: string) => void
}

const defaultValues = {
    sessionRef: null,
    isSessionRefLoaded: false,
    setIsSessionRefLoaded: (val) => {},
    collaborators: {},
    setCollaborators: (val) => {},
    clientId: "",
    setClientId: (val) => {}
}



export const CollabContext = createContext<CollabContextType>(defaultValues);

export const CollabProvider: React.FC<CollabContextType & { children: React.ReactNode }> = ({ children }) => {
    const sessionRef = useRef(null);

    const [isSessionRefLoaded, setIsSessionRefLoaded] = useState(defaultValues.isSessionRefLoaded);
    const [collaborators, setCollaborators] = useState(defaultValues.collaborators);
    const [clientId, setClientId] = useState(defaultValues.clientId);

    return (
        <CollabContext.Provider value={{ sessionRef, isSessionRefLoaded, setIsSessionRefLoaded, collaborators, setCollaborators, clientId, setClientId }}>
            {children}
        </CollabContext.Provider>
    )
}