import { NavigationHistoryContext } from "contexts/NavigationContext";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { FaChevronLeft, FaHome } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

export const HomeExitButton = () => {
  const {getPreviousLocation} = useContext(NavigationHistoryContext)
  let navigate = useNavigate(); // don't use history navigate here
  let location = useLocation();
  const courseId = useCourseId()
  const homeUrl = getHomeUrl(courseId)
  const previousLocation = getPreviousLocation()

  let toUrl = getOnwardsUrl(courseId, location, previousLocation)
  let isExit = toUrl != homeUrl

  return <Link
    className="btn btn-primary btn-sm mr-2 ml-2 cursor-pointer"
    to={toUrl}
  >
    <label className="visually-hidden">Home</label>
    {isExit? <FaChevronLeft/> : <FaHome />}
  </Link>
}

export function getOnwardsUrl(courseId, location, previousLocation) {
  const homeUrl = getHomeUrl(courseId)
  let toUrl = homeUrl
  
  // lessons sends us here with state, which takes precedence
  if(location.state?.from) {
    toUrl = location.state.from
  } else if(previousLocation) {
    toUrl = previousLocation
  }
  console.log('toUrl', toUrl)
  return toUrl
}

function getHomeUrl(courseId) {
  const currUrl = window.location.href

  // student application
  if (currUrl.includes("join/ide")) {
    return `/${courseId}/join`
  }

  // section leader application
  if (currUrl.includes("applyteach/ide")) {
    return `/${courseId}/applyteach`
  }

  // special case for cs106a website
  if (courseId === 'spr23') {
    return `/${courseId}/code`
  }

  if (courseId === "cip3") {
    return `/${courseId}`
  }

  if (courseId === "public") {
    return `/${courseId}`
  }

  // default is to send you back to the home
  return `/`
}