// The different chat experience types

import { BasicChat } from "./BasicChat";
import { GroundedChat } from "./GroundedChat";
import { ButtonsChat } from "./ButtonsChat";
import { IDEChat } from "./IDEChat";
import { CommunityChat } from "./CommunityChat";

export const CHATBOT_NAME = "Chat"; // Cai, Codey, Code Queen, Grumpy Chat, Potato

// export const CHAT_INTRO_MESSAGE = `Hello! I'm ${CHATBOT_NAME}, the chatbot for Code in Place. I'm here to answer questions and discuss the course content as you work through the lesson. Feel free to ask me anything, happy learning! 🌱`;
export const CHAT_INTRO_MESSAGE = `This is your space to ask questions as you work through the lesson. Happy learning! 🌱`;

export const COMMUNITY_INTRO_MESSAGE = `This is your space to connect with other students who are working through this lesson at the same time as you. Don't hesitate to ask questions or help out others. Let's learn and grow together! 🌱`;

// export const BUTTONS_CHAT_INTRO_MESSAGE = `Hello! I'm ${CHATBOT_NAME}, the AI chatbot for Code in Place. I'm here to help you understand the course content as you work through the lesson. Use the buttons below if you want me to explain the material differently or summarize what just happened. Happy learning! 🌱`;
export const BUTTONS_CHAT_INTRO_MESSAGE = `Use the buttons below to get a different explanation or a summary of what just happened. Happy learning! 🌱`;

// export const IDE_CHAT_INTRO_MESSAGE = `Hello! I'm ${CHATBOT_NAME}, the AI chatbot for Code in Place. I'm here to help as you work through this assignment. Feel free to ask me anything, happy learning! 🌱`;
export const IDE_CHAT_INTRO_MESSAGE = `This is your space to ask questions as you work through the assignment. Happy learning! 🌱`;

export const CHAT_NAMES = {
    NO_CHAT: "No chat",
    GROUNDED: "Grounded",
    BASIC: "Basic",
    COMMUNITY: "Community",
    BUTTONS: "Buttons",
    IDE: "IDE"
}

export const CHAT_TYPES = {
    0: null, // No chat
    1: { name: CHAT_NAMES.GROUNDED, component: <GroundedChat />, intro_message: CHAT_INTRO_MESSAGE },
    2: { name: CHAT_NAMES.BASIC, component: <BasicChat />, intro_message: CHAT_INTRO_MESSAGE },
    3: { name: CHAT_NAMES.COMMUNITY, component: <CommunityChat />, intro_message: COMMUNITY_INTRO_MESSAGE },
    4: { name: CHAT_NAMES.BUTTONS, component: <ButtonsChat />, intro_message: BUTTONS_CHAT_INTRO_MESSAGE },
    5: { name: CHAT_NAMES.IDE, component: <IDEChat />, intro_message: IDE_CHAT_INTRO_MESSAGE },
};

export const MESSAGE_RETRIEVAL_LIMIT = 25;

export const CHAT_INTRO_SLIDE_ID = "unique-chatbot-intro-slide-1997";

export const AI_ID = "ai";
export const CHAT_ROLES = {
    USER: "user",
    ASSISTANT: "assistant"
}

export const MESSAGE_LIMIT = "MESSAGE_LIMIT";
export const FLAGGED_POST = "FLAGGED_POST";
export const MESSAGES_LIMIT_MESSAGE = "Message limit reached. Chat will resume in the next lesson!";
export const FLAGGED_POST_MESSAGE = "Your message has been flagged for inappropriate content. It will not be posted to the chat.";
export const CHAT_SERVICE_ERROR_MESSAGE = "There was an error with the chat service. Please try again.";
export const ERROR_TOAST_DURATION = 10000;