
import { checkIsProjectKarel, checkIsProjectConsole, checkIsProjectGraphics } from "ide/utils/general";
import Swal from "sweetalert2";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { areWorldsEqual, areOutputsEqual } from "components/pyodide/KarelLib/util";
import { useCourseId } from "hooks/router/useUrlParams";
import { useContext } from "react";
import { IDEContext } from "ide/contexts/IDEContext";

import { deepCopy } from "@firebase/util";
import { UnitTestResults, emptyTestResult } from "./UnitTestResults";
import { autograderRunToast, runGPTAutograderRaw, unableToRunToast } from "./gptAutograder/gptAutograder";
import { useUserId } from "hooks/user/useUserId";
import { getScoreFromGPTResponse } from "./gptAutograder/utils";
import { hasAiUnitTests } from "./runUnitTestsAndReportResults";

/**
 * Function: runUnitTests
 * -----------------------
 * @author: Chris Piech. Big refactor March 10th 2024
 * 
 * Runs the unit tests for the project. There are several types of unit tests:
 *  - Karel Unit Tests:  
 *  - Standard Console Output Unit Tests: 
 *  - GPT Unit Tests (run on the server)
 * Returns a TestResults object (see UnitTestResults.ts)
 * This function does not create any side effects (eg log a result to the server, etc.)
 * 
 * This function is only called by runUnitTestsAndReportResults 
 * 
 * Assumptions: 
 *  - We assume that all unit testable projects use main.py as the main file.
 *  - All unit tests are in assnData?.unitTests?.unitTests
 *  - All console tests have a "pre" state
 * 
 */
export async function runUnitTests(ideContext): Promise<UnitTestResults> {


  // collect the necessary data
  const assnData = ideContext.assnData
  const courseId = ideContext.courseId
  const projectData = ideContext.projectData
  const isDiagnostic = ideContext?.isDiagnostic
  const isKarel = checkIsProjectKarel(projectData, assnData);
  const isConsole = checkIsProjectConsole(projectData, assnData);
  const code = ideContext.codeToRun.current;
  const pyodideClient = ideContext.pyodideClientRef.current;

  console.log('run unit tests')

  // no unit tests for diagnostics
  if (isDiagnostic) {
    return emptyTestResult
  }

  // no unit tests for a creative assignment
  console.log('assnData', assnData)

  // check for gpt tests
  if (hasAiUnitTests(ideContext)) {
    return await runAiAutograder(code, courseId, assnData)
  }

  // karel and console have standard unit tests
  const standardUnitTests = assnData?.unitTests?.unitTests
  if (isKarel) {
    return await runKarelUnitTests(code, standardUnitTests, pyodideClient)
  }
  if (isConsole) {
    return await runStandardConsoleUnitTests(code, standardUnitTests, pyodideClient)
  }

  return emptyTestResult
}


/****************************************************************************
 *                      Helper Functions                                    *
 ****************************************************************************/

// Main function for running standard console output unit tests. 
async function runStandardConsoleUnitTests(code, unitTests, pyodideClient): Promise<UnitTestResults> {
  // make sure that the unitTests are valid
  if (!unitTests || unitTests.length == 0) {
    return emptyTestResult
  }
  const testResults = []
  for (let i = 0; i < unitTests.length; i++) {
    const unitTest = unitTests[i]
    if (unitTest.pre) {
      const currentFile = { name: "main.py" }
      const rawResult = await pyodideClient.testCode(code, unitTests[i].pre, currentFile);
      const expectedPost = unitTest["post"]
      const observedPost = rawResult.output
      const correctOutput = areOutputsEqual(expectedPost, observedPost)
      const hasError = rawResult.error && rawResult.error.length > 0
      const isSuccess = !hasError && correctOutput
      testResults.push({
        isSuccess,
        name: unitTest.name,
        usedAi: false,
        rawResult: rawResult
      })
    } else {
      console.error('unit test pre condition is empty')
    }
  }
  return { testResults, testsRun: true }
}


// Main function for running karel unit tests. Returns a TestResults object.
async function runKarelUnitTests(code, unitTests, pyodideClient): Promise<UnitTestResults> {
  // make sure that the unitTests are valid
  if (!unitTests || unitTests.length == 0) {
    return emptyTestResult
  }

  const testResults = []
  for (let i = 0; i < unitTests.length; i++) {
    pyodideClient.setKarelInfo(deepCopy(unitTests[i].pre), (state) => { }, 0);
    const currentFile = { name: "main.py" }
    const rawResult = await pyodideClient.testCode(code, [], currentFile);
    const observedPost = rawResult.karel
    const expectedPost = unitTests[i]["post"]
    const hasError = rawResult.error && rawResult.error.length > 0
    const worldsEqual = areWorldsEqual(expectedPost, observedPost)
    const isSuccess = !hasError && worldsEqual
    testResults.push({
      isSuccess,
      name: unitTests[i].name,
      usedAi: false,
      rawResult: rawResult
    })
  }
  return {
    testsRun: true,
    testResults
  };
}

async function runAiAutograder(code, courseId, assnData): Promise<UnitTestResults> {
  const assnId = assnData?.metaData.uid
  try {
    const gptResponse = await runGPTAutograderRaw({code, courseId, assnId})
    // gptResponse = await gptAutogradePromise;
    // if (editedDuringTestsPromise.current && editedDuringTestsPromise.current.promise) {
    // the code has not been edited while awaiting the autograder
    const { isCorrect } = getScoreFromGPTResponse(gptResponse)
    autograderRunToast.close()
    // editedDuringTestsPromise.current.resolve();
    // editedDuringTestsPromise.current = null;
    return {
      testsRun: true,
      testResults: [{
        isSuccess: isCorrect,
        name: 'GPT Autograder',
        usedAi: true,
        rawResult: gptResponse
      }],
    }

  } catch (error) {
    // any errors are logged within the runGPTAutograder function
    unableToRunToast.fire()
    return {
      testsRun: false,
      testResults: [{
        isSuccess: false,
        name: 'GPT Autograder',
        usedAi: true,
        rawResult: error
      }],
    }
  }
}
