import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { doc, getFirestore, getDocs, collection, setDoc, addDoc, deleteDoc, updateDoc, where, query, or, and } from "firebase/firestore";
import { arrayMove } from '@dnd-kit/sortable';
import { alertError } from 'components/completion/Alerts';
import { roleToFirebaseString, isMinimumRole } from 'contexts/ProfileUtil';
import { ProfileContext, Role } from 'contexts/ProfileContext';
import { CourseContext } from 'contexts/CourseContext';
import { getCurrTime } from "components/timezones/timezoneHelper";

interface RoadmapItem {
  type: string,
  selfCheck: boolean,
  completionPath?: string,
  title: string,
  id: string,
  isStudentModule: boolean
}

interface RoadmapContextType {
  roadmapItemMap: { [key: string]: RoadmapItem[] },
  getCurrentTeachingModule: () => any,
  deleteModuleFromDB: (moduleId: string) => void,
  createModuleInDB: (modInfo) => void,
  deleteItemFromDB: (itemId: string, moduleId: string) => void,
  createItemInDB: (item: any, moduleId: string) => void,
  editModuleInDB: (moduleId: string, newData: any) => void,
  editItemInDB: (moduleId, newItem) => void,
  swapItemOrderInDB: (active, over, moduleId) => void,
  getCommentPath: (moduleId: string) => string,
  addModuleImageLink: (url: string, moduleId: string) => void,
  roadmapType: string,
  setRoadmapType: (role: string) => void,
  getAssignmentsInRoadmap: (onlyReleased?: boolean) => any[]
  getLessonsInRoadmap: (onlyReleased?: boolean) => any[]
  getTeacherLessonsInRoadmap: (onlyReleased?: boolean) => any[]
}


// Create the context with initial undefined value
export const RoadmapContext = createContext<RoadmapContextType | undefined>(undefined);


interface RoadmapProviderProps {
  children: ReactNode;
  courseId: string;
}


export const RoadmapProvider: React.FC<RoadmapProviderProps> = ({ courseId, children }) => {

  const [roadmapItemMap, setRoadmapItemMap] = useState({})
  const { userData } = useContext(ProfileContext)
  const db = getFirestore()
  const { isCourseAsynchronous } = useContext(CourseContext)
  const [roadmapType, setRoadmapType] = useState("student")
  const todayStr = getCurrTime()

  const courseRole = userData?.courseRole;

  const roadmapModulesCollectionRef = (courseRole) => {
    switch (courseRole) {
      case Role.ADMIN:
        return collection(db, `roadmap/${courseId}/modules`)
      case Role.SECTION_LEADER:
        return query(collection(db, `roadmap/${courseId}/modules`))
      case Role.STUDENT:
        return query(collection(db, `roadmap/${courseId}/modules`), and(where("roadmapType", "==", roleToFirebaseString(Role.STUDENT))))
      case Role.EXPERIENCED_STUDENT:
        return query(collection(db, `roadmap/${courseId}/modules`), and(where("roadmapType", "==", roleToFirebaseString(Role.EXPERIENCED_STUDENT))))
      default:
        return query(collection(db, `roadmap/${courseId}/modules`))

    }
  }

  const getCurrentTeachingModule = async () => {
    // fetch modules which are active and not student modules
    const currentModuleQuery = query(collection(db, `roadmap/${courseId}/modules`), where("roadmapType", "==", "sl"))
    const modules = await getDocs(currentModuleQuery)
    if (modules.empty) {
      return null
    }
    const sortedModules = modules.docs
      .sort((a, b) => a.data().startDate > b.data().startDate ? 1 : -1);
    // NOTE only returns one module -- the one with the most recent start date
    return sortedModules.pop()
  }



  useEffect(() => {
    const fillRoadmapItemMap = async () => {
      const roadmap = {}
      const modules = await getDocs(roadmapModulesCollectionRef(courseRole))
      for (var module of modules.docs) {
        roadmap[module.id] = module.data()
      }
      setRoadmapItemMap(roadmap)
    }
    fillRoadmapItemMap()
  }, [courseRole])

  const createModuleInDB = async (modInfo) => {
    const moduleDocRef = collection(db, `roadmap/${courseId}/modules`)
    let moduleId;
    try {
      const docResp = await addDoc(moduleDocRef, {
        ...modInfo,
        roadmapType: roadmapType,
        items: []
      });
      moduleId = docResp.id;
    } catch (e) {
      alertError(e);
      return;
    }
    setRoadmapItemMap(prevModules => ({
      ...prevModules,
      [moduleId]: {
        ...modInfo,
        items: [],
        roadmapType: roadmapType,
      }
    }));

  }


  const deleteModuleFromDB = async (moduleId) => {
    const moduleDocRef = doc(db, `roadmap/${courseId}/modules/${moduleId}`)
    const descriptionDocRef = doc(db, `roadmap/${courseId}/descriptions/${moduleId}`)

    try {
      await deleteDoc(moduleDocRef)
      await deleteDoc(descriptionDocRef)

    } catch (e) {
      alertError(e);
      return;
    }
    setRoadmapItemMap(prevModules => {
      const newState = { ...prevModules };
      delete newState[moduleId];
      return newState;
    });
  }



  const createItemInDB = async (item, moduleId) => {
    let itemId = item.id;
    if (!itemId) {
      return;
    }
    try {
      await updateDoc(doc(db, `roadmap/${courseId}/modules/${moduleId}`), {
        items: [...roadmapItemMap[moduleId].items, item]
      });
    } catch (e) {
      alertError(e);
      return;
    }

    setRoadmapItemMap(prevModules => {
      return {
        ...prevModules,
        [moduleId]: {
          ...prevModules[moduleId],
          items: [...prevModules[moduleId].items, item]
        }
      };
    });
  }




  const deleteItemFromDB = async (itemId, moduleId) => {
    const filteredItems = roadmapItemMap[moduleId].items.filter(item => item.id !== itemId);
    try {
      await updateDoc(doc(db, `roadmap/${courseId}/modules/${moduleId}`), {
        items: filteredItems
      });
    } catch (e) {
      alertError(e);
      return;
    }

    setRoadmapItemMap(prevModules => {
      if (!prevModules[moduleId]) return prevModules; // module does not exist, nothing to update

      return {
        ...prevModules,
        [moduleId]: {
          ...prevModules[moduleId],
          items: filteredItems
        }
      };
    });
  }


  const editModuleInDB = async (moduleId, newData) => {
    // Add firestore setDoc path here
    try {
      await updateDoc(doc(db, `roadmap/${courseId}/modules/${moduleId}`), newData);
    } catch (e) {
      alertError(e);
      return;
    }

    setRoadmapItemMap(prevData => ({
      ...prevData,
      [moduleId]: {
        ...prevData[moduleId],
        ...newData,
        items: prevData[moduleId].items
      }
    }));
  }


  const editItemInDB = async (moduleId, newItem) => {
    let itemId = newItem.id;
    if (!itemId) {
      return;
    }
    let editedItemIdx = -1;
    const items = roadmapItemMap[moduleId].items;

    for (var itemIdx = 0; itemIdx < items.length; itemIdx++) {
      if (items[itemIdx].id === itemId) {
        editedItemIdx = itemIdx;
        break;
      }
    }

    if (editedItemIdx < 0) { return; }

    let moduleData = { ...roadmapItemMap[moduleId] };
    moduleData.items[editedItemIdx] = newItem;

    try {
      await updateDoc(doc(db, `roadmap/${courseId}/modules/${moduleId}`), moduleData);
    } catch (e) {
      alertError(e);
      return;
    }


    setRoadmapItemMap(prevData => {
      return {
        ...prevData,
        [moduleId]: { ...moduleData }
      };
    });
  }

  const swapItemOrderInDB = async (active, over, moduleId) => {

    const items = roadmapItemMap[moduleId].items
    const oldIdx = items.findIndex(obj => obj.id === active.id)
    const newIdx = items.findIndex(obj => obj.id === over.id)
    const newList = arrayMove(items, oldIdx, newIdx);

    setRoadmapItemMap(prevRoadmap => {

      return {
        ...prevRoadmap,
        [moduleId]: {
          ...prevRoadmap[moduleId],
          items: newList
        }
      }
    })

    try {
      await updateDoc(doc(db, `roadmap/${courseId}/modules/${moduleId}`), {
        items: newList
      });
    } catch (e) {
      alertError(e);
      setRoadmapItemMap(prevRoadmap => {

        return {
          ...prevRoadmap,
          [moduleId]: {
            ...prevRoadmap[moduleId],
            items: items
          }
        }
      })
      return;
    }

  }



  const addModuleImageLink = async (url, moduleId) => {
    const moduleDocRef = doc(db, `roadmap/${courseId}/modules/${moduleId}`)

    await setDoc(moduleDocRef, {
      thumbnailUrl: url
    }, { merge: true })


    setRoadmapItemMap(prevModules => {
      return {
        ...prevModules,
        [moduleId]: {
          ...prevModules[moduleId],
          thumbnailUrl: url
        }
      }
    })
  }




  const getCommentPath = (moduleId) => {
    return `roadmap/${courseId}/modules/${moduleId}/announcement`
  }


  const getAssignmentsInRoadmap = (onlyReleased=false) => {
    const todayStr = getCurrTime()
    const modules = Object.keys(roadmapItemMap).map((moduleId) => {
       return { ...roadmapItemMap[moduleId], id: moduleId } 
    })
    .filter((module) => module.isStudentModule && (!onlyReleased || module.startDate <= todayStr))
    .sort((a, b) => { return a.startDate > b.startDate ? 1 : -1 })
    let assignments = []
    for (const module of modules) {
      const assns = module.items.filter((item) => item.itemType === "Assignment").sort((a, b) => { return a.startDate > b.startDate ? 1 : -1 })
      for (const item of assns) {
        assignments.push({
          assnGroup: {
            title: module.title,
            id: module.id
          },
          assnId: item.urlId,
          title: item.title,
        })

      }
    }
    return assignments
  }

  const getLessonsInRoadmap = (onlyReleased=false) => {
    const modules = Object.keys(roadmapItemMap).map((moduleId) => {
       return { ...roadmapItemMap[moduleId], id: moduleId } }).filter((module) => module.isStudentModule && (!onlyReleased || module.startDate <= todayStr)).sort((a, b) => { return a.startDate > b.startDate ? 1 : -1 
    })
    let lessons = []
    for (const module of modules) {
      const les = module.items.filter((item) => item.itemType === "Lesson").sort((a, b) => { return a.startDate > b.startDate ? 1 : -1 })
      for (const item of les) {
        lessons.push({
          lessonGroup: {
            title: module.title,
            id: module.id
          },
          lessonId: item.urlId,
          title: item.title,
        })

      }
    }
    return lessons
  }

  const getTeacherLessonsInRoadmap = (onlyReleased=false) => {
    const modules = Object.keys(roadmapItemMap).map((moduleId) => {
       return { ...roadmapItemMap[moduleId], id: moduleId } })
       .filter(
        (module) => !module.isStudentModule && (!onlyReleased || module.startDate <= todayStr)
      ).sort((a, b) => { return a.startDate > b.startDate ? 1 : -1 
    })
    let lessons = []
    for (const module of modules) {
      const les = module.items.filter((item) => item.itemType === "Lesson").sort((a, b) => { return a.startDate > b.startDate ? 1 : -1 })
      for (const item of les) {
        lessons.push({
          lessonGroup: {
            title: module.title,
            id: module.id
          },
          lessonId: item.urlId,
          title: item.title,
        })

      }
    }
    return lessons
  }


  return (
    <RoadmapContext.Provider value={{
      roadmapItemMap,
      getCurrentTeachingModule,
      createModuleInDB,
      deleteModuleFromDB,
      createItemInDB,
      deleteItemFromDB,
      editModuleInDB,
      editItemInDB,
      getCommentPath,
      swapItemOrderInDB,
      addModuleImageLink,
      roadmapType,
      setRoadmapType,
      getAssignmentsInRoadmap,
      getLessonsInRoadmap,
      getTeacherLessonsInRoadmap
    }}>
      {children}
    </RoadmapContext.Provider>
  )
}