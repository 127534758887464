import { getOnwardsUrl } from "ide/HomeExitButton";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import { hasAiUnitTests, runUnitTestsAndReportResults } from "./runUnitTestsAndReportResults";
import { useContext } from "react";
import { NavigationHistoryContext } from "contexts/NavigationContext";
import { useUserId } from "hooks/user/useUserId";
import { useLocation, useNavigate } from "react-router";
import { PyodideContext } from "components/pyodide/PyodideProvider";
import { IDEContext } from "ide/contexts/IDEContext";
import { useCourseId } from "hooks/router/useUrlParams";
import { isCreativeProject } from "ide/utils/general";

export const UnitTestButton = () => {
  const ideContext = useContext(IDEContext)
  const {
    isRunning,
    setIsRunning,
    aiAutograderRunning
  } = ideContext
  const { isPyodideLoading } = useContext(PyodideContext)
  const courseId = useCourseId()
  const userId = useUserId();
  const navigate = useNavigate()
  const location = useLocation()
  const { getPreviousLocation } = useContext(NavigationHistoryContext)
  const previousLocation = getPreviousLocation()

  const usesAi = hasAiUnitTests(ideContext)

  if(isCreativeProject(ideContext.projectData)) {
    return <></>
  }

  const testButtonClicked = async () => {
    if (!usesAi) {
      // you need to turn off the run button
      // so that there isn't a pyodide collision
      setIsRunning(true)
    }

    await runUnitTestsAndReportResults({
      ideContext,
      navigate,
      userId,
      courseId,
      onwardsUrl: getOnwardsUrl(courseId, location, previousLocation)
    })

    if (!usesAi) {
      console.log('turning off the run button')
      // turn the run button back on!
      setIsRunning(false);
    }
  }

  const isPyodideBlocked = (isRunning || isPyodideLoading) && !usesAi
  const isAiGraderBlocked = aiAutograderRunning && usesAi
  const isDisabled = isPyodideBlocked || isAiGraderBlocked

  return <>
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 100 }}
      overlay={
        <Tooltip id="button-tooltip">
          Run tests that will check if your code meets the specifications.
        </Tooltip>}
    >
      <button
        className="btn btn-outline-dark btn-sm "
        onClick={testButtonClicked}
        disabled={isDisabled}
      >{"Check Correct"}</button>
    </OverlayTrigger>
  </>
}

