import { Landing } from "landing/home"
import { IdeLandingNav } from "./IdeLandingNav"
import { IdeLandingSidebar } from "./IdeLandingSidebar"
import { useWindowSize } from "react-use-size"
import { useProSidebar } from "react-pro-sidebar"
import styled from "styled-components"
import { Outlet } from "react-router"

export const IdeLandingPage = () => {
  return <PageOuter>
    {/* <IdeLandingNav /> */}

    <UnderNav>
      <IdeLandingSidebar />
      <OutletOuter>
        <OutletInner>
          <Outlet />
        </OutletInner>
      </OutletOuter>
    </UnderNav>
  </PageOuter>
}

const PageOuter = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  
`

const OutletInner = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  max-width: 1500px;
`

const OutletOuter = styled.main`
  display: flex;
  width: 100%;
  overflow: auto;
  background-color:white;
  margin: 10px;
  margin-left:0px;
  padding: 10px;
  padding-left:20px;
  border-radius: 10px;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 0px;
    margin-top: 0px;
    padding: 5px;
    padding-top: 10px;
    border-radius: 0px;
  }
`

const UnderNav = styled.div`
  background-color: #f8f9fa;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  height:0px;
`