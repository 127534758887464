import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faUserPlus,
  faGraduationCap,
  faMap,
  faUserFriends,
  faBookReader,
  faChalkboardTeacher,
  faComments,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import Carousel from 'react-bootstrap/Carousel'
import Quotes from "./Quotes";
import { FAQ } from "./FAQ";
import { showEmailReminder } from "./util";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { Link, Navigate } from "react-router-dom";

import { FaAward, FaDumbbell, FaPython } from "react-icons/fa/index.js";
import Button from "react-bootstrap/Button";
import { CourseComponents } from "./CourseComponents";
import { useWindowSize } from "react-use-size";

const ABOUT_BTN = "About Code in Place";
const JOIN_TEACHER_BTN = "Become a teacher";
const JOIN_STUDENT_BTN = "Student Application";

export default class LandingContent extends Component {
  constructor() {
    super();
    this.state = {
      openTeacherApplication: false,
      openStudentApplication: false,
    };
  }


  idea() {
    return (
      <>
        <div className="landing-container">
          <div className="row">
            <div className="col">
              <h2 ref={this.props.contentRef} style={{ paddingTop: "50px" }}>
                The Code in Place Course
              </h2>
              {/* <p>
            {CIP_INTRO}
            </p> */}

              <p>Take the first half of CS106A, online for free, with the support from thousands of teachers.</p>

              <p>

                <FontAwesomeIcon
                  className="aboutIcon"
                  icon={faUserFriends}
                />{" "}
                Who? Learners, with no programming experience, and time to
                dedicate to learning.
                <br />

                <FontAwesomeIcon className="aboutIcon" icon={faMap} /> Where?
                Anywhere with internet.
                <br />

                <FaPython className="aboutIcon" /> What? Learn the first half of Stanford's intro to python course, CS106A.{" "}
                <br />

                <FontAwesomeIcon className="aboutIcon" icon={faClock} /> When? Class starts 22nd April 2024. Applications due 12th April 2024.{" "}
                <br />

                <FaAward className="aboutIcon" /> Certification? Yes. Build a sharable portfolio of your code, hosted by Stanford.{" "}
                <br />


                <FaDumbbell className="aboutIcon" /> How much work? At least 7 hours each week for 6 weeks. Set your own schedule.{" "}

              </p>

              <p>
                <Button variant="outline-primary" as={Link} to="/public/join/cip4" className="">
                  <FontAwesomeIcon icon={faUserPlus} /> {JOIN_STUDENT_BTN}
                </Button>
              </p>


              <p>
                CS106A is one of most popular courses at Stanford University,
                taken by almost 1,600 students every year. It has been developed
                over the last 30 years by an amazing team, including Nick
                Parlante, Eric Roberts and more. The course teaches the
                fundamentals of computer programming using the widely-used Python
                programming language. This course is for everyone from humanists,
                social scientists, to hardcore engineers.
              </p>

              <p>
                What makes Code in Place special? We recruit and train one volunteer teacher
                for every 10 students in order to create a vibrant community of teaching and learning.
                We believe that the magnitude of people who want to teach computer
                science is large and may be roughly proportional to the magnitude
                of people who want to learn. Why? Teaching is joyful and teaching
                is the best way to learn both content and team leading skills. We
                do hope this course inspires more
                human-centered learning for all. Here are the main course components:
              </p>



              {/* <p>
              Code in Place require no previous background in programming
              &mdash; just a willingness to work hard and a love for learning.
              It requires considerable dedication and hard work, over a course
              of 5 weeks.
            </p> */}


            </div></div></div>
        <CourseComponents />


        <div className="landing-container">
          <div className="row">
            <div className="col">


              <CourseSchedule />

              <h2 style={{ paddingTop: "20px" }}>
                Past Offerings
              </h2>
              <p>
                We have hosted Code in Place three times, in 2020, 2021 and 2023. It was an inspiring experience. Tens of thousands students have been introduced to the wonderful world of programming in python. Many new teachers had their first experience in front of students. Friendships were made across countries.
              </p>

              <p>

                It was perhaps the single course with the largest number of teachers ever. Across three offerings we had over:</p>


              <div className="d-flex flex-column justify-content-center align-items-center">
                <h4>
                  <span className="blue">3,000</span> section leaders teach
                </h4>
                <h4>
                  <span className="blue">30,000</span> students
                </h4>
                <h4>
                  <span className="blue">1:10</span> student to section leader ratio
                </h4>
                <h4>
                  <span className="blue">1/2</span> of Stanford's CS106A
                </h4>
              </div>

              <p>
                Students and section leaders came from around the world, and represented a broad distribution of backgrounds. Everyone is welcome is one of our mottos.
              </p>

              {/*  MAP OF STUDENTS */}
            </div></div></div>
        {this.mapOfStudents()}

      </>
    )
  }

  mapOfStudents() {
    return (
      <>
        <div className="landing-container container-fluid container-figure">
          <div className="row">
            <div className="col">
              <img src={process.env.PUBLIC_URL + "/cipmap2.jpg"}
                alt="A global map of students from Code in Place 2020, the first of two offerings."
                style={{ width: '100%' }} />
              <p style={{ textAlign: 'center' }}><i>Map of students from Code in Place 2020, the first of two offerings. The course had 10,000 students in the first offering and 12,000 in the second.</i></p>
              {/* <ImageGallery 
      showPlayButton={false}
      showFullscreenButton={false}
      showIndex={true}
      items={images} /> */}
            </div>
          </div>
        </div>
      </>
    )
  }

  results() {
    return (
      <>
        <div className="landing-container">
          <div className="row">
            <div className="col">


              <p className="mb-4">
                Would you recommend the course to a friend? In the first two offerings of Code in Place, <span className="blue">99.5%</span> of
                students said they would. Students
                were asked: "On a scale from 0-10, how likely are you to recommend
                Code in Place to a friend who wants to program", the average
                recommender strength was 9.7
              </p>
              <Quotes className="mb-2" />
            </div>
          </div>
        </div>

        <div className="landing-container">
          <div className="row">
            <div className="col">
              <img src={process.env.PUBLIC_URL + "/cookies.png"}
                alt="Cookies baked by one of the Code in Place students."
                style={{ width: '100%' }} />
              <p style={{ textAlign: 'center' }}><i><a href="https://pecanstreetcookieco.com/">Cookies</a> baked by one of the Code in Place students. The special ingredient of Code in Place was our community of students and teachers. </i></p>
              {/* <ImageGallery 
      showPlayButton={false}
      showFullscreenButton={false}
      showIndex={true}
      items={images} /> */}
            </div>
          </div>
        </div>
      </>
    )
  }

  news() {
    return (
      <>
        <div className="landing-container">
          <h2 style={{ paddingTop: "20px" }}>
            In the News
          </h2>

          <div className="table-responsive-sm">
            <table className="table table-borderless table-no-left-padding" >
              <tbody>

              <tr>
                  <td>Forbes.com</td>
                  <td><a target="_blank" href="https://www.forbes.com/sites/michaeltnietzel/2023/06/01/stanford-universitys-free-code-in-place-course-hits-the-mark/?sh=2c7bb4c621d6">Stanford University’s ‘Code In Place’ Course Breaks New Online Ground</a></td>
                  <td>June 1, 2023</td>
                </tr>


                <tr>
                  <td>Stanford News</td>
                  <td><a target="_blank" href="https://news.stanford.edu/2021/03/22/famous-stanford-coding-course-free-online/">Famous Coding Course Free Online</a></td>
                  <td>March 22, 2021</td>
                </tr>

                <tr>
                  <td>Stanford Daily</td>
                  <td><a target="_blank" href="https://www.stanforddaily.com/2020/06/08/code-in-place-makes-cs-accessible-to-thousands-worldwide/">Code in Place makes CS accessible to thousands worldwide</a></td>
                  <td>June 8, 2020</td>
                </tr>

                <tr>
                  <td>Wired TechSupport</td>
                  <td><a target="_blank" href="https://www.youtube.com/watch?v=1yyRvyNQ5rQ&t=23s">Chris Answers Coding Questions From Twitter</a></td>
                  <td>Oct 4, 2022</td>
                </tr>






                <tr>
                  <td>Scientific American</td>
                  <td><a target="_blank" href="https://www.scientificamerican.com/article/how-we-got-more-than-10-000-students-from-120-countries-to-embrace-the-joy-of-coding/">10,000 Students from 120 Countries Embrace the Joy of Coding
                  </a></td>
                  <td>March 14, 2021</td>
                </tr>

                <tr>
                  <td>Scientific American</td>
                  <td><a target="_blank" href="https://blogs.scientificamerican.com/observations/help-profs-teach-stanfords-popular-and-now-free-online-intro-to-coding-course/">Help Profs Teach Stanford's Popular Intro to Coding Course</a></td>
                  <td>March 31, 2020</td>
                </tr>



                <tr>
                  <td>New York Times</td>
                  <td><a target="_blank" href="https://www.nytimes.com/2021/07/20/technology/ai-education-neural-networks.html">Can A.I. Grade Your Next Test?</a></td>
                  <td>July 21, 2021</td>
                </tr>

                <tr>
                  <td>School's In Podcast</td>
                  <td><a target="_blank" href="https://podcasts.apple.com/us/podcast/making-a-large-class-feel-small-with-guest-chris-piech/id1239888602?i=1000538552070">Making a large class feel small</a></td>
                  <td>Oct 14, 2021</td>
                </tr>


                <tr>
                  <td>SIGCSE</td>
                  <td><a target="_blank" href="https://dl.acm.org/doi/abs/10.1145/3408877.3432562">Online Section Leading for Scalable Human-Centered Learning</a></td>
                  <td>March 13, 2021</td>
                </tr>


                {/* <tr>
      <td>CS Bridge </td>
      <td><a href="https://www.csbridge.org/">CS Bridge Homepage</a></td>
      <td>Current</td>
    </tr> */}
              </tbody>
            </table>


          </div>
        </div>
      </>
    )
  }

  whoCanTeach() {
    return (
      <>
        <div className="landing-container">
          <div className="row">
            <div className="col">
              <h2 ref={this.props.joinRef} style={{ paddingTop: "20px" }}>
                Who Can Teach?
              </h2>
              {WHO_CAN_TEACH_BLURB}

              <p>
                <Button variant="outline-primary" as={Link} to="/public/applyteach/cip4" className="">
                  <FontAwesomeIcon icon={faUserPlus} /> {JOIN_TEACHER_BTN}
                </Button>
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }

  joinUs() {
    return (
      <>
        <div className="landing-container container">
          <div className="row">
            <div className="col">
              <h2 ref={this.props.joinRef} style={{ paddingTop: "20px" }}>
                Join Us Next Time
              </h2>
              <p>
                Code in Place was a one-time course offered during the time of Covid-19 (which became a two-time course because the pandemic went on so long). At the moment we don't have
                concrete plans for a third class. However, the great idea behind this course will continue to live on.
                The Stanford teaching team who hosted Code in Place looks forward to exciting future . We are going to make more courses
                that bring together folks who are interested in learning to teach,
                folks who want to give back as community service, and students who would like to learn in a human-centric community.

              </p>
              <p>If you would like to hear about future offerings follow us on social media. You can also add your name to one of the interest forms. If there is a future offering:<br />
                <a href="https://forms.gle/8SwqGBzU78ijV3Bv6">I am interested in joining as a Section Leader</a><br />
                <a href="https://forms.gle/rf3oiRpj82r1SdiQ7">I am interested in joining as a Student</a><br /></p>
              <p>You can also preview the  <Link to="/cip3/join">
                Code in Place student application
              </Link>.</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    if (this.state.openTeacherApplication) {
      return <Navigate replace to="/cip3/applyteach" />;
    } else if (this.state.openStudentApplication) {
      return <Navigate replace to="/cip3/join" />;
    }
    return (
      <>
        {this.idea()}
        {this.results()}
        {this.whoCanTeach()}
        {/* {this.joinUs()} */}


        <FAQ />

        {this.news()}
        <div style={{ height: "100px" }}></div>
      </>
    );
  }
}

const CourseSchedule = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768;
  return <>
    <h2 style={{ paddingTop: "20px" }}>Syllabus</h2>
    <p>In Code in Place you will learn the fundamentals of python programming including: control flow, loops and conditionals, console programs with variables, graphics, lists and dictionaries. </p>
    <table className="table table-hover">
      <thead>
        <tr>
          <th scope="col"><h5><span className="badge text-bg-primary">{!isMobile && "Week"} #</span></h5></th>
          <th scope="col"><h5><span className="badge text-bg-primary">Topic</span></h5></th>
          <th scope="col"><h5><span className="badge text-bg-primary">You Build</span></h5></th>
          {!isMobile && <th scope="col"><h5><span className="badge text-bg-primary">Unit</span></h5></th>}
        </tr>
      </thead>
      <tbody className="table-group-divider">
        <tr>
          <td scope="row">Week 1</td>
          <td>Control flow with Karel</td>
          <td>Welcome Karel</td>
          {!isMobile && <td>Karel</td>}
        </tr>
        <tr>
          <td>Week 2</td>
          <td>The Art of Coding</td>
          <td>Puzzle Karel</td>
          {!isMobile && <td>Karel</td>}
        </tr>
        <tr>
          <td>Week 3</td>
          <td>Console Programs</td>
          <td>Sandcastles</td>
          {!isMobile && <td>Console</td>}
        </tr>
        <tr>
          <td>Week 4</td>
          <td>Understanding Variables</td>
          <td>Khansole Academy</td>
          {!isMobile && <td>Console</td>}
        </tr>
        <tr>
          <td>Week 5</td>
          <td>Graphics</td>
          <td>Random Art</td>
          {!isMobile && <td>Graphics</td>}
        </tr>
        <tr>
          <td>Week 6</td>
          <td>Lists + Dictionaries</td>
          <td>World's Hardest Game</td>
          {!isMobile && <td>Graphics</td>}
        </tr>
        <tr>
          <td scope="row">Beyond {isMobile ? "" : "Code in Place"}</td>
          <td>Continue Learning</td>
          <td>Final Project</td>
          {!isMobile && <td>Creativity</td>}
        </tr>
      </tbody>

    </table>
  </>
}

const CIP_INTRO = <>At the start of COVID-19 global lock down we had a thought along the lines of, "this time is hard for lots of folks, what can we do to be helpful?"  As an act of community service, a
  group of computer science instructors came together to offer
  a high quality learning experience free of charge for people who want to learn
  introductory coding, subject to availability. We wanted to make it as high a quality offering as we could possibly host at a scale of 10,000 students. Code in Place was born, and it is now a course we offer yearly:</>

const WHO_CAN_TEACH_BLURB = <>
  <p>
    Code in Place Section Leaders come from a wide range of backgrounds. We have had:
  </p>
  <ul>
    <li>Retired teachers</li>
    <li>Programmers in industry</li>
    <li>University students studying computer science</li>
    <li>and even past Code in Place students who have continued their learning</li>
  </ul>
  We have learned that folks from all contexts can be exceptional educators. The prerequisite is that you know the first half of CS106A (python up through lists and dictionaries). If you are accepted we will train you on the art of computer science education. You need to have at least 2 hours each week over the 5 week period.
</>