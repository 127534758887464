import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";
import { CollabEditor } from "./CollabEditor";

import PythonSvg from "../../course/components/icons/python.svg";
import { useWindowSize } from "react-use-size";
import { useContext } from "react";
import { PeerSessionContext } from "ide/contexts/PeerSessionContext";
import { useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useRole } from "../../firebase/realtime/Rooms";
import { IDEContext } from "ide/contexts/IDEContext";
import { CodePhoto } from "./CodePhoto";
import { FaBinoculars, FaMap, FaRobot, FaSearch, FaSearchLocation } from "react-icons/fa";
import "../sync/y/y.css"

export const FileEditor = ({ projectId, currFile, onFileCodeUpdate, revertToStarterFn }) => {
  const firebaseDocPath = `projects/${projectId}/code/${currFile?.id}`;

  if (!currFile) return <NoFileFoundPage revertToStarterFn={revertToStarterFn}/>

  return (
    <div className="w-100 h-100 d-flex flex-column">
      {/* The tab bar */}
      <div
        className="w-100"
        style={{
          display: "flex",
        }}
      >
        {/* Left side */}
        <div className="ideTabLeftMargin" />
        <FileName currFile={currFile} />

        {/* Middle grow */}
        <div className="ideTabFiller"></div>

        {/* Right side */}
        <div className="ideTabButtonBar"></div>
      </div>

      <FileContent
        currFile={currFile}
        firebaseDocPath={firebaseDocPath}
        onFileCodeUpdate={onFileCodeUpdate}
      />
    </div>
  )
};

const FileContent = ({ currFile, firebaseDocPath, onFileCodeUpdate }) => {
  const format = currFile?.format;
  // default to showing a doc
  if (!format || format === "doc") {
    return <RenderDoc
      currFile={currFile}
      firebaseDocPath={firebaseDocPath}
      onFileCodeUpdate={onFileCodeUpdate} />
  }

  if (format === "image") {
    return <RenderImg currFile={currFile} />
  }

  return <p>Unknown format {format}</p>
}

const RenderImg = ({ currFile }) => {
  return <div style={{ overflow: "auto", display: "flex" }}>
    <img
      src={currFile?.url}
      style={{
        "maxWidth": "100%",
      }}
    />
  </div>
}

const useIsReadOnly = () => {
  const auth = getAuth();
  const user = auth.currentUser;

  // check if should be in listen only mode
  const { courseId, roomId } = useParams();
  const { editable } = useContext(IDEContext);
  // This role is 'pilot' or 'learner' or 'sl'
  const { isMeeting, meetingRole } = useContext(PeerSessionContext);


  const isReadOnlyFunc = (isMeeting, meetingRole, editable) => {
    if (!editable) return true;
    if (!isMeeting) return false;
    // This allows TAs and admins to edit in any session
    // for now we don't want anyone else to be able to edit

    // if (isMinimumRole(courseRole, Role.TA)) return false; // TA/Admin always have edit access if they join  a session

    if (meetingRole === "pilot") return false; // student is always pilot in officeHours
    return true;
  };
  const isReadOnly = isReadOnlyFunc(isMeeting, meetingRole, editable);
  return isReadOnly

}

const SelectEditor = ({ ideOnFileChange, firebaseDocPath, currFile }) => {

  return <CollabEditor ideOnFileChange={ideOnFileChange} firebaseDocPath={firebaseDocPath} currFile={currFile}/>

}


const RenderDoc = ({ currFile, firebaseDocPath, onFileCodeUpdate }) => {
  const windowSize = useWindowSize();
  return <div
    className="h-100 w-100"
    id="code-editor"
    style={{ flexGrow: 2, overflow: "auto", display: "flex" }}
  >

    <SelectEditor
      ideOnFileChange={(e) => {
        onFileCodeUpdate(currFile?.id, e);
      }}
      firebaseDocPath={firebaseDocPath}
      currFile={currFile}
    />
  </div>
}

const FileName = ({ currFile }) => {
  return <span className="ideTabCode">
    <button className="btn p-0 ideCodeTab">
      <span className="" style={{ color: "#5989A4" }}>
        <img
          style={{ width: "20px", marginTop: "-5px" }}
          src={PythonSvg}
          alt=""
        />{" "}
        <span
          style={{
            color: "#2e72f4",
            fontWeight: 700,
            fontSize: "1.2em",
          }}
        >
          {currFile?.name}
        </span>
      </span>
    </button>
    <CodePhoto />
  </span>
}






const NoFileFoundPage = ({revertToStarterFn=undefined}) => {
  const { urlFormat } = useParams();
  const isAssn = urlFormat === "a";



  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-column">
      <h3>Uh oh, no files were found</h3>
      <FaRobot size={100}/>
      {
        isAssn && revertToStarterFn && <button onClick={revertToStarterFn} className="btn btn-primary mt-3">Revert to Starter Code</button>
      }
      
    </div>
  )
}