import { useCourseId } from "hooks/router/useUrlParams";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { FaPowerOff, FaUser } from "react-icons/fa";
import { useContext } from "react";
import { ProfileContext } from "contexts/ProfileContext";
import Gate from "contexts/Gate";
import { CourseContext } from "contexts/CourseContext"; import { menuItemStyles } from "course/components/sidebar/menuItemStyles";
import { CodeSubMenu, ForumsSubMenu, HomeSubMenu } from "course/components/sidebar/SubMenus";
import { getIcon, getTitle, isActive } from "course/components/sidebar/courseSidebarUtils";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import Swal from "sweetalert2";
;


export const IdeLandingSidebar = () => {
  const { broken } = useProSidebar();
  const { userData } = useContext(ProfileContext);
  const { courseFeatures, slFeatures } = useContext(CourseContext);
  const courseId = useCourseId();

  const isSectionLeader = Gate.hasSectionLeaderRole(userData);
  const isAdmin = Gate.hasAdminRole(userData);
  const menu = isSectionLeader ? slFeatures : courseFeatures;


  const { collapsed } = useProSidebar();

  // need auth for signout
  const auth = getAuth()
  const email = auth.currentUser?.email

  return (
    <div style={{ padding: broken ? 0 : 10 }}>
      <Sidebar
        breakPoint="sm"
        backgroundColor="white"
        width="190px"
        collapsedWidth="70px"
        style={{
          borderRight: "none",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Menu menuItemStyles={menuItemStyles}>
            <CourseMenuItem
              courseId={courseId}
              collapsed={collapsed}
              subpage={"about"}
              icon={getIcon("stanford")}
              title={<>Stanford <code>IDE</code></>}
            />

            <CourseMenuItem
              courseId={courseId}
              collapsed={collapsed}
              subpage={"examples"}
              icon={getIcon("code-pages")}
              title={"Examples"}
            />
            <CourseMenuItem
              courseId={courseId}
              collapsed={collapsed}
              subpage={"challenges"}
              icon={getIcon("code-pages")}
              title={"Challenges"}
            />
            <CourseMenuItem
              courseId={courseId}
              collapsed={collapsed}
              subpage={"create"}
              icon={getIcon("code-pages")}
              title={"My Projects"}
            />


          </Menu>
          <Menu menuItemStyles={menuItemStyles}>
            <hr/>
            {email && <MenuItem
              onClick={() => {Swal.fire({title: 'Signed in as', text: email})}}
              id={`nav-logout`}
            >
              
              <span>{email}</span>
            </MenuItem>}
            <MenuItem
              onClick={() => {auth.signOut()}}
              active={false}
              id={`nav-logout`}
            >
              
              <span><FaPowerOff/>{" Sign Out"}</span>
            </MenuItem>
          </Menu>
        </div>
      </Sidebar>
    </div>
  );
};

export const CourseMenuItem = ({ courseId, collapsed, title, icon, subpage, isLinkActive = true }) => {
  const active = isActive(subpage)
  return (
    <MenuItem
      component={<Link to={`/${courseId}/${subpage}`} />}
      active={active}
      id={`nav-${subpage}`}
    >
      {icon}
      <span>{collapsed ? "" : <>&nbsp;&nbsp;{title}</>}</span>
    </MenuItem>
  );
};


export const SidebarFooter = ({ children, collapsed, ...rest }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: "20px",
      }}
    >
      (
      <div {...rest}>
        <div style={{ marginBottom: "12px" }}></div>
        <Typography fontWeight={600}>Pro Sidebar</Typography>
        <Typography
          variant="caption"
          style={{ letterSpacing: 1, opacity: 0.7 }}
        >
          V 23
        </Typography>
        <div style={{ marginTop: "16px" }}>
          <button>
            <Typography variant="caption" color="#607489" fontWeight={600}>
              View code
            </Typography>
          </button>
        </div>
      </div>
      )
    </div>
  );
};

export const Divider = () => {
  return <hr style={{ margin: "10px" }} />;
};

const Typography = ({ children, ...rest }) => {
  return <span {...rest}>{children}</span>;
};
