// The community chat experience

import { useEffect, useRef, useContext, useState } from 'react';
import { LessonContext } from "../LessonContext";
import styled from 'styled-components';
import { RepliableMessage } from './RepliableMessage';
import { ProfileContext } from '../../../contexts/ProfileContext';
import { MessageInputArea } from './MessageInputArea';
import { getFunctions, httpsCallable } from "firebase/functions";
import { FormattedMessage } from './FormattedMessage';
import { ChatHeader } from './ChatHeader';
import { ChatWindow, MessagesDisplay } from './ChatStyles';
import { CHATBOT_NAME, CHAT_SERVICE_ERROR_MESSAGE, FLAGGED_POST, FLAGGED_POST_MESSAGE, MESSAGES_LIMIT_MESSAGE, ERROR_TOAST_DURATION, MESSAGE_LIMIT } from './ChatConstants';
import { errorToast } from 'course/forum/components/forumGeneral';

const functions = getFunctions();
const communityChatEndpoint = httpsCallable(functions, "communityChatEndpoint");

export const CommunityChat = () => {
    const { userData } = useContext(ProfileContext);
    const userName = userData.displayName;

    const { chatMessages, lessonData, currSlideId } = useContext(LessonContext);

    // Keep track of whether the user is replying to a message, or sending a new message
    const [replyTo, setReplyTo] = useState(null);
    const [currentMessage, setCurrentMessage] = useState("");

    // Reference to the bottom of the messages display
    const messagesEndRef = useRef(null)

    // Scroll to the bottom of the messages display when the messages array changes
    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }, [chatMessages]);

    // Construct and return an array of the chain of replies that lead to the given message.
    const constructMessages = (messageId) => {
        // Init the return array
        let messages = [];

        // Grab the specified message
        let currentMessage = chatMessages.find(message => message.id === messageId);

        while (currentMessage) {
            // Prepend the message to the array
            messages.unshift({ role: currentMessage.role, content: currentMessage.content });

            // Find the message that this message is replying to
            currentMessage = chatMessages.find(message => message.id === currentMessage.replyTo?.messageId);
        }

        return messages;
    }

    const storeMessage = async () => {
        // Grab the current message
        const query = currentMessage;

        // If the user didn't type anything, don't send the message
        if (query === "") {
            return;
        }

        // Reset the current message
        setCurrentMessage("")

        // Grab the message that this message is replying to
        const replyInfo = replyTo;
        setReplyTo(null);

        // Get all messages in this reply chain (if any)
        let messages;
        if (replyInfo) {
            messages = constructMessages(replyInfo.messageId);
        } else {
            messages = [];
        }

        try {
            // Send the message to the backend
            const response = await communityChatEndpoint({
                chatMessages: messages,
                query: query,
                lessonId: lessonData.id,
                currSlideId: currSlideId,
                userName: userName,
                aiName: CHATBOT_NAME,
                replyTo: replyInfo
            });

            // If the message was flagged, alert the user
            if (response.data === FLAGGED_POST) {
                errorToast(FLAGGED_POST_MESSAGE, ERROR_TOAST_DURATION);
            } 
            // The AI will no longer respond to this users messages. 
            // Not sure if we have to tell them this.
            // else if (response.data === MESSAGE_LIMIT) {
            //     errorToast(MESSAGES_LIMIT_MESSAGE, ERROR_TOAST_DURATION);
            // }

        } catch (error) {
            errorToast(CHAT_SERVICE_ERROR_MESSAGE, ERROR_TOAST_DURATION);
        }
    }

    return (
        <ChatWindow>
            {/* The Chat bar on top */}
            <ChatHeader />

            {/* The messages area */}
            <MessagesDisplay>
                {[...chatMessages].reverse().map((message, index) => (
                    <RepliableMessage
                        key={index}
                        message={message}
                        setReplyTo={setReplyTo} />
                ))}
                <div ref={messagesEndRef} />
            </MessagesDisplay>

            {/* This is the reply to message */}
            {replyTo &&
                <CurrentlyReplying>
                    <CurrentlyReplyingExit onClick={() => setReplyTo(null)}>x</CurrentlyReplyingExit>
                    <CurrentlyReplyingAuthor><strong>{replyTo.authorName}</strong></CurrentlyReplyingAuthor>
                    <CurrentlyReplyingText>
                        <FormattedMessage content={replyTo.content} />
                    </CurrentlyReplyingText>
                </CurrentlyReplying>}

            {/* The user input area */}
            <MessageInputArea
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
                sendMessage={storeMessage} />
        </ChatWindow>
    );

};

const CurrentlyReplying = styled.div`
    background-color: #f0f0f0;
    padding: 5px;
    border-bottom: 1px solid #ccc;
    position: relative;
`;

const CurrentlyReplyingAuthor = styled.p`
    font-size: 0.9rem;
    color: #0;
    width: fit-content;
    margin: 0;
`;

const CurrentlyReplyingText = styled.p`
    font-size: 0.9rem;
    color: #555;
    width: 100%;
    margin: 0;
    word-wrap: break-word;
`;


const CurrentlyReplyingExit = styled.button`
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
    color: #555;
    cursor: pointer;
`;

