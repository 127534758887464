import React, { useEffect, useState } from 'react';
import {
  useDocumentData,
  useDocumentDataOnce,
  useCollectionOnce,
  useCollection
} from "react-firebase-hooks/firestore";
import {
  useParams
} from "react-router-dom";
import SplitPane from 'react-split-pane';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'
import { firestore } from "firebaseApp.js";
import { useUserId } from 'hooks/user/useUserId';
import { collection, getFirestore } from 'firebase/firestore';
import { getApp } from 'firebase/app';
import Loading from 'react-loading';

import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";


export const FileHistory = (props) => {
  let { projectId, fileId } = useParams();
  const userId = useUserId()
  const db = getFirestore(getApp())

  const path = `/projects/${projectId}/code/${fileId}/logs`
  const historyRef = collection(db, path)
  var [historyCollection, historyLoading, historyLoadingErr] = useCollection(historyRef);



  if (historyLoadingErr) {
    return <>historyLoadingErr</>
  }

  if (historyLoading) {
    return <Loading />
  }

  if (!historyCollection) {
    return <>No history</>
  }

  let allHistory = {}
  for (const doc of historyCollection.docs) {
    allHistory[doc.id] = doc.data()
  }

  if (Object.keys(allHistory).length == 0) {
    return <>No history for this problem</>
  }

  return <HistoryView
    allHistory={allHistory}
  />
}


const HistoryView = ({ allHistory }) => {
  let { projectId, fileId } = useParams();
  let allKeys = Object.keys(allHistory)

  let mostRecentKey = allKeys[allKeys.length - 1];


  let [currSelection, setCurrSelection] = useState(mostRecentKey)
  let [currFileContent, setFileContent] = useState(allHistory[mostRecentKey].content)

  useEffect(() => {
    let content = allHistory[currSelection]?.content
    setFileContent(content)
    // let newAnswer = allHistory[currSelection].answer
    // let newExplanation = allHistory[currSelection].explanation
    // setCurrExplanation(newExplanation)
    // setCurrAnswer(newAnswer)
    // setCurrContent(allHistory[currSelection].content)
  }, [currSelection]);

  const revertToCurrSelection = () => {
    let canEdit = true
    if (!canEdit) {
      Swal.fire({
        toast: true,
        icon: 'error',
        showConfirmButton: false
      });
      return
    }

    Swal.fire({
      toast: true,
      icon: 'info',
      title: 'Reverting...',
      showConfirmButton: false
    });
    firestore
      .doc(`/projects/${projectId}/code/${fileId}`)
      .set(
        buildRevertDoc(allHistory[currSelection]),
        { merge: false } // overwrites!
      )
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'You will need to refresh all tabs',
          text: 'Your answer has been reverted in the database',
          showConfirmButton: true,

          showClass: {
            popup: 'none'
          },
        });
      })
      .catch((e) => {
        Swal.fire({
          toast: true,
          icon: 'error',
          text: e.message,
          showConfirmButton: false
        });
      })
  }

  return <>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col d-flex flex-column' style={{ height: '100vh' }}>
          <h1>File History</h1>
          <SplitPane
            style={{ height: '100%', width: '90vw', flexGrow: 2 }}
            defaultSize={500}
          >
            <HistoryMenu
              allHistory={allHistory}
              currSelection={currSelection}
              setCurrSelection={setCurrSelection}
            />
            <div style={{ maxWidth: 600, height: '100%' }} className='d-flex flex-column'>
              <><button onClick={() => revertToCurrSelection()} class="btn btn-primary">Revert to this content ({currSelection})</button></><br />

              <HistorySnapshot

                currFileContent={currFileContent}
                editable={false}
                showHistory={false}
                currSelection={currSelection}
              />
            </div>
          </SplitPane>
        </div>
      </div>
    </div>
  </>
}

const HistorySnapshot = (props) => {
  if (!props.currSelection) return <>Select a history time</>

  console.log(props.currFileContent)

  return <AceEditor
    mode="python"
    value={props.currFileContent}
    onChange={(e) => { }}
    fontSize="15px"
    enableBasicAutocompletion={true}
    enableLiveAutocompletion={true}
    highlightActiveLine={false}
    showGutter={true}
    wrapEnabled={true}
    maxLines={Infinity}
    readOnly={true}
    width="100%"
    style={{ width: "100%" }}
  />
}

const HistoryMenu = ({
  allHistory,
  currSelection,
  setCurrSelection
}) => {
  // ordered from most recent, to least recent
  let keys = Object.keys(allHistory).reverse()
  return <>
    {
      keys.map((dateKey, index) => {
        let date = new Date(dateKey)
        let options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZoneName: 'short',
          fractionalSecondDigits: 1
        };
        let dateStr = date.toLocaleString('en-US', options)
        return <p key={index}><button onClick={() => setCurrSelection(dateKey)} className='btn btn-link'>{dateStr}</button></p>
      })
    }
  </>
}


const buildRevertDoc = (originLog) => {

  return {
    ...originLog,
    author: 'revertHistory'
  }
}