import { collection, doc, getDoc, getFirestore, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { functions } from "firebaseApp";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { fetchAllAssignmentMetaData, fetchAssignments } from "../utils/general";
import { getAuth } from "firebase/auth";

/**
 * This context provides the server time to the client.
 * Caution: because of internet delays it could be incorrect up to 
 * about 1 second. Do not use it for anything which needs more precise
 * timing.
 * 
 * Stores the server time in the serverDelta variable. It is the difference
 * in ms between the server time and the client time.
 */

const defaultData = {
  getServerTimeMs: undefined
};

export const TimeContext = React.createContext(defaultData);
export const TimeProvider = ({children}) => {

  const [serverTimeDelta, setServerDelta] = useState(undefined);

  useEffect(()=> {
    const getServerTime = functions.httpsCallable('getServerTime');
    getServerTime().then((result) => {
      const serverTime = result.data.serverTime;
      const clientTime = Date.now();
      const serverDelta = serverTime - clientTime;
      setServerDelta(serverDelta)
    });
  },[])


  const getServerTimeMs = () => {
    if(serverTimeDelta === undefined) {
      return undefined;
    }
    return Date.now() + serverTimeDelta;
  }
  


  return (
    <TimeContext.Provider
      value={{
        getServerTimeMs
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};

