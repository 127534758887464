import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/python';
import { FaICursor, FaPlay, FaStop, FaTerminal, FaShapes } from 'react-icons/fa';
import { PyodideClient } from '../../../pyodide/PyodideProvider';
import { v4 as uuidv4 } from 'uuid';
import { MonacoTipTap } from './runnableKarel/TipTapCodeEditor';


// tiptap
import { NodeViewWrapper } from '@tiptap/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'

const MAX_OUTPUT_LINES = 200

export const RunnableGraphics = Node.create({
  name: 'runnable-graphics',
  group: 'block',
  atom: true,
  draggable: true,
  addAttributes() {
    return {
      // we need to make output a state (not an attribute)
      // so that a big for loop doesn't cause too many server writes
      // If we do want it to have persistent output, we can debounce it.
      // Another note: for textbooks (which also use tiptap) not showing
      // output at the start can be a feature.
      code: {
        default: '# your code here\n',
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'runnable-graphics',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['runnable-graphics', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(RenderRunableGraphics)
  },
})

export const RenderRunableGraphics = (props) => {
  // props for keeping track of the output. in the future we will want to push this to
  // the atomic state...
  const messagesEndRef = useRef();
  const [isRunning, setIsRunning] = useState(false);
  const [output, setViewedOutput] = useState('');
  const [showOutput,setShowOutput] = useState(false);
  // we should not need this. workaround beacuse "editable" is not reactive
  const [isEditable, setIsEditable] = useState(props.editor.isEditable)
  const pyodideClientRef = useRef(null);
  const [showGraphics, setShowGraphics] = useState(false)
  const [canvasId, setCanvasId] = useState(uuidv4())

  useEffect(() => {
    console.log(canvasId, document.getElementById(canvasId))
    if(showGraphics && document.getElementById(canvasId) !== null) {
      document.getElementById(canvasId).style.display = "block";
    } else if(document.getElementById(canvasId) !== null) {
      document.getElementById(canvasId).style.display = "none";
    }

  }, [showGraphics])


  const setPyodideHandlers = () => {
    pyodideClientRef.current.setHandlers(
      () => {
        // Func called on end
        setIsRunning(false)
      },
      (stdout) => {
        // Func called on stdout
        appendOutput(stdout)
      },
      (stderr) => {
        // Func called on error
        appendOutput(stderr);
        setIsRunning(false)
      },
      (inputPrompt) => {
        const data = prompt(inputPrompt)
        appendOutput(data, false)
        return data;
      },
      null,
      null
    )
  }

  let runCode = () => {
    setOutput('');

    let pyodideClient = pyodideClientRef.current;
    setPyodideHandlers();
    const code = getCode();
    setIsRunning(true)
    pyodideClient.runCode(code, { name: "main.py" }, false, 500, canvasId);
    setShowOutput(true);
    setShowGraphics(true)
    // window.raisePyodideStopFlag(false)
  };


  useEffect(() => {
    pyodideClientRef.current = new PyodideClient();
    pyodideClientRef.current.setHandlers(
      () => {
        // Func called on end
        setIsRunning(false)
      },
      (stdout) => {
        // Func called on stdout
        appendOutput(stdout)
      },
      (stderr) => {
        // Func called on error
        appendOutput(stderr);
        setIsRunning(false)
      },
      () => {/*Input run*/ },
      null,
      null
    )
  }, [])


  useEffect(() => {
    if (messagesEndRef.current) {
      // WARNING: untested
      messagesEndRef.current.scrollIntoView()
    }
  }, [output]);

  // this is fired by writing an empty string each time editable changes :(
  props.editor.on('transaction', () => {
    setIsEditable(props.editor.isEditable)
  })


  const onClick = (e) => {
    // e.stopPropagation();
  };

  const getCode = () => {
    return props.node.attrs.code || ""
  };

  const getOutput = () => {
    return output
  }

  const getShowOutput = () => {
    return showOutput
  }

  const getShowGraphics = () => {
    return showGraphics
  }

  const setOutput = (newValue) => {
    let lines = newValue.split('\n')
    // the off by one is for the final return
    let nLines = lines.length - 1
    if (nLines > MAX_OUTPUT_LINES) {
      // off by one here for the same reason
      let toKeep = lines.slice(-(MAX_OUTPUT_LINES + 1))
      newValue = `last ${MAX_OUTPUT_LINES} lines...\n` + toKeep.join('\n')
    }
    setViewedOutput(newValue)
    // props.updateAttributes({
    //   output: newValue
    // })
  }

  var currOutput = ''
  const appendOutput = (newValue, nl = true) => {
    if (nl && currOutput.length > 0) {
      currOutput = `${currOutput}\n${newValue}`;
    } else {
      currOutput = `${currOutput}${newValue}`;
    }
    setOutput(currOutput)
  }

  const onValueChange = (newCode) => {
    props.updateAttributes({
      code: newCode
    })
  };

  return (
    <NodeViewWrapper data-drag-handle>
      <div
        style={{
          border: 'lightgrey',
          borderStyle: 'solid',
          borderWidth: '1px',
        }}
        onClick={(e) => onClick(e)}
        spellCheck="false"
        draggable="true"
      >
        <MonacoTipTap
          mode="python"
          value={getCode()}
          onChange={(e) => onValueChange(e)}
          readOnly={!isEditable}
        />
        <canvas
        style={{
          backgroundColor: 'white',
          border: "solid 1px",
        }}
        width={0}
        height={0}
        id={canvasId}
      ></canvas>
        {getShowOutput() && (
          <pre
            spellCheck="false"
            style={{
              backgroundColor: 'black',
              color: 'white',
              height: '115px',
              marginBottom: '0px',
              padding: '5px',
            }}
          >
            {getOutput()}
            <span ref={messagesEndRef} />
          </pre>
        )}
        <RunButtonRow
          isRunning={isRunning}
          runCode={() => runCode()}
          showOutput={getShowOutput()}
          showGraphics={getShowGraphics()}
          setShowOutput={setShowOutput}
          setShowGraphics={setShowGraphics}
          pyodideClient={pyodideClientRef.current}
        />
      </div>
    </NodeViewWrapper>
  );
};

const RunButtonRow = (props) => {
  let runStopText = (
    <span>
      <FaPlay /> {'Run'}
    </span>
  );
  if (props.isRunning) {
    runStopText = (
      <span>
        <FaStop /> {'Stop'}
      </span>
    );
  }

  let showHideOutput = (
    <span>
      <FaTerminal /> Show
    </span>
  );
  if (props.showOutput) {
    showHideOutput = (
      <span>
        <FaTerminal /> Hide
      </span>
    );
  }

  let showHideGraphics = (
    <span>
      <FaShapes /> Show
    </span>
  );
  if (props.showGraphics) {
    showHideGraphics = (
      <span>
        <FaShapes /> Hide
      </span>
    );
  }

  const onRunStopClick = () => {
    if (props.isRunning) {
      props.pyodideClient.raisePyStopFlag()
      // window.pyodide.runPython(`raise Exception('Stop')`);
    } else {
      props.runCode();
    }
  };

  return (
    <>
      <button
        onClick={() => onRunStopClick()}
        className="btn btn-light btn-sm "
        style={{ width: '80px' }}
        disabled={props.disabled}
      >
        {runStopText}
      </button>

      <button
        onClick={() => props.setShowOutput(!props.showOutput)}
        style={{ width: '80px' }}
        className="btn btn-light btn-sm "
        disabled={props.disabled}
      >
        {showHideOutput}
      </button>
      <button
        onClick={() => props.setShowGraphics(!props.showGraphics)}
        style={{ width: '80px' }}
        className="btn btn-light btn-sm "
        disabled={props.disabled}
      >
        {showHideGraphics}
      </button>

    </>
  );
};


