import { database, auth, functions } from "firebaseApp.js";
import { useState, useEffect } from "react";
import { Event } from "./types";

const GLOBAL_EVENT_QUEUE = '%GLOBAL%'

export const EVENT_TYPES = {
    newMatch: 'NEW_MATCH',
    failedMatch: 'FAILED_MATCH',
    peerLearnNudge: 'PEER_LEARN_NUDGE',
    careHoursNudge: "NUDGE_CARE_HOURS",
    careHoursMatch: "NEW_CARE_MATCH",
    slNoCareStudents: "SL_NO_CARE_STUDENTS"
}

export interface EventsByType {
    [k : string]:  Array<Event>
}

export const useEvents = (uid: string, courseId: string) : [EventsByType, (event: Event) => void] => {
    const [events, setEvents] = useState([]);

    const addEvent = (newEvent: Event) => {
        setEvents((events) => [...events, newEvent]);
    }

    const removeEvent = (newEvent: Event) => {
        setEvents((events) => events.filter((event) => event.eventId !== newEvent.eventId));
    }

    useEffect(() => {
        const eventListenerRef = database.ref(`/${courseId}/events/${uid}`);
        const eventListenerRefGlobal = database.ref(`/${courseId}/events/${GLOBAL_EVENT_QUEUE}`);
        const eventListenerRefs: [typeof eventListenerRef, string][] =
             [[eventListenerRef, uid], [eventListenerRefGlobal, GLOBAL_EVENT_QUEUE]];
        for(const [ref, dest] of eventListenerRefs) {
            ref.orderByChild('timestampMS').startAt(Date.now()).on('child_added', (snap, prevChildKey) => {
            // const eventListenerRef = database.ref(`/${courseId}/queue/`)
            // eventListenerRef.on('child_added', (snap, prevChildKey) => {
                // console.log(">>>>>> Child added ", snap.val())
                const val = snap.val();
                val['eventDest'] = dest
                if (!val?.ignore) {
                    // only add events without an ignore flag
                    addEvent(val);
                }
            })
        }
    }, [])

    let eventsByType: EventsByType = Object.fromEntries(Object.values(EVENT_TYPES).map((eventType) => [eventType, []]));
    events.forEach((event) => {
        if(!(event.eventType in eventsByType)) {
            console.error("Unknown event type: ", event.eventType);
            console.error("Valid types: ", Object.values(EVENT_TYPES));
        } else {
            eventsByType[event.eventType].push(event)
        }
    });
    // console.log("eventsByType: ", eventsByType);

    return [eventsByType, removeEvent];

}
