// The buttons chat experience

import { getFunctions, httpsCallable } from "firebase/functions";
import { ChatWindow } from './ChatStyles';
import { ChatHeader } from "./ChatHeader";
import { useContext, useEffect, useRef, useState } from "react";
import { LessonContext } from "../LessonContext";
import { MessagesDisplay } from "./ChatStyles";
import { SimpleMessage } from "./SimpleMessage";
import { TypingAnimation } from "./TypingAnimation";
import styled from 'styled-components';
import { CHATBOT_NAME, ERROR_TOAST_DURATION, MESSAGES_LIMIT_MESSAGE, MESSAGE_LIMIT, CHAT_SERVICE_ERROR_MESSAGE } from "./ChatConstants";
import { errorToast } from "course/forum/components/forumGeneral";

const functions = getFunctions();
const explainDifferently = httpsCallable(functions, "explainDifferentlyEndpoint");
const getSummary = httpsCallable(functions, "getSummary");

export const ButtonsChat = () => {
    const { chatMessages, currSlideId, lessonData, getCurrentContent } = useContext(LessonContext);

    // Reference to the bottom of the messages display
    const messagesEndRef = useRef(null)

    const [loadingMessage, setLoadingMessage] = useState(false);

    // Scroll to the bottom of the messages display when the messages array changes
    useEffect(() => {
        setLoadingMessage(false);
    }, [chatMessages]);

    // Scroll to the bottom of the messages array whenever the user just sent or received a message
    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }, [loadingMessage]);

    // Call the explain differently endpoint
    const callExplainDifferently = async () => {
        // Prevent multiple calls
        if (loadingMessage) return;

        // Set the loading message to true
        setLoadingMessage(true);

        // Get the current content that the user is looking at
        const currentContent = await getCurrentContent();

        try {
            // Call the explain differently endpoint
            const res = await explainDifferently({ aiName: CHATBOT_NAME, lessonId: lessonData.id, currSlideId: currSlideId, content: currentContent });

            if (res.data === MESSAGE_LIMIT) {
                errorToast(MESSAGES_LIMIT_MESSAGE, ERROR_TOAST_DURATION);
                setLoadingMessage(false);
            }
        } catch (error) {
            errorToast(CHAT_SERVICE_ERROR_MESSAGE, ERROR_TOAST_DURATION);
            setLoadingMessage(false);
        }
    }

    // Call the get summary endpoint
    const callGetSummary = async () => {
        // Prevent multiple calls
        if (loadingMessage) return;

        // Set the loading message to true
        setLoadingMessage(true);

        // Get the current content that the user is looking at
        const currentContent = await getCurrentContent();

        try {
            // Call the get summary endpoint
            const res = await getSummary({ aiName: CHATBOT_NAME, lessonId: lessonData.id, currSlideId: currSlideId, content: currentContent });

            if (res.data === MESSAGE_LIMIT) {
                errorToast(MESSAGES_LIMIT_MESSAGE, ERROR_TOAST_DURATION);
                setLoadingMessage(false);
            }
        } catch (error) {
            errorToast(CHAT_SERVICE_ERROR_MESSAGE, ERROR_TOAST_DURATION);
            setLoadingMessage(false);
        }
    }

    return (
        <ChatWindow>
            <ChatHeader />
            <MessagesDisplay>
                {[...chatMessages].reverse().map((message, index) => (
                    <SimpleMessage
                        key={index}
                        message={message} />
                ))}
                {loadingMessage && <TypingAnimation />}
                <div ref={messagesEndRef} />
            </MessagesDisplay>
            <ButtonsContainer>
                <AIButton onClick={() => callExplainDifferently()}>Explain it differently.</AIButton>
                <AIButton onClick={() => callGetSummary()}>What just happened?</AIButton>
            </ButtonsContainer>
        </ChatWindow>
    );
};

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: white;
    border-top: 1px solid #e0e0e0;
`;

const AIButton = styled.button`
    background-color: #e84393;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin: 5px;
    &:hover {
        background-color: #d63069;
    }
`;