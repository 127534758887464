import { useCourseId } from "../../hooks/router/useUrlParams";
import { EVENT_TYPES } from "../../firebase/realtime/Events";
import { ToastContainer } from "react-bootstrap";
import { ToastBox } from "./ToastNotify";
import { useNavigate } from "react-router-dom";
import { createContext, useContext, useEffect, useState } from 'react';
import { EventsContext } from '../../course/contexts/EventsContext';
import { ROOM_EXITED, setRoomStatus } from '../../firebase/realtime/Rooms';
import { Event } from '../../firebase/realtime/types';
import Swal from "sweetalert2";
import { PeerSessionContext } from '../../ide/contexts/PeerSessionContext';


export const ToastContext = createContext({
  addToToasts: (toast) => { },
  removeFromToasts: (toast) => { }
})



export const ToastLayer = (props) => {
  const courseId = useCourseId();
  const { user, activeRoom } = props
  const [eventsByType, removeEvent] = useContext(EventsContext)
  const { isMeeting } = useContext(PeerSessionContext)

  // Stricter check for ide in the random chance it is in the userID
  const inIde = window.location.href.includes("/ide/") || window.location.href.includes("/peer/")

  // Used to determine if we are in the EndOfPeerSessionPage in which case we want to render feedback differenlty
  const isPeer = window.location.href.includes("/peer/")


  const [otherToasts, setOtherToasts] = useState([])

  const addToToasts = (toast) => {
    //hacky way to have uniques
    setOtherToasts((prev) => [...prev.filter((t) => t.key !== toast.key), toast])
  }

  const removeFromToasts = (key) => {
    setOtherToasts((prev) => prev.filter((t) => t.key !== key))
  }


  const toasts = []
  const [toastsEvent, modals] = processEvents(eventsByType, removeEvent, courseId, user.uid, activeRoom, inIde, isPeer, isMeeting)
  toasts.push(...toastsEvent)
  toasts.push(...otherToasts)


  return (
    <>
      <ToastContainer position="top-end" style={{ zIndex: 5 }}>
        {toasts}
      </ToastContainer>

      <ToastContext.Provider value={{ addToToasts, removeFromToasts }}>
        {props.children}
      </ToastContext.Provider>
    </>
  )
}

const processEvents = (
  eventsByType: any,
  removeEvent: (e: Event) => void,
  courseId: string,
  uid: string,
  activeRoom: string,
  inIde: boolean,
  inPeer: boolean,
  isMeeting: boolean
) => {
  const toasts = [];
  const modals = [];
  const navigate = useNavigate();
  if (activeRoom && !inIde) {
    toasts.push(
      <ToastBox
        title="You are in a peer session!"
        key="feedback-requested"
        text="Would you like to go back into your peer session or end the session? If you have an ongoing session, you can always rejoin from the home page under the peer projects section."
        successText={"Rejoin session!"}
        cancelText={"End session"}
        show={activeRoom} // remove when activeRoom goes away
        dismissIsCancel={false}
        onSuccess={async () => {
          navigate("/cip3/peer/" + activeRoom);
        }}
        onCancel={() => {
          setRoomStatus(uid, courseId, activeRoom, ROOM_EXITED);
        }}
      ></ToastBox>
    );
  }
  if (eventsByType[EVENT_TYPES.peerLearnNudge].length > 0) {
    const newEvent = eventsByType[EVENT_TYPES.peerLearnNudge][0];
    toasts.push(
      <ToastBox
        title="Learn with your peers!"
        key="queue-nudge"
        text="A lot of your classmates are peer learning right now. Join the fun!"
        successText="Learn with friends"
        cancelText="Maybe later"
        onSuccess={() => {
          removeEvent(newEvent);
          // TODO: anchor to the right page
          navigate("/cip3");
        }}
        onCancel={() => {
          removeEvent(newEvent);
        }}
      />
    );
  }
  if(eventsByType[EVENT_TYPES.careHoursMatch].length > 0) {
    const newEvent = eventsByType[EVENT_TYPES.careHoursMatch][0];
    const roomId = newEvent.data.roomId
    removeEvent(newEvent)
    if(!isMeeting) {
      // fire a swal to let the user know they have been matched
      Swal.fire({
        title: 'We found a match!',
        text: 'You will be redirected to the room in a few seconds.',
        icon: 'success',
        confirmButtonText: 'Ok',
        timer: 5000,
        timerProgressBar: true,
      }).then(
        () => {
          navigate(`/cip3/peer/${roomId}`)
        }
      )

    }
  }
  return [toasts, modals];
};
