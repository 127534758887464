// @ts-nocheck
import React, { useState, useEffect, useCallback, useRef, useContext } from "react"; // every page needs to import react

// if you want to use the auth state
import { getAuth, updateProfile, signOut } from "firebase/auth";

// if you want to interact with the database
import "firebase/compat/firestore";
import Split from "react-split";

import { useComponentSize } from "react-use-size";
import "react-tabs/style/react-tabs.css";

import { LeftCol } from "./LeftCol";
import { FileEditor } from "./Editor/Editor";
import { TerminalPane } from "./TerminalPane/TerminalPane";
import { CanvasPane } from "./RightPane/CanvasPane";
import { IDEContext } from "./contexts/IDEContext";
import { KarelSelect } from "./RightPane/KarelSelect";

export const GUTTER_SIZE = 5;
const ASIDE_WIDTH = 58;
// instead of snapping, give the left col content a min width!
const SNAP_LEFT_COL_SIZE = 0;
const BOTTOM_BAR_SIZE = 0
const BOTTOM_BAR_COLOR = 'black' // blue
// const BOTTOM_BAR_COLOR = '#2D2e2c' // black

const LEFT_PCT = 30;
const RIGHT_PCT = 45; //of the editor/canvas split

export const IDEView = ({
  onFileCodeUpdate,
  currFile,
  terminalRef,
  projectData,
  assnData,
  onChangeFile,
  fileStructure,
  revertToStarterFn,
  setFileStructure,
}) => {
  const defaultPercent = [LEFT_PCT, 100 - LEFT_PCT]; // percent
  const minimumIdeWidth = 400; // px

  // const startingView = hasAssn ? "standard" : "minimized"
  const {leftColViewState, setLeftColViewState} = useContext(IDEContext);

  // this is the representation that Split needs
  const [collapsedIndex, setCollapsedIndex] = useState(undefined);
  // we need to keep track of the actual percentages too
  const [cachedSize, setCachedSize] = useState(defaultPercent);
  // this is the dimension of the outer component
  const outerSize = useComponentSize();


  // if the user drags a component to be small, react
  useEffect(() => {
    // ignore this if the window hasn't loaded yet
    if (outerSize.width == 0) return;

    const leftPanelPercent = cachedSize[0];
    const leftPanelWidth = outerSize.width * (leftPanelPercent / 100);
    if (leftPanelWidth <= ASIDE_WIDTH + GUTTER_SIZE) {
      setLeftColViewState("minimized");
    } else {
      setLeftColViewState("standard");
    }
  }, [cachedSize]);

  // if the minimize button is pressed, react
  useEffect(() => {
    if (leftColViewState === "minimized") {
      setCollapsedIndex(0);
    }
    if (leftColViewState === "standard") {
      setCollapsedIndex(undefined);
      setCachedSize(defaultPercent);
    }
    if (leftColViewState == "expanded") {
      setCollapsedIndex(1);
    }
  }, [leftColViewState]);

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.refreshTerm();
    }
  }, []);

  return (
    <div
      className="w-100 h-100 ideOuter"
      style={{ paddingTop: "55px" }}
      ref={outerSize.ref}
    >
      <Split
        className="w-100 d-flex"
        style={{ height: `calc(100% - ${BOTTOM_BAR_SIZE}px)` }}
        sizes={cachedSize} // this is in percentages
        minSize={[ASIDE_WIDTH, minimumIdeWidth]} // this seems to be in pixels
        expandToMin={false}
        gutterSize={GUTTER_SIZE}
        gutterAlign="center"
        snapOffset={[SNAP_LEFT_COL_SIZE, 0]} // this is also in pixels
        dragInterval={1} // must be a whole number
        direction="horizontal"
        onDrag={(sizes) => setCachedSize(sizes)}
        onDragStart={() => {}}
        onDragEnd={() => {}}
        collapsed={collapsedIndex}
      >
        <div
          className="pane"
          style={{ marginRight: "0px", backgroundColor: "#eee" }}
        >
          <LeftCol
            isMinimized={leftColViewState === "minimized"}
            setMinimizedState={setLeftColViewState}
            projectData={projectData}
            assnData={assnData}
            revertToStarterFn={revertToStarterFn}
            onChangeFile={onChangeFile}
            currFile={currFile}
            fileStructure={fileStructure}
            setFileStructure={setFileStructure}
            onFileCodeUpdate={onFileCodeUpdate}
          />
        </div>
        <div className="pane" style={{ position: "relative", zIndex: "1" }}>
          <EditorTerminalSplit
            {...{
              onFileCodeUpdate,
              currFile,
              projectData,
              terminalRef,
              revertToStarterFn
            }}
          />
        </div>
      </Split>
      <BottomBar/>
    </div>
  );
};

const EditorTerminalSplit = (props) => {
  const defaultPercent = [75, 25]; // percent
  const minimumEditorHeight = 100; // px
  const collapsedTerminalHeight = 20; // px
  const snapEditorSize = 0; // px
  const snapTerminalSize = 0; // px

  const {terminalViewState, setTerminalViewState} = useContext(IDEContext)

  // this is the representation that Split needs
  const [collapsedIndex, setCollapsedIndex] = useState(undefined);
  // we need to keep track of the actual percentages too
  const [cachedSize, setCachedSize] = useState(defaultPercent);
  // this is the dimension of the outer component
  const outerSize = useComponentSize();

  // if the window is resized, we may need to recalculate some things...
  useEffect(() => {}, [outerSize]);

  // if the user drags a component to be small, react
  useEffect(() => {
    // ignore this if the window hasn't loaded yet
    if (outerSize.width == 0) return;

    const terminalPercent = cachedSize[1];
    const terminalHeight = outerSize.height * (terminalPercent / 100);
    if (terminalHeight <= collapsedTerminalHeight + GUTTER_SIZE) {
      setTerminalViewState("minimized");
    } else {
      setTerminalViewState("standard");
    }
  }, [cachedSize]);

  // if the minimize button is pressed, react
  useEffect(() => {
    if (terminalViewState === "minimized") {
      setCollapsedIndex(1);
    }
    if (terminalViewState === "standard") {
      setCollapsedIndex(undefined);
      setCachedSize(defaultPercent);
    }
    if (terminalViewState == "expanded") {
      setCollapsedIndex(0);
    }
  }, [terminalViewState]);

  return (
    <div className="w-100 h-100" ref={outerSize.ref} style={{
      paddingBottom:'10px', backgroundColor:"#2d2e2c"}}>
      <Split
        className="h-100 w-100 d-flex flex-column"
        sizes={cachedSize} // this is in percentages
        minSize={[minimumEditorHeight, collapsedTerminalHeight]} // this seems to be in pixels
        expandToMin={false}
        gutterSize={GUTTER_SIZE}
        gutterAlign="center"
        snapOffset={[snapEditorSize, snapEditorSize]} // this is also in pixels
        dragInterval={1} // must be a whole number
        direction="vertical"
        onDrag={(sizes) => setCachedSize(sizes)}
        onDragStart={() => {}}
        onDragEnd={() => {}}
        collapsed={collapsedIndex}
      >
        <div className="pane">
          <EditorCanvasSplit {...props} />
        </div>
        <div style={{ backgroundColor: "#eee" }}>
          <TerminalPane
            viewState={terminalViewState}
            setViewState={setTerminalViewState}
            terminalRef={props.terminalRef}
          />
        </div>
      </Split>
    </div>
  );
};

const EditorCanvasSplit = (props) => {
  const defaultPercent = [100 - RIGHT_PCT, RIGHT_PCT]; // percent
  const collapsedEditorWidth = 200; // px
  const collapsedCanvasWidth = 40; // px
  const snapEditorSize = 0; // px
  const snapCanvasSize = collapsedCanvasWidth + 30; // px

  // this is the state that child components know to work with
  const {canvasViewState, setCanvasViewState} = useContext(IDEContext)

  // this is the representation that Split needs
  const [collapsedIndex, setCollapsedIndex] = useState(undefined);
  // we need to keep track of the actual percentages too
  const [cachedSize, setCachedSize] = useState(defaultPercent);
  // this is the dimension of the outer component
  const outerSize = useComponentSize();

  // if the window is resized, we may need to recalculate some things...
  useEffect(() => {}, [outerSize]);

  // if the user drags a component to be small, react
  useEffect(() => {
    // ignore this if the window hasn't loaded yet
    if (outerSize.width == 0) return;

    const canvasPercent = cachedSize[1];
    const canvasWidth = outerSize.width * (canvasPercent / 100);
    if (canvasWidth <= collapsedCanvasWidth + GUTTER_SIZE) {
      setCanvasViewState("minimized");
    } else {
      setCanvasViewState("standard");
    }
  }, [cachedSize]);

  // if the minimize button is pressed, react
  useEffect(() => {
    if (canvasViewState === "minimized") {
      setCollapsedIndex(1);
    }
    if (canvasViewState === "standard") {
      setCollapsedIndex(undefined);
      setCachedSize(defaultPercent);
    }
    if (canvasViewState == "expanded") {
      setCollapsedIndex(0);
    }
  }, [canvasViewState]);


  return (
    <div className="w-100 h-100" ref={outerSize.ref}>
      <Split
        className="h-100 w-100 d-flex"
        sizes={cachedSize} // this is in percentages
        minSize={[collapsedEditorWidth, collapsedCanvasWidth]} // this seems to be in pixels
        expandToMin={false}
        gutterSize={GUTTER_SIZE}
        gutterAlign="center"
        snapOffset={[snapEditorSize, snapCanvasSize]} // this is also in pixels
        dragInterval={1} // must be a whole number
        direction="horizontal"
        onDrag={(sizes) => setCachedSize(sizes)}
        onDragStart={() => {}}
        onDragEnd={() => {}}
        collapsed={collapsedIndex}
      >
        <div className="pane" style={{ zIndex: 100 }}>
          <FileEditor
            currFile={props.currFile}
            onFileCodeUpdate={props.onFileCodeUpdate}
            projectId={props.projectData.uid}
            revertToStarterFn={props.revertToStarterFn}
          />
              <KarelSelect/>

        </div>
        <div className="pane" style={{overflow:'auto'}}>
          <CanvasPane
            viewState={canvasViewState}
            setViewState={setCanvasViewState}
          />
        </div>
      </Split>
    </div>
  );
};

const BottomBar= () => {
  const style = {
    backgroundColor:BOTTOM_BAR_COLOR,
    height:`${BOTTOM_BAR_SIZE}px`
  }
  return <div className="w-100" style={style}/>
}
