
import React, { Component, useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import { KarelWorld } from 'components/pyodide/KarelLib/KarelWorld';
import { HorizontalSpeechBubble } from 'components/duolessons/lessontypes/SpeechBubbles';
import { Karel } from 'components/pyodide/KarelLib/karelapi';
import { getDefaultWorldState, areWorldsEqual, MAX_CORNER_SIZE } from 'components/pyodide/KarelLib/util';
import styled from 'styled-components';
import { useIsMobile } from 'utils/general';
import { FaSync } from 'react-icons/fa';

// data should have startState, goalState, and commands
export const KarelCommandsLesson = ({ onComplete, data }) => {


  const [worldState, setWorldState] = useState(data.startState)
  const karel = new Karel(setWorldState, worldState)
  const isMobile = useIsMobile()

  let nCols = data.startState.nCols
  
  const worldWidth = nCols * MAX_CORNER_SIZE

  const showGoal = true

  const world = translate('World')
  const goal = translate('Goal')

  useEffect(() => {
    if (areWorldsEqual(worldState, data.goalState)) {
      onComplete()
    }
  }, [worldState])


  const reset = () => {
    setWorldState(data.startState)
  }

  return (<LessonOuter>
    <WorldGoalOuter>
      <div style={{ width: worldWidth, marginBottom: 20 }}>
        <h3>{world}:</h3>
        <KarelWorld worldState={worldState} />
      </div>
      {!isMobile && <div style={{ width: 100 }} />}
      {showGoal && <div style={{ width: worldWidth, marginBottom: 20 }}>
        <h3>{goal}:</h3>
        <KarelWorld worldState={data.goalState} />
      </div>}
    </WorldGoalOuter>
    <ButtonRow
      data={data}
      karel={karel}
      reset={reset}
      worldState={worldState}
    />
  </LessonOuter>)
}

const ButtonRow = ({ karel, data, reset, worldState }) => {

  const isMobile = useIsMobile()

  const buttonsMobile = [
    { command: 'pick_beeper', action: () => karel.pick_beeper() },
    { command: 'put_beeper', action: () => karel.put_beeper() },
    { command: 'move', action: () => karel.move() },
    { command: 'turn_left', action: () => karel.turn_left() }
  ]

  const buttonsDesktop = [
    { command: 'move', action: () => karel.move() },
    { command: 'turn_left', action: () => karel.turn_left() },
    { command: 'pick_beeper', action: () => karel.pick_beeper() },
    { command: 'put_beeper', action: () => karel.put_beeper() },
  ]

  const buttons = isMobile ? buttonsMobile : buttonsDesktop

  const showCommands = parseCommands(data.commands)

  const showReset = data.commands.length > 1
  return <ButtonRowOuter>
    {buttons.map(({ command, action }) => (
      <CommandButton
        key={command}
        show={showCommands.includes(command)}
        onClick={() => action()}
        text={`${command}()`}
      />
    ))}


    {showReset && <div className='mr-1 ml-1 mb-2'>
      <Button aria-label="Reset world" disabled={areWorldsEqual(worldState, data.startState)} variant="secondary" onClick={reset} size="lg">
        <code style={{ color: 'white' }}><FaSync /></code>
      </Button>
    </div>}
  </ButtonRowOuter>
}

const CommandButton = ({ show, onClick, text }) => {
  if (!show) {
    return <></>
  }
  return <div className='mr-1 ml-1 mb-2'>
    <Button onClick={onClick} size="lg">
      <code style={{ color: 'white' }}>{text}</code>
    </Button>
  </div>
}

const translate = (key) => {
  return key
}

export function parseCommands(databaseCommands) {
  const commands = []
  for (let i = 0; i < databaseCommands.length; i++) {
    const command = databaseCommands[i]
    commands.push(command.value)
  }
  return commands
}

const ButtonRowOuter = styled.div`
  display:flex;
  justify-content:center;
  margin-bottom:20px;
  width:100%;
  flex-wrap:wrap;

  @media (max-width: 768px) {
    justify-content:center;
  }
`

const WorldGoalOuter = styled.div`
  display:flex;
  margin-bottom:20px;

  @media (max-width: 768px) { 
    flex-direction:column;
    align-items:center;
  }
`

const LessonOuter = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  width:100%;
  max-width:1200px;
  margin:auto;
`