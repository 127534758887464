import React, {createRef, useEffect, useRef} from 'react';
import useState from 'react-usestateref';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';


import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-latex';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/python';

import katex from 'katex';


// tiptap
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { isEmpty } from '@firebase/util';
import { EXAMPLE_WORLD_STATE } from '../../../pyodide/KarelLib/util';
import { KarelWorld } from '../../../pyodide/KarelLib/KarelWorld';
import { WorldEditor } from '../../../pyodide/KarelLib/WorldEditor';

export const KarelWorldTipTap = Node.create({
    name: 'karelworld',
    inline: false,
    group: 'block',
    atom: true,
    selectable:true,
    addAttributes() {
        return {
          worldState: {
            default:DEFAULT_WORLD_STATE
          },
          showEditor: {
            default: true 
          }
        }
      },
    
      parseHTML() {
        return [
          {
            tag: 'karelworld',
          },
        ]
      },
    
      renderHTML({ HTMLAttributes }) {
        return ['karelworld', mergeAttributes(HTMLAttributes)]
      },
    
      addNodeView() {
        return ReactNodeViewRenderer(RenderKarelWorldTipTap)
      },
  })

export const RenderKarelWorldTipTap = (props) => {

  const worldState = props.node.attrs.worldState
  const isEditable = props.editor.isEditable

  const setWorldState = (newStateFn) => {
    const newState = newStateFn(worldState)
    props.updateAttributes({
        worldState: newState
    })
  }

  let component = null
  if(isEditable) {
    component = <WorldEditor worldState={worldState} setWorldState={setWorldState}/>
  } else {
    component = <KarelWorld worldState={worldState}/>
  }
  
  return (
    <NodeViewWrapper >
      {component}
    </NodeViewWrapper>
  );
}

const DEFAULT_WORLD_STATE ={
    nRows:3,
    nCols:4,
    karelRow:0,
    karelCol:0,
    karelDir:'East'
  }
