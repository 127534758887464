import React from "react";
import { DiJavascript1, DiCss3Full, DiHtml5, DiReact } from "react-icons/di";
import { FaPython } from "react-icons/fa";

const FILE_ICONS = {
  js: <DiJavascript1 />,
  css: <DiCss3Full />,
  html: <DiHtml5 />,
  py: <FaPython />
};

export default FILE_ICONS;
