import { useContext, useEffect } from "react";
import { KarelWorld } from "../../components/pyodide/KarelLib/KarelWorld";
import { IDEContext } from "../contexts/IDEContext";
import { checkIsProjectConsole, checkIsProjectKarel } from "../utils/general";
import { GraphicsCanvas } from "./GraphicsCanvas";
import { TestList } from "ide/RightPane/TestView";
import { WorldEditor } from "components/pyodide/KarelLib/WorldEditor";

export const Canvas = (props) => {
  const {
    assnData,
    projectData,
    karelWorldState,
    defaultKarelWorldState,
    setDefaultKarelWorldState,
    setKarelWorldState } = useContext(IDEContext);
  const isConsole = checkIsProjectConsole(projectData,assnData);
  // karelWorldEditMode={karelWorldEditMode}

  // const isKarel = checkIsProjectKarel(assnData);


  const isKarel = checkIsProjectKarel(projectData,assnData);
  if (isKarel) {
    if(props.karelWorldEditMode) {
      return (
        <div className="w-100 d-flex">
          <WorldEditor
          worldState={defaultKarelWorldState}
          setWorldState={(karelState) => {
            setKarelWorldState(karelState)

            setDefaultKarelWorldState(karelState)
          }}

          />
        </div>
      )
    }
    return (
      <div className="w-100 d-flex">
        <KarelWorld worldState={karelWorldState} />
      </div>
    );
  }


  if (isConsole) {
    return (
      <div>
        <TestList/>
      </div>
    );
  }
  return <GraphicsCanvas {...props} />;
};
