import { TERMINAL_SETTINGS } from "./XTermSettings.js";
import { Terminal } from "xterm";
import "./xterm.css";
import { WebLinksAddon } from "xterm-addon-web-links";

import { getErrorMessage } from "../../ErrorMessage/errorhint.js";

// Init terminal term
/**
 * @name TerminalView
 * @description TerminalView class that handles all interactions with the terminal
 */
export class TerminalView {
  constructor(userSpecifiedShellprompt, runCode, onEnd, raiseStop, isKarel,setReplMode, runReplCode) {
    this.term = new Terminal(TERMINAL_SETTINGS);
    this.term.loadAddon(new WebLinksAddon());
    this.fontSizePx = (this.term.options.fontSize * 4) / 3;
    this._currCmd = "";
    this._shellprompt = `${userSpecifiedShellprompt} `;
    this._input = false;
    this._stdinPromise = {};
    this._runCode = runCode;
    this._onEnd = onEnd;
    this._code = "";
    this._raiseStop = raiseStop;
    this.termState = "% ";
    this._stepped = false;
    this._deleteBuffer = this._shellprompt.length;
    this.isKarel = isKarel;
    this._cmdBuffer = []
    this._cmdPtr = 0;
    this._setReplMode = setReplMode;
    this.replMode = false
    this.runReplCode = runReplCode;
    this.replCode = ""
  }

  /**
   * @name initTerm
   * @description Initializes the terminal
   * @returns {void}
   */
  initTerm() {
    this.term.open(document.getElementById("terminal"));
    this._stdinPromise = this.getNewPromise();
    this.term.onKey((e) => this._onKey(e));
    this.term.textarea.onfocus = () => {
      if (this._stepped) {
        this.term.clear();
        let newTermState = this.termState.replace(/\n/g, "\r\n");
        this.writeAndScroll(newTermState);
        this._stepped = false;
      }
    };

    this.writeAndScroll(this._shellprompt);
  }

  /**
   * @name focusTerm
   * @description Focuses the terminal - useful for instances like when the user is prompted for input
   * @returns {void}
   */
  focusTerm() {
    this.term.textarea.focus();
    this.term.focus();
  }

  /**
   * @name blur
   * @description Blurs the terminal element - useful for after running python via the terminal
   * @returns {void}
   */
  blur() {
    this.term.textarea.blur();
    this.term.blur();
  }



  /**
   * @name refreshTerm
   * @description Refreshes the terminal
   * @returns {void}
   */
  refreshTerm() {
    this.term.open(document.getElementById("terminal"));
    this.writeAndScroll("");
  }


  /**
   * @name _prompt
   * @description Prompts the user for input
   * @param {boolean} pt - If in repl mode, whether or not the user is in the middle of a command or just beginning one
   * @returns {void}
   */
  _prompt(pt = true) {
    this._currCmd = "";
    if(this.replMode) {
      if(pt) {
        this.writeAndScroll("\r\n>>> ");
      } else {
        this.writeAndScroll("\r\n... ");

      }
    } else {
      this.writeAndScroll("\r\n" + this._shellprompt);
    }
  }

  /**
   * @name _prepareOutput
   * @description Prepares the terminal for output by adding a newline
   */
  _prepareOutput() {
    this.writeAndScroll("\r\n");
  }

  /**
   * @name _onKey
   * @description Handles keypresses in the terminal
   * @param {*} e - event object
   */
  async _onKey(e) {
    const ev = e.domEvent;
    const printable = !ev.altKey && !ev.ctrlKey && !ev.metaKey;
    const ctrlPressed = ev.ctrlKey;
    if (ev.keyCode === 67 && ctrlPressed) {
      this._raiseStop();
    }
    switch (ev.keyCode) {
      case 13:
        if (this._input && this._stdinPromise.promise) {
          this._stdinPromise.resolve();
        } else {
          this._cmdBuffer.push(this._currCmd);
          if(this._cmdBuffer.length > 20) {
            this._cmdBuffer.shift();
          }
          this._cmdPtr = this._cmdBuffer.length;
          if(this.replMode) {
            await this._handleReplEnter(this._currCmd);
          } else {
            this._handleEnter(this._currCmd);
          }
          this._currCmd = "";
        }
        break;
      case 8:
        // If cursor is not over shellprompt
        if (
          this.term._core.buffer.x > this._deleteBuffer ||
          this._deleteBuffer + this._currCmd.length >=
            this.term.cols - 1 ||
          this._input
        ) {
          this.writeAndScroll("\b \b");
          this._currCmd = this._currCmd.substring(0, this._currCmd.length - 1);
        }
        break;
      case 38:
        if(this._cmdBuffer.length > 0) {
          for(let i = 0; i < this._currCmd.length; i ++)  {
            this.writeAndScroll("\b \b");
          }
          if(this._cmdPtr > 0) {
            this._cmdPtr -= 1
          }
          this._currCmd = this._cmdBuffer[this._cmdPtr]
          this.writeAndScroll(this._currCmd);
        }
        break;
      case 40:
        if(this._cmdBuffer.length > 0) {
          for(let i = 0; i < this._currCmd.length; i ++)  {
            this.writeAndScroll("\b \b");
          }
          if(this._cmdPtr < this._cmdBuffer.length) {
            this._cmdPtr += 1;
          }
          if (this._cmdPtr === this._cmdBuffer.length) {
            this._currCmd="";
          }
          else {
            this._currCmd = this._cmdBuffer[this._cmdPtr]
            this.writeAndScroll(this._currCmd);
          }
        }
        break;
      default:
        // If key is printable and not arrow key
        if (printable && (ev.keyCode < 37 || ev.keyCode > 40)) {
          this._currCmd = this._currCmd + e.key;
          this.writeAndScroll(e.key);
        }
        break;
    }
  }

  /**
   * @name _handleEnter
   * @description Handles the enter key being pressed
   * @param {*} cmd - command to be handled
   * @returns {Promise<void>}
   */
  async _handleEnter(cmd) {
    // Split into args
    const args = cmd.split(/\s+/);
    const firstArg = args[0];

    this._currCmd = "";

    // Check for keywords, else print command unknown
    if (firstArg === "python") {
      if (args[1]) {
        this._runCode();
      } else {
        this.writeAndScroll("\r\nPython 3.9");
        this.writeAndScroll("\r\nCode In Place 2023 Repl Mode");
        this.writeAndScroll(`\r\nType "quit()" to quit repl mode`);
        await this.toggleRepl();
      }
    } else if (firstArg === "clear") {
      this.term.clear();
      this._prompt();
    }else if (firstArg === "cd") {
      this.writeAndScroll("\r\n");
      this.writeAndScroll("No file directory here!");
      this._prompt();
    }else if (firstArg === "ls") {
      this.writeAndScroll("\r\n");
      this.writeAndScroll("What files do we have ");
      this._prompt();
    } else if (firstArg === "pip") {
      this.writeAndScroll("\r\n");
      this.writeAndScroll("You can't install packages, but you can import numpy!");
      this._prompt();
    } else if (firstArg === "echo") {
      this.writeAndScroll("\r\n");
      args.shift()
      for(var i of args) {
        this.writeAndScroll(i + " ");

      }
      this._prompt();
    }else {
      for (let unknownArg of args) {
        if (unknownArg.trim().length !== 0) {
          this.writeAndScroll("\r\n");
          this.writeAndScroll("command not found: ");
          this.writeAndScroll(unknownArg);
        }
      }
      this._prompt();
    }
  }

  /**
   * @name _handleReplEnter
   * @description Handles the enter key being pressed in repl mode
   * @param {*} cmd 
   */
  async _handleReplEnter(cmd) {
    if(cmd === "quit()") {
      await this.toggleRepl();
    }
    else {
      this.replCode = `\n${cmd}`
      let promptType = await this.runReplCode(this.replCode)
      if(this.replMode) {
        this._prompt(promptType);

      }
    }
    this._currCmd="";

  }

  /**
   * @name endStdin
   * @description resolves the stdin promise
   */
  endStdin() {
    if(this._input && this._stdinPromise) {
      this._stdinPromise.resolve()
    }
  }


  /**
   * @name handleStdin
   * @description Handles stdin
   * @param {*} _ 
   * @returns 
   */
  async handleStdin(_) {
    this._stdinPromise = this.getNewPromise();
    this._input = true;
    await this._stdinPromise.promise;
    this._input = false;
    // Reset promise
    this._stdinPromise = this.getNewPromise();
    const returnedInput = this._currCmd;
    this._currCmd = "";
    return returnedInput;
  }


  /**
   * @name handleStdout
   * @description Writes stdout to terminal
   * @param {string} stdout 
   * @param {boolean} nl 
   */
  handleStdout(stdout, nl=true) {
    if (stdout !== "Python initialization complete") {
      if(nl) {
        this._prepareOutput();
      }
      this.term.scrollToBottom();
      this.writeAndScroll(stdout);
    }
  }

  /**
   * @name handleStderr
   * @description Handles stderr by delegating to the getErrorMessage (a proxy for a cloud func) and writing the output to the terminal
   * @param {string} code 
   * @param {string} stderr 
   * @returns {Promise<string>} error message to be shown to user
   */
  async handleStderr(code, stderr) {
    // Use the selectedErrorMessageType to get an error message
    const error_message = await getErrorMessage(
      code,
      stderr,
      this
    );

    // Output error message to the terminal
    this._prepareOutput();
    this.term.scrollToBottom();
    this.writeAndScroll(error_message);

    return error_message;
  }

  /**
   * @name handleEnd
   * @description Handles the end of a script run
   * @returns {void}
   */
  handleEnd() {
    this.term.scrollToBottom();
    this._prompt();
    this._onEnd();
    this.term.selectAll();
    this.termState = this.term.getSelection();
    this.term.selectLines(0, 0);
  }

  /**
   * @name getNewPromise
   * @returns {object} - object with promise and resolve members
   */
  getNewPromise() {
    let resolveFunc;
    // Returns object with promise member and resolve member
    return {
      promise: new Promise(function (resolve, reject) {
        resolveFunc = resolve;
      }),
      resolve: resolveFunc,
    };
  }

  /**
   * @name updateCols
   * @description Updates the number of cols in the terminal
   * @param {*} newWidth 
   */
  updateCols(newWidth) {
    // TO DO - make this less hacky
    this.term.resize(
      Math.floor((2.2 * newWidth) / this.fontSizePx),
      this.term.rows
    );
  }

  /**
   * @name updateRows
   * @description Updates the number of rows in the terminal
   * @param {*} newHeight 
   */
  updateRows(newHeight) {
    // TO DO - make this less hacky
    this.term.resize(this.term.cols, Math.floor((newHeight - 30) / 17));
  }


  /**
   * @name writeAndScroll
   * @description Writes to the terminal and scrolls to the bottom
   * @param {string} str - content to be written to terminal 
   */
  writeAndScroll(str) {
    this.term.write(str);
    this.term.scrollToBottom();
  }


  /**
   * @name toggleRepl
   * @description Toggles repl mode
   * @returns {Promise<void>}
   */
  async toggleRepl() {
    this.replMode = !this.replMode;
    if(this.replMode) {
      this._deleteBuffer = 4
    } else {
      this._deleteBuffer = this._shellprompt.length;
    }
    await this._setReplMode(this.replMode);
    this._prompt()
  }


  /**
   * @name dispose
   * @description Disposes of the terminal
   * @returns {void}
   */
  dispose() {
    this.term.dispose();
  }
}
