import { useContext } from 'react'
import { FaLock, FaPython, FaTrash, FaUndo, FaUnlock } from "react-icons/fa";
import { Button } from 'react-bootstrap';
import Swal from "sweetalert2";
import Markdown from "../../components/markdown/Markdown";
import { TipTap } from "../../components/richTextEditor/TipTap/TipTap";
import { IDEContext } from "../contexts/IDEContext";
import styled from 'styled-components';

const AssnPrompt = ({ promptPath, solnPath, assnTitle, workedExample, revertToStarterFn, bottomBarRef }) => {
  const bottomBarBuffer = bottomBarRef && bottomBarRef.current ? bottomBarRef.current?.clientHeight || 0 : 0;



  return (
    <>
      <div
        className="w-100"
        style={{
          paddingLeft: "10px",
          paddingRight: "20px",
          display: "flex",
          maxWidth: "550px",
          justifyContent: "space-between",
          paddingTop: "10px",
        }}
      >
        <TitleBar
          assnTitle={assnTitle}
          workedExample={workedExample}
          revertToStarterFn={revertToStarterFn}
        />
      </div>
      <div
        className="w-100 h-100"
        style={{
          flexGrow: 2,
          paddingLeft: "10px",
          paddingRight: "20px",
          textAlign: 'justify',
          fontSize: '14px',
          overflow: "auto",
          paddingBottom: bottomBarBuffer,

        }}
      >
        {workedExample ? <SolutionSection solnPath={solnPath} /> : <></>}
        <TipTap
          editable={false}
          collaborative={false}
          firebaseDocPath={promptPath}
          onServerWrite={() => { }}
          buttonBar={() => { }}
        />

      </div>
    </>
  );
};

const TitleBar = ({ assnTitle, workedExample, revertToStarterFn }) => {
  function onRestartAssn() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-primary mr-2",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: `Are you sure you want to restart this assignment?`,
        text: 'Your code will revert to the "starter code" and your current code will be deleted.',
        icon: "warning",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Yes, delete my current code!",
        cancelButtonText: "Never mind",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          console.log("revert to starter");
          await revertToStarterFn();
        }
      });
  }


  return (
    <div className="d-flex justify-content-between w-100 align-items-baseline">
      <span
        className="ideHeadingLight"
      >{workedExample ? 'Practice' : 'Instructions'}</span>

      <button className="btn btn-sm btn-light" onClick={() => onRestartAssn()}>
        <div className="d-flex flex-row align-items-center"><FaTrash className="mr-1" />Restart</div>
      </button>
    </div>
  );
};

const SolutionSection = ({ solnPath }) => {
  const { showSoln, setShowSoln } = useContext(IDEContext);
  const showSolnText = <><FaUnlock/> Show solution</>
  const hideSolnText = <><FaLock/> Hide solution</>

  return <>

    <button
      className='btn btn-light btn-sm mb-2'
      onClick={() => { setShowSoln(!showSoln) }}
    >{showSoln ?  hideSolnText : showSolnText}</button>
    {showSoln && <>
      <SolutionTitle>Example Solution: </SolutionTitle>
      <TipTap
        editable={false}
        collaborative={false}
        firebaseDocPath={solnPath}
        onServerWrite={() => { }}
        buttonBar={() => { }}
      />
      <hr />
    </>
    }

  </>
}

const SolutionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  `

export default AssnPrompt;
