import React, { useContext, useEffect, useState } from "react"; // every page needs to import react

// if you want to interact with the database
import "firebase/compat/firestore";
import {
  FaChevronLeft,
  FaFileAlt,
  FaFolderOpen,
  FaQuestion,
  FaFlag,
  FaChevronRight,
  FaShoePrints,
  FaComments,
  FaQuestionCircle,
  FaGlasses,
  FaTools,
  FaCog,
  FaComment
} from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import AssnPrompt from "./AssnPrompt/AssnPrompt";
import FileTree from "./Files/FileTree";
import { getPromptPath, getSolnPath } from "../utils/general";
import { DocsSplash } from "./LeftCols/DocsSplash";
import { StepperView } from "./LeftCols/StepperView";
import { IDEContext } from "./contexts/IDEContext";
import { PeerSessionContext } from "./contexts/PeerSessionContext";
import { ChatPage } from "../components/chat/ChatContainer";
import { checkIsProjectKarel } from "./utils/general";
import { StyleFeedbackTab } from "../course/styleFeedback/StyleFeedbackTab";
import { CHAT_NAMES, CHAT_TYPES } from "components/duolessons/lessonchats/ChatConstants";

import { SettingsView } from "./LeftCols/Settings";
import { IDEChat } from "components/duolessons/lessonchats/IDEChat";
import { NewMessageDot } from "components/duolessons/lessonchats/NewMessageDot";


// Making the page a variable here is problematic because you
// have to create the prompt page before you know what the assnId.
const NAV_STATES = [
  {
    name: "JITHChat",
    icon: <FaComments size={20} />,
  },
  {
    name: "Your Task",
    icon: <FaFlag size={18} />,
  },
  {
    name: "Docs",
    icon: <FaFileAlt size={20} />,
  },
  {
    name: "Files",
    icon: <FaFolderOpen size={20} />,
  },
  {
    name: "Replay",
    icon: <FaShoePrints size={20} />,
  },
  // {
  //   name: "Style Feedback",
  //   icon: <FaGlasses size={20} />,
  // }
  {
    name: "Chat", // This one is for Sierra's chatbot experiment
    icon: <FaComment size={20} />,
  },
];

const Page = ({
  selectedTab,
  promptPath,
  solnPath,
  projectData,
  assnData,
  revertToStarterFn,
  onChangeFile,
  currFile,
  fileStructure,
  setFileStructure,
  hasAssn,
  onFileCodeUpdate
}) => {
  if (selectedTab === "Your Task") {
    let title = assnData?.metaData?.title
      ? assnData.metaData.title
      : projectData.title;
    let workedExample = assnData?.metaData?.workedExample
    return (
      <AssnPrompt
        promptPath={promptPath}
        solnPath={solnPath}
        assnTitle={title}
        workedExample={workedExample}
        revertToStarterFn={revertToStarterFn}
      />
    );
  }
  if (selectedTab === "Files") {
    return (
      <>
        <LeftColTitle title="Project Files" />
        <FileTree
          projectId={projectData.uid}
          fileStructure={fileStructure}
          setFileStructure={setFileStructure}
          onChangeFile={onChangeFile}
          currFile={currFile}
          isCreative={!hasAssn}
          onFileCodeUpdate={onFileCodeUpdate}
        />
      </>
    );
  }
  if (selectedTab === "Docs") {
    return <DocsSplash />;
  }
  if (selectedTab === "Replay") {
    return <StepperView />;
  }
  if (selectedTab === "Settings") {
    return <SettingsView />;
  }
  if (selectedTab === "JITHChat") {
    return (
      <div style={{ height: "100%", padding: "10px" }}>
        <ChatPage />
      </div>
    );
  }
  if (selectedTab === "Style Feedback") {
    return (
      // <LeftColTitle title="Coming Soon!" />
      <StyleFeedbackTab
        projectData={projectData}
        fileStructure={fileStructure}
      />
    );
  }
  if (selectedTab === "Chat") {
    return (
      <IDEChat />
    );
  }
  return <LeftColTitle title="Coming Soon!" />;
};

export const LeftCol = ({
  isMinimized,
  setMinimizedState,
  projectData,
  assnData,
  revertToStarterFn,
  currFile,
  onChangeFile,
  fileStructure,
  setFileStructure,
  onFileCodeUpdate
}) => {
  const promptPath = getPromptPath(projectData);
  const solnPath = getSolnPath(projectData)
  const { setSelectedTab, selectedTab } = useContext(IDEContext);
  const { isMeeting } = useContext(PeerSessionContext);
  const hasAssn = promptPath != undefined;

  const { stepMode } = useContext(IDEContext);
  const isKarel = checkIsProjectKarel(projectData, assnData)

  useEffect(() => {
    const startIndex = isMeeting ? 0 : hasAssn ? 1 : 2; // used to add chat as default for meetings
    setSelectedTab(NAV_STATES[startIndex].name);
  }, [])

  useEffect(() => {
    if (stepMode && !isKarel) {
      setSelectedTab("Replay");
    }
  }, [stepMode]);

  return (
    <div
      className="d-flex flex-row h-100"
      style={{
        backgroundColor: "rgb(256, 256, 256)",
      }}
    >
      <SideBar
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isMinimized={isMinimized}
        setMinimizedState={setMinimizedState}
        promptPath={promptPath}
        isMeeting={isMeeting}
      />

      <div
        className="d-flex flex-column w-100"
        style={{
          backgroundColor: "white",
          height: 'calc(100vh - 60px)',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        <Page
          promptPath={promptPath}
          solnPath={solnPath}
          selectedTab={selectedTab}
          projectData={projectData}
          onChangeFile={onChangeFile}
          currFile={currFile}
          fileStructure={fileStructure}
          setFileStructure={setFileStructure}
          assnData={assnData}
          revertToStarterFn={revertToStarterFn}
          hasAssn={hasAssn}
          onFileCodeUpdate={onFileCodeUpdate}
        />
      </div>
    </div>
  );
};

const SideBar = ({
  selectedTab,
  setSelectedTab,
  isMinimized,
  setMinimizedState,
  promptPath,
  isMeeting
}) => {
  const hasAssn = promptPath != undefined;
  const { setHelpMode, assnData, isDiagnostic, chatType } = useContext(IDEContext);
  const isKarel = assnData?.metaData?.type === "karel";


  const handleKeyPress = (event, func) => {
    if (event.key === 'Enter') {
      // Simulate a click event
      func();
    }
  };

  return (
    <div className="pt-0 d-grid flex-column justify-space-between ide-side-bar border-end align-content-between h-100">
      {/* <MinimizeButton {...{ isMinimized, setMinimizedState }} /> */}

      <div className="d-flex flex-column">
        {NAV_STATES.map((item) => {
          if ((item.name === "Your Task" && !hasAssn) ||
            (item.name === "JITHChat" && !isMeeting) ||
            (item.name === "Replay" && (isKarel || isDiagnostic)) ||
            (item.name === "Chat" && (isDiagnostic || CHAT_TYPES[chatType] === null || CHAT_TYPES[chatType].name !== CHAT_NAMES.IDE))){
            return <span key={item.name}></span>;         // empty span render
          }

          return (
            <NavIcon
              isMinimized={isMinimized}
              setMinimizedState={setMinimizedState}
              key={item.name}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              state={item.name}
              icon={item.icon}
              id={item.name + "_sidebar_icon"}
              onKeyPress={handleKeyPress}
            />
          );
        })}
      </div>
      <div className="d-flex flex-column align-content-center justify-center">
        {
          !isDiagnostic && <OverlayTrigger
            placement="right"
            delay={{ show: 100, hide: 100 }}
            overlay={(props) => (
              <Tooltip id="button-tooltip" {...props}>
                IDE Settings
              </Tooltip>
            )}
          >
            <div
              onClick={() => {
                setSelectedTab('Settings');
              }}
              onKeyDown={(e) => handleKeyPress(e, () => {
                setSelectedTab('Settings');
              })}
              className={`btn radius mt-2`}
              tabIndex={0}
            >
              <label className="visually-hidden">IDE Settings</label>

              <FaCog size={24} />
            </div>
          </OverlayTrigger>
        }
        <OverlayTrigger
          placement="right"
          delay={{ show: 100, hide: 100 }}
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              IDE Help
            </Tooltip>
          )}
        >
          <div
            onClick={() => {
              setHelpMode(true);
            }}
            onKeyDown={(e) => handleKeyPress(e, () => {
              setHelpMode(true);
            })}
            className={`btn radius mt-2 mb-2`}
            tabIndex={0}
            id="help-btn"
          >
            <label className="visually-hidden">IDE Help</label>
            <FaQuestionCircle size={24} />
          </div>
        </OverlayTrigger>

      </div>
    </div>
  );
};
const NavIcon = ({
  icon,
  state,
  selectedTab,
  setSelectedTab,
  isMinimized,
  setMinimizedState,
  id,
  onKeyPress
}) => {
  const { setChatTimestamp, setUnreadMessageFlag, unreadMessageFlag } = useContext(IDEContext);

  // the nav is selected if...
  const isSelected = selectedTab === state;

  // decide if the button is selected
  let buttonSelectionClass = "ide-nav-unselected";
  if (!isMinimized && isSelected) {
    buttonSelectionClass = "ide-nav-selected";
  }

  const onClick = () => {
    if (isMinimized) {
      setMinimizedState("standard");
    }
    if (isSelected && !isMinimized) {
      setMinimizedState("minimized");
    }
    setSelectedTab(state);

    if (state === "Chat") {
      setChatTimestamp();
      setUnreadMessageFlag(false);
    }
  };

  if (state === "Chat") {
    return (
      <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => (
        <Tooltip id="button-tooltip" {...props}>
          {state}
        </Tooltip>
      )}
    >
      <div
        id={id}
        onClick={() => onClick()}
        className={`btn radius ` + buttonSelectionClass}
        tabIndex={0}
        aria-label={state}
        onKeyDown={(e) => onKeyPress(e, onClick)}
        style={{ position: 'relative' }}
      >
        {unreadMessageFlag && <NewMessageDot topPx={"8px"} rightPx={"8px"} />}
        {icon}
      </div>
    </OverlayTrigger>
    );
  }

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => (
        <Tooltip id="button-tooltip" {...props}>
          {state}
        </Tooltip>
      )}
    >
      <div
        id={id}
        onClick={() => onClick()}
        className={`btn radius ` + buttonSelectionClass}
        tabIndex={0}
        aria-label={state}
        onKeyDown={(e) => onKeyPress(e, onClick)}
      >
        {icon}
      </div>
    </OverlayTrigger>
  );
};
const MinimizeButton = ({ isMinimized, setMinimizedState }) => {
  // if you hit the button, we will set the state to this value
  const toggleState = isMinimized ? "standard" : "minimized";
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 100 }}
      overlay={(props) => (
        <Tooltip id="button-tooltip" {...props}>
          {isMinimized ? "Show Panel" : "Hide Panel"}
        </Tooltip>
      )}
    >
      <button
        className="btn"
        style={{
          padding: "12px 11px 12px 11px",
        }}
        onClick={() => setMinimizedState(toggleState)}
      >
        {getMinimizeIcon(isMinimized)}
      </button>
    </OverlayTrigger>
  );
};

const getMinimizeIcon = (isMinimized) => {
  if (isMinimized) {
    return <FaChevronRight size={20} />;
  }
  return <FaChevronLeft size={20} />;
};

export const LeftColTitle = ({ title }) => {
  return (
    <div
      className="w-100"
      id="left-col-title"
      style={{
        paddingLeft: "10px",
        paddingRight: "10px",
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "10px",
      }}
    >
      <span className="ideHeadingLight">{title}</span>
    </div>
  );
};


