import React, { createContext } from "react";
import 'firebase/compat/auth';


export const PeerSessionContext = createContext({
    // roomId : undefined as string,
    // roomStatus: undefined as string,
    meetingType: null,
    isMeeting: false,
    endSession: () => {},
    meetingRole: null
})

interface Props {
    // roomId: string,
    meetingType: 'peer' | 'officehours' | null,
    onEndSession: () => Promise<any>,
    children: React.ReactNode,
    meetingRole: 'pilot' | 'learner' | 'sl' | 'extra-sl' | null
}

export const PeerSessionProvider = ({
    // courseId,
    // roomId,
    meetingType, 
    onEndSession,
    children,
    meetingRole
}: Props) => {
  // @ts-ignore
  // const [user, loading] = useAuthState(firebase.auth());
  // const roomStatus = useRoomStatus(courseId, roomId, user.uid)

    return (
        <PeerSessionContext.Provider
        value={{
            // roomId,
            // roomStatus,
            meetingType: meetingType,
            isMeeting: true,
            endSession: onEndSession,
            meetingRole: meetingRole
        }}>
            { children }
        </PeerSessionContext.Provider>
    )
}
