import React, { useState, useEffect, useRef } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { FaBook, FaChevronLeft } from "react-icons/fa";
import styled from 'styled-components';

export const HorizontalSpeechBubble = ({ 
    text, 
    iconPath = undefined 
  }) => {
  const imgPath = iconPath || "/karel192.png"
  return <HorizontalSpeechBubbleOuter>
    <IconSmall src={imgPath} alt=""/><RightSpeechBubble text={text} />
  </HorizontalSpeechBubbleOuter>
}

export const SpeechBubbleLesson = ({ data }) => {
  const text = data.text
  const imgPath = data.imgUrl || "/karel192.png"
  return <CenteredContent >
    <TopSpeechBubble text={text} />
    <IconLarge src={imgPath} alt=""/>
  </CenteredContent>
}

const RightSpeechBubble = ({ text }) => (
  <SpeechBubbleContainer>
    <HorizontalSpeechBubbleText tabIndex={0}>{text}</HorizontalSpeechBubbleText>
    <TailLeft />
  </SpeechBubbleContainer>
);

const TopSpeechBubble = ({ text }) => (
  <SpeechBubbleContainer style={{ minHeight: 80 }}>
    <SpeechBubbleText tabIndex={0}>{text}</SpeechBubbleText>
    <TailBottom />
  </SpeechBubbleContainer>
);

const IconLarge = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 10px;
`;

// should always have a 1:1 aspect ratio
const IconSmall = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 5px;
`;

const SpeechBubbleContainer = styled.div`
  position: relative;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 15px;
  margin-top:10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid grey;
`;

const SpeechBubbleText = styled.p`
  margin: 0;
  color: #333333;
  font-size: 16px;
  text-align: center;
`;

const HorizontalSpeechBubbleOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  `

const HorizontalSpeechBubbleText = styled.p`
margin: 0;
color: #333333;
font-size: 16px;
`

const TailBottom = styled.div`
  position: absolute;
  bottom: -10px; // The tip of the tail will align with the bottom of the bubble
  left: 50%;
  transform: translateX(-50%) rotate(225deg);
  background: #FFFFFF;
  width: 20px; // Width of the tail square before rotation
  height: 20px; // Height of the tail square before rotation
  content: '';
  clip-path: polygon(0 0, 100% 0, 0 100%); // Clip the square to form a triangle
  border: 1px solid grey;
`;

const TailLeft = styled.div`
  position: absolute;
  top: 50%; // Align the center of the tail with the center of the bubble's side
  left: -10px; // The tip of the tail will align with the left side of the bubble
  transform: translateY(-50%) rotate(315deg); // Adjust rotation to point left
  background: #FFFFFF;
  width: 20px; // Width of the tail square before rotation
  height: 20px; // Height of the tail square before rotation
  content: '';
  clip-path: polygon(0 0, 100% 0, 0 100%); // Clip the square to form a triangle
  border: 1px solid grey;
`;

const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;