import { useEffect, useRef, useContext } from 'react';
import { LessonContext } from "../LessonContext";
import { TypingAnimation } from "./TypingAnimation";
import { MessageInputArea } from './MessageInputArea';
import { ChatWindow, MessagesDisplay } from './ChatStyles';
import { ChatHeader } from './ChatHeader';
import { SimpleMessage } from './SimpleMessage';

export const SimpleChatView = ({ sendMessage, currentMessage, setCurrentMessage, sendEnabled }) => {

    // Keep track of the page Index from the lesson context
    const { chatMessages, tempMessages } = useContext(LessonContext);

    // Reference to the bottom of the messages display
    const messagesEndRef = useRef(null)

    // Scroll to the bottom of the messages display when the messages array changes
    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }, [chatMessages, tempMessages]);

    return (
        <ChatWindow>
            {/* The Chat bar on top */}
            <ChatHeader />

            {/* The messages area */}
            <MessagesDisplay>
                {[...chatMessages].reverse().map((message, index) => (
                    <SimpleMessage
                        key={index}
                        message={message}
                    />
                ))}
                {[...tempMessages].map((message, index) => (
                    <SimpleMessage
                        key={index}
                        message={message}
                    />
                ))}
                {!sendEnabled && <TypingAnimation />}
                <div ref={messagesEndRef} />
            </MessagesDisplay>

            {/* The user input area */}
            <MessageInputArea
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
                sendMessage={sendMessage} />
        </ChatWindow>
    );

};