import {
  collection,
  doc,
  getDoc,
  getFirestore,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import { useUserId } from "hooks/user/useUserId";
import {
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { useCourseId } from "hooks/router/useUrlParams";

interface IExperiments {
  // true means in-treatment group. false means you are not
  // for undefined, assume false
  care: boolean | undefined;
  styleFeedback: number | undefined;
  exp1_showChatGPT: number | undefined;
  exp2_showGPTHandout: number | undefined;
  exp3_QuestionPool: number | undefined;
  // TODO: others
}

interface IExpContext {
  expLoading: boolean;
  expData: IExperiments | undefined;
}

const defaultData = {
  expLoading: true,
  expData: undefined,
};

export const ExperimentContext = React.createContext<IExpContext>(defaultData);

export const ExperimentProvider = ({ children }) => {
  const db = getFirestore();
  const userId = useUserId();
  const courseId = useCourseId();

  const path = `users/${userId}/${courseId}/experimentFlags`;
  const expRef = doc(db, path);

  const [expDataRaw, expLoading, expError] = useDocumentData(expRef);


  const expData = expDataRaw as IExperiments;

  if (expError) {
    console.error(expError);
  }

  return (
    <ExperimentContext.Provider
      value={{
        expData,
        expLoading,
      }}
    >
      {children}
    </ExperimentContext.Provider>
  );
};
