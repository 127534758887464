import React, { useContext } from "react";
import { FaEnvelope } from "react-icons/fa";
import Swal from 'sweetalert2'
import {
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { ReactComponent as GoogleIconColor } from "icons/google.svg";
import { LoginContext, LoginScreen } from "./LoginContext";
import { SignInButton } from "./SignInButton";

export const AlternateAuth = ({
  auth,
  btnVariant = "outline-primary"
}) => {
  const provider = new GoogleAuthProvider();
  const [loginState, setLoginState] = useContext(LoginContext)

  const handleGoogleSignin = () => {
    signInWithPopup(auth, provider)
      .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
        });
      }) 


  }

  return (
    <div className="d-flex flex-column justify-content-center align-content-center">
      <SignInButton
        className="" 
        variant={btnVariant} 
        onClick={handleGoogleSignin}>
        <GoogleIconColor style={{ width: '24px', height: '24px' }} />&nbsp;
        Continue with Google
      </SignInButton>
      <SignInButton 
        className="d-inline-flex align-items-center mb-2" 
        variant={btnVariant} 
        onClick={() => setLoginState(LoginScreen.EMAIL_LINK)}>
        <FaEnvelope style={{ width: '24px', height: '24px' }} />&nbsp;
        Continue with One-Time Link
      </SignInButton>
    </div>
  )
}
