import { PilotToggle } from "./PilotToggle";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import { FaPhone } from "react-icons/fa";
import { PeerSessionContext } from "../contexts/PeerSessionContext";
import { useParams } from "react-router";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import { PilotRequestNotification } from "./Notifications";
import { useRoomMembers, useRole } from "../../firebase/realtime/Rooms";
import { isMinimumRole } from "contexts/ProfileUtil";
import { Role } from "contexts/ProfileContext";
import { roleStrToEnum } from "ide/EndOfPeerSesson";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { doc, getFirestore } from "firebase/firestore";
import { Loading } from "components/loading/Loading";
import { PeopleInRoom } from "./PeopleInRoom";



export const MeetingButtons = (props) => {
    const { originalMeetingRole }  = props
    const { meetingType, endSession } = useContext(PeerSessionContext);
    const { roomId } = useParams()
    const auth = getAuth();
    const user = auth.currentUser;
    const { courseId } = useParams()
    const memberInfo  = useRoomMembers(courseId, roomId)

    const db = getFirestore();
    const docRef = doc(db, "users", user.uid);
    const  [userData, userDataLoading, userDataError] = useDocumentDataOnce(docRef);
    const courseRoleStr = userData?.roles?.cip3
    const courseRole = courseRoleStr !== undefined ? roleStrToEnum(courseRoleStr) : undefined
    const isStaff = isMinimumRole(courseRole, Role.TA)
    const meetingRole = isStaff ? 'sl' : originalMeetingRole


    const meetingRoleDisplay = meetingRole === 'pilot' ? 'Pilot' : 'Co-Pilot'

    if(memberInfo === undefined || userDataLoading || originalMeetingRole === undefined) return <>Loading...</>

    return (
        <>
            <PeopleInRoom/>
            <div style={{margin: "auto"}}>Role: {meetingRoleDisplay}</div>
            &nbsp;
            {meetingType === 'peer' ? <PilotToggle uid={user.uid} roomId={roomId} memberInfo={memberInfo} meetingRole={meetingRole} courseId={courseId} /> : <></>}
            &nbsp;
            &nbsp;
            <Button
                className="btn btn-danger btn-sm mr-2"
                onClick={endSession}
            >
                <FaPhone style={{ transform: "rotate(225deg)" }} /> &nbsp;
                Leave session
            </Button>
            <PilotRequestNotification uid={user.uid} memberInfo={memberInfo} courseId={courseId} roomId={roomId}/>
        </>
    )
}