import React, { useContext } from "react";
import { CourseContext } from "contexts/CourseContext";

export const getCurrTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

export function formateDateWithSuffix(date) {
  if(date == null) {
    return ""
  }
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' });
  const dateStr = formatter.format(date);

  const pluralRules = new Intl.PluralRules('en-US', { type: 'ordinal' });
  const suffix = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  }[pluralRules.select(date.getDate())];

return dateStr.replace(/\d+/, date.getDate() + suffix);
}



export function getTimeInTimezone(timezoneString) {
  const [sign, hours, minutes] = timezoneString.match(/([-+])(\d+):(\d+)/).slice(1);
  const timezoneOffset = ((parseInt(hours, 10) * 60) + parseInt(minutes, 10)) * (sign === '-' ? -1 : 1);
  const currentTime = new Date();
  const utcTime = currentTime.getTime() + (currentTime.getTimezoneOffset() * 60000);
  const timezoneTime = utcTime + (timezoneOffset * 60000);
  const timezoneDate = new Date(timezoneTime);
  const hoursFormatted = timezoneDate.getHours() % 12 || 12;
  const minutesFormatted = timezoneDate.getMinutes().toString().padStart(2, '0');
  const amPm = timezoneDate.getHours() < 12 ? 'am' : 'pm';
  return `${hoursFormatted}:${minutesFormatted} ${amPm}`;
}


export function getTimeUntil(nextDate){
  if(nextDate == null) {
    return ""
  }
    const now = new Date();
    const timeUntil = Math.abs(nextDate.getTime() - now.getTime());
    let secondsUntil = Math.floor(timeUntil / 1000);
    let minutesUntil = Math.floor(secondsUntil / 60);
    let hoursUntil = Math.floor(minutesUntil / 60);
    let daysUntil = Math.floor(hoursUntil / 24);

    if(nextDate.getTime() < now.getTime()){
      secondsUntil *= -1
      minutesUntil *= -1
      hoursUntil *= -1
      daysUntil *= -1
    }
  
    const rtf = new Intl.RelativeTimeFormat(navigator.language, { style: 'long' });
  
    if (Math.abs(daysUntil) > 0) {
      return rtf.format(daysUntil, 'day');
    } else if (Math.abs(hoursUntil) > 0) {
      return rtf.format(hoursUntil, 'hour');
    } else if (Math.abs(minutesUntil) > 0) {
      return rtf.format(minutesUntil, 'minute');
    } else {
      return rtf.format(secondsUntil, 'second');
    }
  }
  


export const getFormattedString = function (dayName, hours, mins) {
  if (mins === 0) {
    // special case for midnight
    if (hours === 0) {
      return dayName + ", Midnight";
    }
    if (hours === 12) {
      return dayName + ", Noon";
    }
    // special case for noon
    var suffix = "am";
    if (hours > 12) {
      suffix = "pm";
      hours -= 12;
    }
    return dayName + ", " + hours + suffix;
  } else{
    // special case for noon
    let suffix = "am";
    if (hours === 0) {
      return dayName + ", " + `12:${mins}am (just after Midnight)`;
    } else if (hours === 12) {
      return dayName + ", " + `12:${mins}pm (just after Noon)`;
    } else if (hours >= 12) {
      suffix = "pm";
      hours -= 12;
    }
    return dayName + ", " + hours + `:${mins}` + suffix;
  }
};

export const getTimezoneName = function (timezoneString) {
  const parts = timezoneString.split(":");
  const hours = parseInt(parts[0][parts[0].length - 1] + parts[1].substring(0, 2));
  const minutes = parseInt(parts[1].substring(2));
  const utcOffset = hours * 60 + minutes;
  const timezoneName = Intl.DateTimeFormat(undefined, { timeZone: `Etc/GMT${parts[0]}` }).resolvedOptions().timeZone;
  const tz = timezoneName.replace(/[^A-Za-z\/_]/g, '');
  const city = tz.split('/')[1];
  return "Stanford"
  return city;
}

export const getSectionTimeStr = function (timeZoneStr, i) {
  const days = ["Sundays", "Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays"];
  // this is a Wed. sections start at 4pm on Wednesday
  const { getFirstDayOfSection } = useContext(CourseContext)
  const firstDay = getFirstDayOfSection(timeZoneStr, i)

  let dayName = days[firstDay.getDay()];
  let hours = firstDay.getHours();
  let mins = firstDay.getMinutes();

  const formattedString = getFormattedString(dayName, hours, mins);
  console.log(formattedString)
  return formattedString
};

export const getSectionTimeStrNoHook = function (timeZoneStr, i, getFirstDayOfSection) {
  const days = ["Sundays", "Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays"];
  // this is a Wed. sections start at 4pm on Wednesday
  const firstDay = getFirstDayOfSection(timeZoneStr, i)

  let dayName = days[firstDay.getDay()];
  let hours = firstDay.getHours();
  let mins = firstDay.getMinutes();

  return getFormattedString(dayName, hours, mins);
};


const parseUTCOffset = function (tzString) {
  var parsedString = tzString.slice(3);
  var multiplier = parsedString[0] === "+" ? 1 : -1;
  parsedString = parsedString.slice(1);
  var [hours, minutes] = parsedString.split(":");

  return {
    hours: multiplier * parseInt(hours),
    minutes: multiplier * parseInt(minutes),
  };
};

const removeOffset = function (date, offset) {
  date.setHours(date.getHours() - offset.hours);
  date.setMinutes(date.getMinutes() - offset.minutes);
  return date;
};

const addOffset = function (date, offset) {
  date.setHours(date.getHours() + offset.hours);
  date.setMinutes(date.getMinutes() + offset.minutes);
  return date;
};

const padTo2 = (number, pad, withSign) => {
  const absValue = "" + Math.abs(number);
  let paddedValue = pad ? absValue.padStart(2, "0") : absValue;
  if (withSign) {
    if (number < 0) {
      paddedValue = "-" + paddedValue;
    } else {
      paddedValue = "+" + paddedValue;
    }
  }
  return paddedValue;
};

export const getLocalUTCTimezone = function () {
  const offset = new Date().getTimezoneOffset();
  const numHours = ~~(offset / 60); // get floor
  const numMins = padTo2(offset % 60, true, false);
  const diffNum = numHours * -1;
  const paddedNum = padTo2(diffNum, false, true);
  return "UTC" + paddedNum + ":" + numMins;
};

export const shiftToTimezone = function (date, srcTzString, dstTzString) {
  removeOffset(date, parseUTCOffset(srcTzString));
  addOffset(date, parseUTCOffset(dstTzString));
  return date;
};

export const getLocalTime = function (timeZoneStr, dateTime) {
  // all times in this program should be CA time, UTC-7.
  return shiftToTimezone(dateTime, "UTC-7:00", timeZoneStr);
};

export const smallGroupTimeToDateTime = function (timeStr, day) {
  // return date of firt small group on this day and time
  const re = new RegExp("(\\d+):(\\d+)");
  const match = timeStr.match(re);
  let hours = parseInt(match[1]);
  let mins = parseInt(match[2]);

  let date;
  if (day === "Sunday") {
    date = 18;
  } else if (day === "Monday") {
    date = 19;
  }

  return new Date(2021, 3, date, hours, mins);
};

export const convertTimeToLocalTimezoneForSmallGroup = function (
  timeStr,
  day,
  destTz
) {
  // timeStr will look like 22:00, and will be in UTC-7:00

  const firstDate = smallGroupTimeToDateTime(timeStr, day);
  const shiftedDate = shiftToTimezone(firstDate, "UTC-7:00", destTz);

  const days = ["Sunday", "Monday", "Tuesday", "Wednesday"];

  const shiftedDay = days[shiftedDate.getDay()];

  return `${shiftedDay}s at ${padTo2(
    shiftedDate.getHours(),
    true,
    false
  )}:${padTo2(shiftedDate.getMinutes(), true, false)}`;
};
