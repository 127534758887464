import React, { useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import { ToastBox } from '../../components/toasts/ToastNotify'
import { setPilotStatus, setRequestStatus } from '../../firebase/realtime/Rooms';


// const notifications = [{'name': "Ali", 'courseId': "", 'roomId': "", 'newPilotUid': "", "oldPilotUid": ""}]

export const PilotRequestNotification = (props) => {
  const { memberInfo, uid, courseId, roomId } = props
  const { requesters, uidToDisplayName } = memberInfo
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ToastContainer style={{position: 'fixed', top: '50px', right: '0', zIndex: 5 }} >
        {requesters.filter(r => r !== uid).map((requesterUid) => (
              <ToastBox
              title={uidToDisplayName[requesterUid] + " is requesing to be the pilot"}
              key={requesterUid}
              successText="Give pilot"
              cancelText={null}
              onSuccess={() => {
                setPilotStatus(courseId, roomId, requesterUid, uid);
              }}
              onCancel={() => {
                setRequestStatus(courseId, roomId, requesterUid, false);
              }}
            />
        ))}
    </ToastContainer>
  );
}