import { FBReturn } from "./types";

export const ERROR_CODES = {
    QUEUE: {
        ALREADY_IN_QUEUE: "queue/already-in-queue",
        NOT_IN_QUEUE: "queue/not-in-queue",
        QUEUE_CLOSED: "queue/closed",
    },
    ROOMS: {
        INVALID_STATUS: "rooms/invalid-room-status",
        INVALID_ROOM: "rooms/invalid-room",
    },
    CHAT: {
        EMPTY_MESSAGE: "chat/empty-message",
    },
    GENERIC: "generic",
}

export const logError = async (error) => {
    console.error(error);
    // TODO: log these errors to firebase
}

export const safeFirebase = function(func)  {
    return async (...args) :  Promise<FBReturn> => {
        const ret = await func(...args);
        if(ret['success'] === false) {
            ret['errorSource'] = func.name;
            ret['args'] = args;
            await logError(ret);
        }
        return ret;
    }
}