import { useState, useEffect } from "react";
import AceEditor from 'react-ace';
import './styleFeedback.css';
import { useWindowSize } from "react-use-size";
import { FaFile, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaInfo } from "react-icons/fa";


const PANEL_OFFSET = 150
const RIGHT_SIDE_BOTTOM_OFFSET = 137


export const StyleFeedback = ({setCode, studentCode, responseData, handleThumbClick, hasStudentGivenFeedback}) => {
    
    const {identifierResponseJson, magicNumbersResponseJson, commentsResponseJson, decompResponseJson } = responseData

    return (
        <div className="container style-feedback-container">
            <div className="row" style={{height:'100vh'}}>
                <div className="col-12 col-md-6 pt-3" >
                    <div style={{
                        width:'100%'
                    }}>
                        <DisplayCode setCode={setCode} studentCode={studentCode}/>
                    </div>
                </div>
                <div className="col-12 col-md-6 pt-3">
                    <div className="" style={{  height:`100%`, overflow:'auto'}}>
                        <RightHandSide 
                            identifierResponseJson={identifierResponseJson} 
                            magicNumbersResponseJson={magicNumbersResponseJson}  
                            commentsResponseJson={commentsResponseJson}
                            decompResponseJson={decompResponseJson}
                            handleThumbClick={handleThumbClick}
                            hasStudentGivenFeedback={hasStudentGivenFeedback}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const RightHandSide = ({identifierResponseJson, magicNumbersResponseJson, commentsResponseJson, decompResponseJson, handleThumbClick, hasStudentGivenFeedback}) => {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < 768;
    const height = isMobile ? `100%` : `calc(100vh - ${RIGHT_SIDE_BOTTOM_OFFSET}px)`
    const styleData = {
        identifier:identifierResponseJson,
        magicNumbers:magicNumbersResponseJson,
        comments:commentsResponseJson,
        decomp:decompResponseJson
    }

    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div className="d-flex justify-content-between align-items-center">
                <div style={{fontSize: "24px"}}>Style Feedback</div>
                <Link to="/cip3/textbook/style" target="_blank"><FaFile></FaFile> Style Guide</Link>
            </div>
            <div style={{height: height, paddingRight:'15px', overflow:'auto'}}>
                {hasStudentGivenFeedback ? <InstructionsNoFeedback/> : <InstructionsWithFeedback handleThumbClick={handleThumbClick}/>}
                <StyleFeedbackContent styleData = {styleData}/>
            </div>
        </div>
    )
}

const FeedbackThumbs = ({handleThumbClick}) => {

    return (
        <div className="metaGrading-thumbButtonContainer">
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 100 }}
            overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                    Helpful feedback!
                </Tooltip>
            )}
        >
      <button
        className="metaGrading-thumbButton"
        onClick={() => handleThumbClick(1)}
      >
        <FaThumbsUp
          size="30px"
          title="Yes, the grading was correct"
          style={{color: "green"}}
        />
      </button>
      </OverlayTrigger>
      <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 100 }}
            overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                    Not helpful feedback
                </Tooltip>
            )}
        >
      <button
        className="metaGrading-thumbButton"
        onClick={() => handleThumbClick(0)}
      >
        <FaThumbsDown
          size="30px"
          title="No, the grading was not correct"
          style={{marginTop: "9px", color: "darkgrey"}}
        />
      </button>
      </OverlayTrigger>
    </div>
    )
}

const InstructionsWithFeedback = ({handleThumbClick}) => {
    return (
        <div className="d-flex">
            <div className="alert alert-primary d-flex">
                <div><b>Auto Style Feedback</b> Take this feedback as inspiration, but note that it was generated by a machine. Did you find this feedback helpful?</div>
                <div>
                <FeedbackThumbs handleThumbClick={handleThumbClick}/>
                </div>
            </div>
        </div>
    )
}
const InstructionsNoFeedback = () => {
    return (
        <div>
            <div className="alert alert-primary d-flex">
                <div><b>Auto Style Feedback</b> Take this feedback as inspiration, but note that it was generated by a machine.</div>
            </div>
        </div>
    )
}

const StyleFeedbackContent = ({styleData}) => {
    return (
        <>
            <IdentifierRightHandSide identifierResponseJson={styleData.identifier}/>
            <MagicNumbersRightHandSide magicNumbersResponseJson={styleData.magicNumbers}/>
            <CommentsRightHandSide commentsResponseJson={styleData.comments}/>
            <DecompRightHandSide decompResponseJson={styleData.decomp}/>
        </>
    )
}

const TooLongFunctions = ({tooLongFunctions}) => {
    if(tooLongFunctions === undefined || tooLongFunctions.length === 0) {
        return <></>
    }
    return (
        <>
        <p>We noticed some of these functions are a bit long. Consider decomposing them into multiple functions. Remember each function should have one clear task: </p>
        {tooLongFunctions.map((fnName, index) => {
            return <li key={index}><code>{fnName}</code></li>
        })}
        <br/>
        </>
    )
}

const RepeatedCode = ({repeatedCode}) => {
    if(repeatedCode === undefined || Object.keys(repeatedCode).length === 0) {
        return <></>
    }
    return (
        <>
        <p>We noticed some of these functions have repeated code. Consider defining a function to reduce repetition. </p>
        {Object.keys(repeatedCode).map((fnName, index) => {
            return <li key={index}><code>{fnName}</code></li>
        })}
        <br/>
        </>
    )
}

const DecompRightHandSide = ({decompResponseJson}) => {
    if(decompResponseJson === undefined) {
        return <></>
    }
    const tooLongFunctions = decompResponseJson["longFunctions"]
    const repeatedCode = decompResponseJson["repeatedCode"]
    if (tooLongFunctions === undefined && repeatedCode === undefined) {
        return <InvalidResponse styleType={"Decomposition"}/>
    }
    if (Object.keys(repeatedCode).length === 0 && tooLongFunctions.length === 0) {
        return (
            <NoRecommendations styleType={"Decomposition"} message={"We have no suggestions in this area. You can still check your own code for things we might have missed. Focus on making it readable."}/> 
        )
    }
    return (
        <div style={{paddingTop: "2vh"}}>
            <div className="card mx-auto">
                <div className="card-body" >
                <h5 className="card-title">Decomposition</h5>
                <TooLongFunctions tooLongFunctions={tooLongFunctions}/>
                <RepeatedCode repeatedCode={repeatedCode}/>
                </div>
            </div>
        </div>
    )
}

const WellDone = ({wellDoneFnFeedback}) => {
    if (wellDoneFnFeedback === undefined || wellDoneFnFeedback.length === 0) {
        return <></>
    }
    return (
        <>
        <p>{wellDoneFnFeedback}</p>
        </>
    )
}

const Suggestions = ({suggestions}) => {
    if (suggestions === undefined || suggestions.length === 0) {
        return <></>
    }
    return (
        <>
        {
            suggestions.map((suggestion, index) => {
                return <p key={index}>{suggestion}</p>
            })
        }
        </>
    )
}


const CommentsRightHandSide = ({commentsResponseJson}) => {
    if (commentsResponseJson === undefined) {
        return <></>
    }
    if (commentsResponseJson === 'invalid') {
        return <InvalidResponse styleType={'Comments'}/>
    }
    const wellDoneFnFeedback = commentsResponseJson["well_done"]
    const suggestions = commentsResponseJson["suggestions"]
    return (
        <div style={{paddingTop: "2vh"}}>
            <div className="card mx-auto">
                <div className="card-body" >
                <h5 className="card-title">Comments</h5>
                <WellDone wellDoneFnFeedback={wellDoneFnFeedback}/>
                <Suggestions suggestions={suggestions}/>
                </div>
            </div>
        </div>
    )
}


const MagicNumbersRightHandSide = ({magicNumbersResponseJson}) => {
   
    if(magicNumbersResponseJson === undefined) {
        return <></>
    }
    if(Object.keys(magicNumbersResponseJson).length === 0) {
        return <></>
    }
    const definedConstantDidntUse = magicNumbersResponseJson["definedConstantDidntUse"]
    const magicNumbersNeedConstants = magicNumbersResponseJson["magicNumbersNeedConstants"]
    const constantsUsedAsVariables = magicNumbersResponseJson["constantsUsedAsVariables"]
    const needToBeDefinedUpperCase = magicNumbersResponseJson["needToBeDefinedUpperCase"]
    
    const noFeedbackToGive = definedConstantDidntUse.length === 0 && magicNumbersNeedConstants.length === 0 && constantsUsedAsVariables.length === 0 && needToBeDefinedUpperCase.length === 0 

    if(noFeedbackToGive) {
        return <NoRecommendations styleType={"Constants and Magic Numbers"} message={"We have no suggestions in this area. Great work defining and using constants and magic numbers!"}/>
    }

    return (
        <div style={{paddingTop: "2vh"}}>
            <div className="card mx-auto">
                <div className="card-body" >
                    <MagicNumbersFeedback needToBeDefinedUpperCase={needToBeDefinedUpperCase} constantsUsedAsVariables={constantsUsedAsVariables} definedConstantDidntUse={definedConstantDidntUse} magicNumbersNeedConstants={magicNumbersNeedConstants}/>          
                </div>
            </div>
        </div>
    )


}

const MagicNumbersFeedback = ({ needToBeDefinedUpperCase,constantsUsedAsVariables, definedConstantDidntUse, magicNumbersNeedConstants }) => {
    const needToBeDefinedUpperCaseMessage = "We noticed these variable stay constant throughout the program. Try defining them as constants in all uppercase at the top of the file."
    const constantsUsedAsVariablesMessage = "We noticed you defined a constant that does not stay constant throughout the program. Try defining it within the function as a variable instead."
    const definedConstantDidntUseMessage = "We noticed you might have defined some constants and forgotten to use them in all cases in the code."

    let magicNumbersNeedConstantsMessage = "We noticed there is the following magic number in the code. Consider defining a constant intead."
    if (magicNumbersNeedConstants.length > 1) {
        magicNumbersNeedConstantsMessage = "We noticed there are the following magic numbers in the code. Consider defining constants for each of these intead."
    }
    return (
        <>
            <h5 className="card-title">Constants and Magic Numbers</h5>
            <MagicNumbersListMapping listToMap={needToBeDefinedUpperCase} message={needToBeDefinedUpperCaseMessage}/>
            <MagicNumbersListMapping listToMap={constantsUsedAsVariables} message={constantsUsedAsVariablesMessage}/>
            <MagicNumbersListMapping listToMap={definedConstantDidntUse} message={definedConstantDidntUseMessage}/>
            <MagicNumbersListMapping listToMap={magicNumbersNeedConstants} message={magicNumbersNeedConstantsMessage}/>
        </>
    )

}

const MagicNumbersListMapping = ({listToMap, message}) => {
    if (listToMap.length === 0) {
        return <></>
    }
    return (
        <>
        <div>{message}</div>
        {listToMap.map((line, index) => {
            return <div key={index}><code>{line}</code></div>
        })
        }
        <br/>
        </>

    )
}


const IdentifierRightHandSide = ({identifierResponseJson}) => {    
    if (identifierResponseJson === undefined) {
        return <></>
    }
    if (identifierResponseJson === 'invalid') {
        return <InvalidResponse styleType={"Function and Variable Names"}/>
    }
    if (Object.keys(identifierResponseJson).length === 0) {
        return <NoRecommendations styleType={'Function and Variable Names'} message={"Great work choosing variable and function names! Keep up the good work."}/>
    }
    return (
        <div>
            <div className="card mx-auto">
                <div className="card-body" >
                    <h5 className="card-title">Function and Variable Names</h5>
                    <div className="card-text">{"Here are some options for alternative function and variable names: "}</div>
                    <IdentifierFormattedFeedback responseJson={identifierResponseJson}/>
                </div>
            </div>
        </div>

    )
}

const NoRecommendations = ({styleType, message}) => {
    return (
        <div style={{paddingTop: "2vh"}}>
            <div className="card mx-auto">
                <div className="card-body" >
                    <h5 className="card-title">{styleType}</h5>
                    <div className="card-text">{message}</div>
                </div>
            </div>
        </div>
    )
}

const InvalidResponse = ({styleType}) => {
    return (
        <div style={{paddingTop: "2vh"}}>
            <div className="card mx-auto">
                <div className="card-body" >
                    <h5 className="card-title">{styleType}</h5>
                    <div className="card-text">{"Sorry we were unable to get you style feedback in this area. Please try again."}</div>
                </div>
            </div>
        </div>
    )
}

const IdentifierFormattedFeedback = ({responseJson}) => {
    return (
        <>
        {Object.keys(responseJson).map((identifierName, index) => {
            return <IdentifierRecommendation 
                    key={index} 
                    identifierName={identifierName} 
                    indentifierInfo={responseJson[identifierName]}/>
        })}
        </>
    )
}

const AlternativeName = ({name, index, names}) => {
    if (index === names.length - 1) {
        return <code key={index}>{name}</code>;
    } else {
        return <code key={index}>{name}, </code>;
    }
}

const IdentifierRecommendation = ({identifierName, indentifierInfo}) => {
    const alternativeNames = indentifierInfo["alternative_names"]
    const why = indentifierInfo["why"]
    return (
        <div>
            <br/>
            <h5><code>{identifierName}</code></h5>
            <span><u>Alternative Names:</u> </span>
            <>
            {
                alternativeNames.map((name, index) => {
                    return <AlternativeName key={index} name={name} index={index} names={alternativeNames}/>
                })
            }
            </>
            <br/>
            <span><u>Why:</u> {why}</span>
            <br/>
        </div>
    )
}

const CodeEditorEditable = (props) => {
    const { setCode, studentCode } = props
    const [showCode, setShowCode] = useState(studentCode)

    useEffect(() => {
        setCode(showCode)
    }, [showCode])

    return (
        <div className="card" style={{paddingTop: "2vh"}}>
                <AceEditor
                    mode="python"
                    name="StyleFeedbackEditor"
                    fontSize="12px"
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    onChange={(e) => setShowCode(e)}
                    value={showCode}
                    width={"100%"}
                    height={`calc(100vh - ${PANEL_OFFSET}px)`}
                    maxLines={0}
                    setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    readOnly: true,
                    tabSize: 2}}
                />
        </div>
    )
}


const DisplayCode = (props) => {
    const {setCode} = props
    return (
        <div >
            <h4>Your Code</h4>
            <CodeEditorEditable {...props}/>
        </div>
    )
}