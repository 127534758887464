import firebase from "firebase/compat/app";
import { database } from "firebaseApp.js";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Button } from "react-bootstrap"
import { FaCircle } from "react-icons/fa"
import { useContext } from "react";
import { useParams } from "react-router";
import { setPilotStatus, setRequestStatus, useRole} from "../../firebase/realtime/Rooms";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import "../../styles/PilotButton.css"
import { isMinimumRole } from "contexts/ProfileUtil";
import { ProfileContext, Role } from "contexts/ProfileContext";

export const PilotToggle = (props) => {
    const { uid, roomId, memberInfo, meetingRole, courseId } = props // TODO make these real props
    // const uid = "CUu6RgOlUkbPvVXYKC61KUZZb6x2"
    // const roomId = "0d74e89f-8607-4169-93c5-857be9be581c"  
    
    // If you are joining a session as an admin, you do not have the ability to request to be pilot
    if (meetingRole == 'sl') {
        return <></>
    }

    if(memberInfo === undefined || meetingRole === undefined) return <>Loading...</>

    const { members, requesters, displayNames, uidToDisplayName } = memberInfo

    // const pilotInfo = { members, requesterId, courseId, roomId, uid}
    // const copilotInfo = { requesterId, courseId, roomId, uid}
    const pilotInfo = { members, requesters, uidToDisplayName, courseId, roomId, uid}
    const copilotInfo = { requesters, courseId, roomId, uid}

    
    return (
        <>
        {meetingRole === 'pilot' ? 
        <div>
        <PilotView info={pilotInfo}/>
        </div>
        :
        <CoPilotView info={copilotInfo}/>
        }
        </>
    )
}

const PilotView = (props) => {
    // get all members in the room 
    const { members, requesters, uidToDisplayName, courseId, roomId, uid } = props.info
    console.log("~~~~~~~~~~~~~~~~~~>>>>>>>>>", uidToDisplayName)

    // in below u is the uid of all members in the room
    return (
    <DropdownButton id={'dropdown button'} title="Change Pilot">
        {Object.keys(uidToDisplayName).map((u) => {
            return u === uid ? <></> : <Dropdown.Item onClick={() => setPilotStatus(courseId, roomId, u, uid)}>{requesters.includes(u)? <FaCircle/> : ""}{uidToDisplayName[u]}</Dropdown.Item>
        })}
    </DropdownButton>
    )
}

const CoPilotView = (props) => {
    const { requesters, courseId, roomId, uid } = props.info
    return (
        <div>
        {requesters.includes(uid) ?
        <Button variant="warning" onClick={() => setRequestStatus(courseId, roomId, uid, false)}>Unrequest Pilot</Button>
        :
        <Button onClick={() => setRequestStatus(courseId, roomId, uid, true)}>Request Pilot</Button>
        }
        </div>
    )
}
