import {  useContext } from "react";
import { IDEContext } from "../contexts/IDEContext";
import { Editor } from "@monaco-editor/react";

export const ResizableCodeInput = ({ onChange, isReadOnly, code="", isOffline=false }) => {
const { editorRef, editorLoaded, setEditorLoaded, editorFontSize } = useContext(IDEContext);



  const onMount = (editor) => {
    editorRef.current = editor;
    setEditorLoaded(!editorLoaded);
  }

  if(isOffline) {
    <div  className="h-100 w-100 d-flex overflow-hidden">
      <Editor
      height="100%"
      defaultLanguage="python"
      onChange={onChange}
      onMount={onMount}
      options={{
        fontSize: editorFontSize,
        minimap: { enabled: false },
        readOnly: isReadOnly,
      }}
      width={"100%"}
      value={code}
    /> 
  </div>
  }

  return <div  className="h-100 w-100 d-flex overflow-hidden">
      <Editor
      height="100%"
      defaultLanguage="python"
      onChange={onChange}
      onMount={onMount}
      options={{
        fontSize: editorFontSize,
        minimap: { enabled: false },
        readOnly: isReadOnly,
      }}
      width={"100%"}
    /> 
  </div>
}