import { useContext, useEffect, useRef } from "react"
import { CollabContext } from "../CollabContext"
import { IDEContext } from "ide/contexts/IDEContext"
import { useMonaco } from "@monaco-editor/react"
import "../../../components/duolessons/lessontypes/codeExamples/Monaco.css";




export const useMonacoBinding = (currFileId, firebaseDocPath, serverData, onUpdate, isFallback) => {
    const { sessionRef, isSessionRefLoaded } = useContext(CollabContext)
    const { editorRef, editorLoaded, stepMode, lineNo, isRunning, initSetCode, errorLineNo, codeToRun  } = useContext(IDEContext)
    const monaco = useMonaco()   
    const decorationRef = useRef([])



    // set up monaco binding when the editor is loaded
    useEffect(() => {
        if(!isSessionRefLoaded || !monaco || !currFileId || !editorRef.current) return
        let model = editorRef.current.getModel()
        if(!model) {
            return;
        }

        sessionRef.current.setMonacoBinding(currFileId, model, new Set([editorRef.current]), serverData, firebaseDocPath, isFallback)
        initSetCode((code) => {
            model.setValue(code)
        })
        return () => {
            sessionRef.current.destroyBinding()
        }

    }, [isSessionRefLoaded, editorLoaded, currFileId, monaco, isFallback])


    // Handle decorating editor when user is stepping through code
    useEffect(() => {
        console.log("lineNo", lineNo, stepMode)
        if (monaco && lineNo > 0) {
            const editors = monaco.editor.getEditors()
            setMarkers(editors, lineNo)
        }
    }, [lineNo, stepMode]);

    useEffect(() => {
        if(isRunning) return;
        if (monaco && errorLineNo > 0) {
            const editors = monaco.editor.getEditors()
            setMarkers(editors, errorLineNo, "line-highlight-error")
        }
      }, [errorLineNo, isRunning]);

    // Remove decorations when user runs or exits step mode
    useEffect(() => {
        if(monaco) {
            clearMarkers(monaco.editor.getEditors())
        }
    }, [stepMode, codeToRun.current])

    useEffect(() => {
        if(monaco && isRunning) {
            clearMarkers(monaco.editor.getEditors())
        }
    }, [isRunning])


    const setMarkers = (editors, markedLineNo, className="line-highlight-step") => {
        console.log("setMarkers", editors, markedLineNo)
        if(!editors || editors.length < 1) { return }
        const editor = editors[0]
        editor.deltaDecorations(decorationRef.current, [])
        decorationRef.current = []

        const newDecorations = [
            {
                range: new monaco.Range(markedLineNo, 1, markedLineNo, 1),
                options: { isWholeLine: true, className: className }
            }
        ]
        const decs = editor.deltaDecorations([], newDecorations)
        decorationRef.current = decs
    }

    const clearMarkers = (editors) => {
        if(!editors || editors.length < 1) { return }
        const editor = editors[0]
        editor.deltaDecorations(decorationRef.current, [])
        decorationRef.current = []
    }

}



