import { createContext, useEffect, useState } from 'react';
import { doc, getDoc, getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { useParams } from 'react-router';
import { shiftToTimezone } from "components/timezones/timezoneHelper";



const defaultData = {
    courseId: '',
    courseName: '',
    courseDescription: '',
    courseTimezone: '',
    courseStartDate: '',
    courseEndDate: '',
    nCourseWeeks: 0,
    firstSectionTimestamp:'',
    courseType: '',
    courseFeatures: [],
    courseVideoId: '',
    sectionTimeDelta: 0,
    slFeatures: [],
    isCourseAsynchronous: false,
    isFeatureEnabled: (featureName: string, isSl?: boolean) => false,
    getSectionsSnapshot: () => { return null },
    getFirstDayOfSection: (timezoneString: string, sectionIndex: number) => { return null },
    getNextSectionIndex: (timezoneString: string, sectionIndex: number) => { return 0 },
    getNextSectionDate: (timezoneString: string, sectionIndex: number) => { return null },
    rosterTypesenseIndex: '',
    storiesTypesenseIndex: '',
    roadmapList: []
  };


export const CourseContext = createContext(defaultData);

interface CourseContextType {
    courseId: string;
    courseName: string;
    courseDescription: string;
    courseTimezone: string;
    courseStartDate: string;
    courseEndDate: string;
    nCourseWeeks: number;
    firstSectionTimestamp: string;
    courseType: string,
    sectionTimeDelta: number,
    courseVideoId: string,
    isCourseAsynchronous: boolean,
    isFeatureEnabled: (featureName: string, isSl?: boolean) => boolean,
    getSectionsSnapshot: () => any,
    getFirstDayOfSection: (timezoneString: string, sectionIndex: number) => Date,
    getNextSectionIndex: (timezoneString: string, sectionIndex: number) => number,
    getNextSectionDate: (timezoneString: string, sectionIndex: number) => Date,
    courseFeatures: string[],
    slFeatures: string[],
    rosterTypesenseIndex: string,
    storiesTypesenseIndex: string,
    roadmapList: string[]
}
  
  interface CourseContextProviderProps {
    children: React.ReactNode;
    }

  




export const CourseProvider: React.FC<CourseContextProviderProps> = ({ children }) => {
    const  { courseId } = useParams()
    const [courseName, setCourseName] = useState<string>('');
    const [courseDescription, setCourseDescription] = useState<string>('');
    const [courseTimezone, setCourseTimezone] = useState<string>('');
    const [courseStartDate, setCourseStartDate] = useState<string>('');
    const [courseEndDate, setCourseEndDate] = useState<string>('');
    const [nCourseWeeks, setnCourseWeeks] = useState<number>(0);
    const [firstSectionTimestamp, setFirstSectionTimeStamp] = useState<string>('');
    const [courseType, setCourseType] = useState<string>('');
    const [isCourseAsynchronous, setIsCourseAsynchronous] = useState<boolean>(false);
    const [courseFeatures, setCourseFeatures] = useState<string[]>([])
    const [slFeatures, setSlFeatures] = useState<string[]>([])
    const [courseVideoId, setCourseVideoId] = useState<string>('');
    const [sectionTimeDelta, setSectionTimeDelta] = useState<number>(0);
    const [rosterTypesenseIndex, setRosterTypesenseIndex] = useState<string>('');
    const [storiesTypesenseIndex, setStoriesTypesenseIndex] = useState<string>('');
    const [roadmapList, setRoadmapList] = useState<string[]>(["student", "sl"])



    // make sure its a real course
    
    const db = getFirestore()
    const courseDocRef = doc(db, `course/${courseId}`);

    const getFirstDayOfSection = (timezoneString,sectionIndex) => {
        // NOTE: when calling this function, make sure to check if firstSectionTimestamp is loaded or use it in a useEffect
        if (!firstSectionTimestamp) {
            throw new Error('Timestamp not loaded yet');
        }
        const firstSectionDate = new Date(firstSectionTimestamp);
        firstSectionDate.setHours(firstSectionDate.getHours() + sectionIndex)
        return shiftToTimezone(firstSectionDate, "UTC-7:00", timezoneString);
    }

    function getNextSectionIndex(timezoneString, sectionIndex) {
        // NOTE: when calling this function, make sure to check if firstSectionTimestamp is loaded or use it in a useEffect
        const firstSection = getFirstDayOfSection(timezoneString, sectionIndex);
        let currSection = firstSection;
        const oneHourInMilliseconds = 60 * 60 * 1000;
        let weekIndex = 0
        while (new Date() >= new Date(currSection.getTime() + oneHourInMilliseconds)) {
            currSection = new Date(currSection.getTime() + 7 * 24 * 60 * 60 * 1000);
            weekIndex += 1
        }
        return weekIndex;
    }

    function getNextSectionDate(timezoneString, sectionIndex) {
        // NOTE: when calling this function, make sure to check if firstSectionTimestamp is loaded or use it in a useEffect
        const firstSection = getFirstDayOfSection(timezoneString, sectionIndex);
        let currSection = firstSection;
        const oneHourInMilliseconds = 60 * 60 * 1000;
        while (new Date() >= new Date(currSection.getTime() + oneHourInMilliseconds)) {
            currSection = new Date(currSection.getTime() + 7 * 24 * 60 * 60 * 1000);
        }
        return currSection;
    }

    useEffect(() => {
        const getCourseData = async () => {
            if(!courseId) return;
            const courseDoc = await getDoc(courseDocRef);
            if (courseDoc.exists()) {
                const courseData = courseDoc.data();
                setCourseName(courseData.name ?? "");
                setCourseDescription(courseData.description ?? "");
                setCourseTimezone(courseData.timezone ?? "");
                setCourseStartDate(courseData.startDate ?? "");
                setnCourseWeeks(courseData.nWeeks?? 0);
                setFirstSectionTimeStamp(courseData.firstSectionTimestamp ?? "");
                setCourseEndDate(courseData.endDate ?? "");
                setCourseType(courseData.type ?? "");
                setCourseVideoId(courseData.videoId ?? "");
                setCourseFeatures(courseData.features ?? []);
                setSlFeatures(courseData.slFeatures ?? []);
                setSectionTimeDelta(courseData.sectionTimeDelta ?? 0);
                setRosterTypesenseIndex(courseData.rosterTypesenseIndex ?? '');
                setStoriesTypesenseIndex(courseData.storiesTypesenseIndex ?? '');
                const verboseRoadmapList = ["student", "sl", ...courseData.roadmapList ?? ["student", "sl"]]
                const uniqueRoadmap = array => array.filter((v,i) => array.indexOf(v) === i)
                setRoadmapList(uniqueRoadmap(verboseRoadmapList))


                setIsCourseAsynchronous(courseData.type ? courseData.type === "asynchronous" : false)
            }
        }
        getCourseData();
    }, [courseId])


    const isFeatureEnabled = (featureName: string, isSl: boolean=false) => {
        const features = isSl ? slFeatures : courseFeatures
        return features.includes(featureName)
    }

    const getSectionsSnapshot = async () => {
        // fetches sections for this course
        const sectionRef = collection(db, "sections")
        const q = query(sectionRef, where("courseId", "==", courseId));
        const querySnapshot = await getDocs(q);
        return querySnapshot
    }


    return (
        <CourseContext.Provider value={{ 
            courseId, 
            courseName, 
            courseDescription, 
            courseTimezone, 
            courseStartDate, 
            courseEndDate, 
            nCourseWeeks,
            firstSectionTimestamp,
            courseType, 
            courseFeatures,
            slFeatures,
            courseVideoId,
            isCourseAsynchronous,
            isFeatureEnabled,
            getSectionsSnapshot,
            sectionTimeDelta,
            getFirstDayOfSection,
            getNextSectionDate,
            getNextSectionIndex,
            rosterTypesenseIndex,
            storiesTypesenseIndex,
            roadmapList
        }}>
            {children}
        </CourseContext.Provider>
    );

}