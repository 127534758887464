import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import {
  faAppleAlt,
  faUserPlus,
  faGraduationCap,
  faUserFriends,
  faArrowCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import LandingContent from "./LandingContent";
import { Footer } from "landing/components/Footer";
import { GoogleAuthProvider, getAuth, signInWithRedirect } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getApp } from "firebase/app";
import { LinkContainer } from "react-router-bootstrap";
import { useUserId } from "hooks/user/useUserId";
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { doc, getFirestore } from "firebase/firestore";
import { isMinimumRole } from "contexts/ProfileUtil";
import { Role, getCourseRoleNumber } from "contexts/ProfileContext";
import { useHistoryNavigate } from "hooks/router/useHistoryNavigate";

const ABOUT_BTN = "About Code in Place";
const JOIN_TEACHER_BTN = "Apply to Teach";
const JOIN_STUDENT_BTN = "Student App";

export const Landing = ({ }) => {
  document.title = "Code in Place";
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return <>

    {width < 850 ? <MobileLanding /> : <DesktopLanding />}
    {/* <SignInSignOut /> */}
  </>
};

const MobileLanding = ({ }) => {
  const contentRef = useRef(null);
  const joinRef = useRef(null);
  return (
    <div>
      <div className="splashBackground" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/splash4.jpg')` }}>
        <div className="d-flex flex-column justify-content-center">
          <div className="website-subtitle-mobile">
            <h1 className="card-title website-title-mobile">Code in Place</h1>
            <h5>
              A free, human-centric, intro-to-coding course from Stanford University

            </h5>
            <MainButton />
          </div>
          {/* <div className="d-flex justify-content-center">
                    <Button variant="primary" as={Link} to="/course" className="ml-1 mr-1">
                    <FontAwesomeIcon icon={faUserFriends} />  Student Login
                      </Button>
                      <Button variant="primary"  as={Link} to="/teach/portal" className="ml-1 mr-1">
                      <FontAwesomeIcon icon={faGraduationCap} /> Section Leader Login
                      </Button>
                      </div> */}
        </div>
        <div className="d-flex justify-content-center mt-3 flex-wrap">
          {/* <Button variant="secondary" as={Link} to="/cip3">
            Enter Course
          </Button> */}
          <Button className="ml-1" onClick={() => contentRef.current.scrollIntoView({ behavior: "smooth" })}>
            <FontAwesomeIcon icon={faArrowCircleDown} /> {ABOUT_BTN}
          </Button>
          {/* <Button as={Link} to="/cip3/join" className="ml-1 mt-1">
                        <FontAwesomeIcon icon={faUserPlus} /> {JOIN_STUDENT_BTN}
                      </Button> */}
          <Button as={Link} to="/public/applyteach/cip4" className="ml-1 mt-1">
            <FontAwesomeIcon icon={faGraduationCap} /> SL App
          </Button>

          {/* <Button onClick={() => joinRef.current.scrollIntoView()}>
            <FontAwesomeIcon icon={faArrowCircleDown} /> Apply
          </Button> */}
        </div>
      </div>

      <LandingContent contentRef={contentRef} joinRef={joinRef} />
      <Footer />
    </div>
  );
};

const DesktopLanding = ({ }) => {
  const contentRef = useRef(null);
  const joinRef = useRef(null);
  return (
    <div className="">
      <div className="splashBackground" style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/splash3.jpeg')` }}>
        <div className="container landing-container">
          <div className="row w-100">
            <div className="col">
              <div className="d-flex justify-content-center">
                <div className="card" style={{ backgroundColor: '#ffffffee', borderRadius: '1rem' }}>
                  <div className="card-body">
                    <h1 className="card-title website-title">Code in Place</h1>

                    <h5 className="card-subtitle mb-2 text-muted text-centered">
                      A free, human-centered, intro-to-coding course from Stanford University<br />
                      Access the material anytime. Next class with teachers: April 22nd - May 31st
                    </h5>

                    <div className="d-flex justify-content-center">

                      <MainButton />
                    </div>

                    <hr />

                    {/*  */}



                    <p className="card-text">
                      {INTRO_BLURB}
                    </p>

                    <p className="card-text">
                      {CIP_BLURB}
                    </p>

                    <div className="d-flex justify-content-center">
                      {/* <Button variant="secondary" as={Link} to="/cip3">
            Enter Course
          </Button> */}
                      <Button
                        className="ml-1"
                        onClick={() => contentRef.current.scrollIntoView({ behavior: "smooth" })}
                      >
                        <FontAwesomeIcon icon={faArrowCircleDown} /> {ABOUT_BTN}
                      </Button>
                      {/* <Button as={Link} to="/cip3/join" className="ml-1">
                        <FontAwesomeIcon icon={faUserPlus} /> {JOIN_STUDENT_BTN}
                      </Button> */}
                      <Button as={Link} to="/public/applyteach/cip4" className="ml-1">
                        <FontAwesomeIcon icon={faGraduationCap} /> {JOIN_TEACHER_BTN}
                      </Button>



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav></nav>
      <div className="d-flex flex-column align-items-center">
        <LandingContent contentRef={contentRef} joinRef={joinRef} />
      </div>
      <Footer />
    </div>
  );
};

// const LandingLogOut = ({}) => {
//   // we are not assured that the user is logged in here,
//   // but if they are, render a log out button
//   const auth = getAuth();
//   const [user] = useAuthState(auth)
//   if(!user) {
//     // sign in button
//     return <button style={logOutButtonStyle} className="btn btn-danger" onClick={() => auth.signOut(auth)}>Sign In</button>
//   }
//   else {
//     return <button style={logOutButtonStyle} className="btn btn-danger" onClick={() => auth.signOut(auth)}>Sign Out</button>
//   }
// }

const EnterCourseButton = () => {
  const auth = getAuth(getApp());
  const [user] = useAuthState(auth)
  if (!user) {
    return <></>
  }
  return <EnterCourseButtonView />
}

const EnterCourseButtonView = () => {
  // assume you are logged in
  const userId = useUserId()
  const courseId = 'cip3'
  const db = getFirestore(getApp())
  const roleRef = doc(db, 'users', userId, 'roles', courseId)
  const [roleData, roleLoading, roleError] = useDocumentData(roleRef)
  if (roleLoading) {
    return <></>
  }

  const roleNumber = getCourseRoleNumber(roleData?.role)


  if (!roleNumber || !isMinimumRole(roleNumber, Role.STUDENT)) {
    console.log('not minimum role', roleNumber)
    return <></>
  }

  // I want this centered
  return <div >
    <LinkContainer to="/cip3"><Button variant="primary" size="lg">Enter CIP3</Button></LinkContainer></div>
}

const MainButton = () => {
  const navigate = useHistoryNavigate();
  return <>
    <Button
      className="btn ml-1"
      size="lg"
      onClick={()=>navigate('/welcome')}
    >Learn to Code</Button>
    {/* <EnterCourseButton />
    <Button
  className="btn ml-1"
  size="lg"
  href="https://docs.google.com/forms/d/e/1FAIpQLScri0GUAf65THYVaViXr1-eZS7jYbD_LblskM30rKnozz-vIw/viewform?usp=sf_link"
>Interest Form</Button> */}
  </>
}

const SignInSignOut = () => {
  const navigate = useNavigate();
  const auth = getAuth(getApp());
  const [user, loading] = useAuthState(auth);
  if (loading) {
    return <>Loading...</>
  }
  if (!user) {
    return <button style={logOutButtonStyle} className="btn btn-light" onClick={() => navigate(`/public`)}>Sign In</button>
  }
  return <button style={logOutButtonStyle} className="btn btn-light" onClick={() => auth.signOut()}>Sign Out</button>
};

function loginWithRedirect() {
  const auth = getAuth(getApp());
  signInWithRedirect(auth, new GoogleAuthProvider());
}

const INTRO_BLURB = <></>


// const INTRO_BLURB = <>Code in Place 2023 was a joyful time! Join us for the
// next offering as either a student or as a
// section leader. If you'd like to be contacted when Code in Place 2024 begins recruiting, fill out <a href="https://docs.google.com/forms/d/e/1FAIpQLScri0GUAf65THYVaViXr1-eZS7jYbD_LblskM30rKnozz-vIw/viewform?usp=sf_link">this form!</a></>

const CIP_BLURB = <>
  Our goal is to offer the highest quality free coding course to many students from around the world. The secret? Thousands of passionate teachers, decades of experience making coding joyful at Stanford, our uplifting community of learners, and a small touch of AI, when helpful. Learn (or teach) the first half of Stanford's flagship intro to python course, CS106A. Good times!</>



const logOutButtonStyle = {
  position: "absolute",
  top: "1rem",
  right: "1rem",
  zIndex: 1000
}