import { collection, doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection, useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { fetchAllAssignmentMetaData, fetchAssignments } from "../../utils/general";
import { getAuth } from "firebase/auth";

/**
 * Assignments are reactive to the firebase, so if you change
 * the value there, everyones assignment list will update!
 */

export type AssnMetadataType = {
    title: string,
    type: string,
}

const defaultData = {
    assignments: undefined,
    assnMetaData:undefined,
    assnLoaded:false,
    assnMap:undefined,

    creativeProjects:undefined,
    creativeMetaData:{},
    creativeLoaded:false,

    groupProjects: undefined,
    groupLoaded: false,

    completedAssignments:{},
    deleteCreativeProject: (deletedProjectId) => {},
};

export const AssnContext = React.createContext(defaultData);

export const AssnProvider = ({courseId, children }) => {

  // Note: you do *not* currently have project meta data for assignments here
  // it is just links. You can get the meta data for the project by
  // loading each project id

  
  const db = getFirestore()

  const auth = getAuth();
  const user = auth.currentUser;

  // these are the refs we read (to start)
  const assnRef = doc(db, `assns/${courseId}`);
  const assnCollectionRef = collection(db, `assns/${courseId}/assignments`);
  const creativeRef = doc(db,`/users/${user.uid}/${courseId}/creativeProjects`)
  const groupRef = doc(db,`/users/${user.uid}/${courseId}/peerProjects`)
  const completedRef = doc(db, `/users/${user.uid}/${courseId}/assnProgress`)
  const assnMapRef = doc(db, `/users/${user.uid}/${courseId}/assnMap`)
  

  // all of this is state on the assignments (and the meta data)
  const [assignmentDoc, assnDataIsLoading] = useDocumentData(assnRef)
  const [assignments, setAssignments] = useState(undefined)
  const [assnMetaDataCollection, collectionDataLoading] = useCollection(assnCollectionRef)
  const [assnMetaData, setAssnMetaData] = useState(undefined)
  const [assnLoaded, setAssnLoaded] = useState(false)

  // see which projects have been completed
  const [completedAssignments, completedDataIsLoading, completeError] = useDocumentData(completedRef)
  
  // all of this is state on the creative projects (and the meta data)
  const [creativeDoc, creativeDocLoading, creativeDocError] = useDocumentData(creativeRef)
  const [creativeProjects, setCreativeProjects] = useState([])
  const [creativeMetaData, setCreativeMetaData] = useState({})
  const [creativeLoaded, setCreativeLoaded] = useState(false)

  const [assnMapDoc, assnMapLoading, assnMapError] = useDocumentData(assnMapRef)

  const [groupDoc, groupDocLoading, groupDocError] = useDocumentData(groupRef)
  const [groupProjects, setGroupProjects] = useState([])
  const [groupLoaded, setGroupLoaded] = useState(false)

  // helper function to get info for a project
  const fetchCreativeProjectInfo = async (projectId) => {
    const db = getFirestore();
    const docRef = doc(db, `projects/${projectId}`);
    const projMetaData = (await getDoc(docRef)).data();
    setCreativeMetaData((prev) => {
      const newValue = {...prev}
      newValue[projectId] = projMetaData
      return newValue
    })
  };


  const deleteCreativeProject = async (projectId) => {
    const db = getFirestore();
    const docRef = doc(db, `/users/${user.uid}/${courseId}/creativeProjects`);
    const newCreativeProjects = creativeProjects.filter((id) => id !== projectId)
    await updateDoc(docRef, {creativeProjects:newCreativeProjects, deletedProjects:firebase.firestore.FieldValue.arrayUnion(projectId)})
    setCreativeProjects(newCreativeProjects)
  }


  // process a change to the assignment doc
  useEffect(()=> {
    // only run this when loading is done
    if(assnDataIsLoading) return

    if(assignmentDoc && assignmentDoc.assns) {
      setAssignments(assignmentDoc.assns)
    } else {
      setAssignments([])
    }
  }, [assnDataIsLoading])

  // process a change to the group projects 
  useEffect(()=> {
    if(groupDoc) {
      // convert groupDoc to a dictionry
      // @ts-ignore
      setGroupProjects(groupDoc)
      setGroupLoaded(true)
    } 
  }, [groupDoc])

  // when you get the list of creative project ids...
  useEffect(() => {
    if(creativeDoc) {
      const projs = creativeDoc.creativeProjects
      if(!projs) {
        setCreativeLoaded(true)
      } else {
        setCreativeProjects(projs)
        for(const projId of projs){
          fetchCreativeProjectInfo(projId)
        }
      }
    }
    if(!creativeDoc && !creativeDocLoading) {
      setCreativeLoaded(true)
    }
  }, [creativeDoc, creativeDocLoading])

  // process a change to meta data
  useEffect(() => {
    if(assnMetaDataCollection) {
      const metaData = {}
      const response = assnMetaDataCollection
      response.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        metaData[doc.id] = doc.data();
        // console.log('HERE', doc.id, doc.data())
        // look up the project info, go ahead and render without it
        // fetchAssnProjectInfo(doc.data().projectId)
      });
      setAssnMetaData(metaData)
    }
  }, [assnMetaDataCollection])

  // check if you have loaded everything
  useEffect(() => {
    if(assignments && assnMetaData) {
      setAssnLoaded(true)
    }
  }, [assnMetaData, assignments])

  // check if you have loaded creative
  useEffect(() => {
    if(!creativeDocLoading){
      let isDone = true
      for(const projectId of creativeProjects) {
        if(!(projectId in creativeMetaData)) {
          isDone = false
        }
      }
      if(isDone){
        setCreativeLoaded(true)
      }
    }
    
  }, [creativeMetaData, creativeProjects])

  const fullyLoadedAssn = assnLoaded && !completedDataIsLoading
  return (
    <AssnContext.Provider
      value={{
        assignments,
        assnMetaData,
        assnMap:assnMapDoc,
        assnLoaded:fullyLoadedAssn,
        creativeProjects,
        creativeMetaData,
        creativeLoaded,
        groupProjects,
        groupLoaded,
        completedAssignments,
        deleteCreativeProject
      }}
    >
      {children}
    </AssnContext.Provider>
  );
};
