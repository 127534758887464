import React, { useState, useEffect, useContext } from "react";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { FaBars } from 'react-icons/fa';
import { Outlet } from "react-router-dom"
import { useLocation } from 'react-router-dom';

import {
  ProfileContext,
  ProfileProvider,
  Role,
} from "../../contexts/ProfileContext";
import { Page404 } from "components/errors/Page404";
import { isProduction } from "../../config";
import {
  LessonsContext,
  LessonsProvider,
} from "course/contexts/LessonsContext";
import {
  Menu,
  MenuItem,
  ProSidebarProvider,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { useWindowSize } from "react-use-size";
import { SI } from "country-flag-icons/react/3x2";
import { isMinimumRole, isProfileComplete, isStaffRole } from "contexts/ProfileUtil.jsx";
import Gate from "contexts/Gate";
import { NoAccess } from 'components/errors/NoAccess'
import { courseMaxWidth } from "course/constants/courseContants";
import { CompletionProvider } from "contexts/CompletionContext";
import { Loading } from "components/loading/Loading";
import { getApprovedCourses } from "utils/general";
import { RoadmapProvider } from "course/contexts/RoadmapContext";
import { check } from "prettier";
import { AssnProvider } from "course/contexts/AssnContext";


export const IdePage = (props) => {
  const { courseId } = useParams();
  const [approvedCourses, setApprovedCourses] = useState({})
  const [loaded, setLoaded] = useState(false)

  // make sure its a real course
  useEffect(() => {
    const getAc = async () => {
      const ac = await getApprovedCourses()
      setApprovedCourses(ac)
      setLoaded(true)
    }
    getAc();
  }, [])



  // if (!(courseId in approvedCourses) && loaded) {
  //   return <Page404 message={`Unknown url path: ${courseId}`} />
  // }


  if (!loaded) {
    <Loading />
  }


  return (
      <ProfileProvider>
        <CompletionProvider courseId={courseId}>
          <RoadmapProvider courseId={courseId}>
            <AssnProvider courseId={courseId}>
              <LessonsProvider courseId={courseId}>
                <CoursePageWithContext {...props} approvedCourses={approvedCourses} />
              </LessonsProvider>
            </AssnProvider>
          </RoadmapProvider>
        </CompletionProvider>
      </ProfileProvider>
  );
};

const CoursePageWithContext = ({ noSidebar, approvedCourses }) => {
  const { courseId } = useParams();
  const navigate = useNavigate()
  const { loading: userIsLoading, userData } = useContext(ProfileContext)

  if (userIsLoading) return <></>;


  // does the user have the minimum role?
  const courseRole = userData.courseRole
  const requiredRole = approvedCourses[courseId]

  if (requiredRole) {
    if (!isMinimumRole(courseRole, requiredRole)) {
      // if user role unassigned, redirect to student application
      if (!courseRole) {
        navigate(`/public/join/${courseId}`)
        return <></>
      }
      // only admins can see the course page for now
      if (isProduction) {
        return <NoAccess />
      }
    }
  }

  return (
    <ProSidebarProvider>
      <Outlet />
    </ProSidebarProvider>
  );
};

