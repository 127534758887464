import { Button, Nav, Navbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FaUsers,
  FaPlay,
  FaStop,
  FaHome,
  FaEdit,
  FaWindowClose,
  FaPhone,
  FaSyncAlt,
  FaDownload,
  FaChevronLeft,
} from "react-icons/fa";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { useContext, useState, createRef } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import useMediaQuery, { Breakpoints } from "../utils/useMediaQuery";
import Swal from "sweetalert2";
import {
  checkIsProjectConsole,
  checkIsProjectGraphics,
  checkIsProjectKarel,
  getAllFileNames,
  getAllFileNamesWithoutImages,
  getProjectFilesCode,
  updateProjectTitle,
  getAllImages
} from "./utils/general";
import { IDEContext } from "./contexts/IDEContext";
import { PeerSessionContext } from "./contexts/PeerSessionContext";
import { useCourseId } from "hooks/router/useUrlParams";

import { MeetingButtons } from "./PairProgram/MeetingButtons";
import { PyodideContext } from "components/pyodide/PyodideProvider";
import { areWorldsEqual } from "components/pyodide/KarelLib/util";
import { DiagnosticNav } from "diagnostic/DiagnosticNav";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useHistoryNavigate } from "hooks/router/useHistoryNavigate";
import { NavigationHistoryContext } from "contexts/NavigationContext";
import { HomeExitButton } from "./HomeExitButton";


export const navBackgroundColor = "#d0e0ff" //#ccddff
export const sliderColor = '#3273f6'
export const runBtnColor = "#bb34fe"
export const disabledSliderColor = "#cdd5e8"




export const NavIDE = ({
  isRunning,
  startStopButtonClicked,
  projectData,
  assnData,
  stepFunc,
  stepListSize,
  onPublish,
  fileStructure
}) => {
  const {
    editable,
    karelWorldState,
    defaultKarelWorldState,
    isDiagnostic
  } = useContext(IDEContext);


  const { isMeeting, meetingRole } = useContext(PeerSessionContext);
  const courseId = useCourseId()

  const isTablet = useMediaQuery(Breakpoints.TAB);
  const isMobile = useMediaQuery(Breakpoints.XS);
  const projectId = projectData.uid;
  const assnId = assnData?.metaData?.uid
  const shareUrl = `/${courseId}/share/${projectId}`;
  
  const [title, setTitle] = useState(
    assnData?.metaData?.title ? assnData.metaData.title : projectData.title
  );





  const downloadProject = async () => {
    try {
      const files = getAllFileNamesWithoutImages(fileStructure);
      const filesCode = await getProjectFilesCode(projectData.uid)
      const images = await getAllImages(fileStructure)

      const zip = new JSZip();
      const folder = zip.folder(projectData.title);
      files.forEach((file) => {
        if (filesCode.hasOwnProperty(file.id)) {
          folder.file(file.name, filesCode[file.id].content);
        }
      });

      for (var image of Object.keys(images)) {
        if (images[image]) {
          const response = await fetch(images[image]);
          const blob = await response.blob();
          folder.file(image, blob);
        }
      }

      const content = await zip.generateAsync({ type: "blob" })
      saveAs(content, `${projectData.title}.zip`);
    } catch (e) {
      await Swal.fire(`There was an error downloading your project`, e.message)
    }

  };

  const isRenamable = !assnData && editable;
  const isKarel = checkIsProjectKarel(projectData, assnData);
  const isConsole = checkIsProjectConsole(projectData, assnData);
  const isGraphics = checkIsProjectGraphics(projectData, assnData)

  if (isDiagnostic) {
    return <DiagnosticNav
      isRunning={isRunning}
      startStopButtonClicked={startStopButtonClicked}
      isKarel={isKarel}
      karelWorldState={karelWorldState}
      defaultKarelWorldState={defaultKarelWorldState}
      editable={editable}
    />
  }

  return (
    <Navbar
      style={{ backgroundColor: navBackgroundColor }}
      variant="dark"
      className="fixed-top"
    >
      <Navbar.Brand style={{ color: "black", fontWeight: 600 }}>
        <HomeExitButton />
        <code style={{}}>IDE</code> | {title}
        {isRenamable ? (
          <button
            onClick={async () => {
              const { value: projectName } = await Swal.fire({
                title: "Edit Project Name",
                input: "text",
                inputValue: title,
                inputLabel: "What do you want your project name to be?",
                showCancelButton: true,
                inputValidator: (value) => {
                  if (!value) {
                    return "You can't have an empty project name!";
                  }
                },
              });

              await updateProjectTitle(projectData.uid, projectName);
              setTitle(projectName);
            }}
            style={{ marginLeft: "10px" }}
            className="bg-transparent border-0"
          >
            <label className="visually-hidden">Edit Project Name</label>
            <FaEdit />
          </button>
        ) : null}
        <button className="bg-transparent border-0" onClick={downloadProject}>
          <label className="visually-hidden">Download Project</label>
          <FaDownload />
        </button>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        style={{ justifyContent: "space-between" }}
      >
        {/* This is the left side */}

        <Nav>
          <Nav.Item></Nav.Item>
        </Nav>

        {/* Not MOBILE */}
        {isTablet ? (
          <>
            <Nav>
              <StartStopButton
                isRunning={isRunning}
                startStopButtonClicked={startStopButtonClicked}
                isKarel={isKarel}
                karelWorldState={karelWorldState}
                defaultKarelWorldState={defaultKarelWorldState}
                editable={editable}
              />
              <ReplaySlider stepFunc={stepFunc} stepListSize={stepListSize} />
            </Nav>

            {/* This is the right side */}
            <Nav>
              {isMeeting ? (
                <MeetingButtons originalMeetingRole={meetingRole} />
              ) : (
                <></>
              )}
              {/* disabled=isMeeting because we don't want peer sessions to be able to share code */}
              <button
                disabled={isMeeting}
                id="share-button"
                onClick={async () => {
                  const files = getAllFileNamesWithoutImages(fileStructure);

                  if (files.length == 0) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "You need to have at least one file to publish this app!",
                    });
                    return;
                  }

                  const hasMoreThanOneFile = files.length > 1;
                  let publishStatus = false;

                  // Check if there are any extra files
                  let showTerminalVal = isConsole ? true : false;
                  let showTerminalConfirmedVal = true;

                  if (isGraphics) {
                    const {
                      value: showTerminal,
                      isConfirmed: showTerminalConfirmed,
                    } = await Swal.fire({
                      title: "Do you want to show the terminal?",
                      input: "checkbox",
                      inputValue: true,
                      inputPlaceholder: "Show Terminal?",
                      confirmButtonText:
                        'Continue <i class="fa fa-arrow-right"></i>',
                    });

                    showTerminalVal = showTerminal;
                    showTerminalConfirmedVal = showTerminalConfirmed;
                  }

                  if (hasMoreThanOneFile) {
                    const listFiles = {};
                    for (let i = 0; i < files.length; i++) {
                      listFiles[files[i].id] = files[i].name;
                    }

                    const { value: mainFile, isConfirmed: isConfirmed } =
                      await Swal.fire({
                        title: "Select Your Main File!",
                        text: "What is the main file you want to run for your published app?",
                        input: "select",
                        confirmButtonText: "Publish!",
                        inputOptions: listFiles,
                        inputPlaceholder: "Select a file",
                        showCancelButton: true,
                        inputValidator: (result) =>
                          !result && "You need to select a file!",
                      });

                    if (isConfirmed) {
                      publishStatus = await onPublish(
                        mainFile,
                        !!showTerminalVal,
                        title
                      );
                    }
                  } else {
                    const mainFile = files[0].id;

                    publishStatus = await onPublish(
                      mainFile,
                      !!showTerminalVal,
                      title
                    );
                  }

                  if (publishStatus && showTerminalConfirmedVal) {
                    const { isConfirmed: isConfirmed } = await Swal.fire({
                      title: "Congrats!",
                      html:
                        `
                          <div><b>You just published your project!</b></div><br />
                          <input value=` +
                        window.location.origin +
                        shareUrl +
                        ` />
                        `,
                      icon: "success",
                      confirmButtonText: "View your project!",
                    });

                    if (isConfirmed) {
                      window.open(shareUrl, "_blank");
                    }
                  } else {
                    Swal.fire(
                      {
                        icon: "error",
                        title: "Oops...",
                        html: `We couldn't publish your project. Make sure to <a href="https://codeinplace.stanford.edu/cip3/profile"> set your display name.</a>`,
                      }
                    )
                  }
                }}
                className="btn btn-primary btn-sm mr-2"
              >
                <FaUsers /> Share
              </button>
              {/* <button className="btn btn-primary btn-sm">
                <FaHandshake /> Get Help
              </button> */}
            </Nav>
          </>
        ) : (
          // MOBILE VIEW
          <>
            <Nav>
              <StartStopButton
                isRunning={isRunning}
                startStopButtonClicked={startStopButtonClicked}
                isKarel={isKarel}
                karelWorldState={karelWorldState}
                defaultKarelWorldState={defaultKarelWorldState}
                editable={editable}
              />
            </Nav>
          </>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};



const ReplaySlider = ({ stepFunc, stepListSize }) => {
  const stepMin = 1;
  const {
    editable,
    stepMode,
    setStepMode,
    setLineNo,
    stepPtr,
    setStepPtr,
    hasHistory,
    karelWorldState,
    defaultKarelWorldState,
  } = useContext(IDEContext);
  const isDisabled = !hasHistory;

  return <div
    className="d-flex flex-row justify-content-center gap-2"
    style={{
      opacity: isDisabled ? 0.4 : 1.0,
    }}
    id="replay-mode-stepper"
  >
    <div
      onClick={() => {
        if (stepPtr > stepMin && hasHistory) {
          setStepPtr(stepPtr - 1);
          setLineNo(stepFunc(stepPtr - 1));

          setStepMode(true);
        }
      }}
      style={{ marginTop: "2px", cursor: "pointer" }}
    >
      <IoIosArrowDropleftCircle color={isDisabled ? disabledSliderColor : sliderColor} />
    </div>
    <input
      id="stepslider"
      type="range"
      min={stepMin}
      max={stepListSize() - 1}
      value={stepPtr}
      onChange={(e) => {
        const stepIdx = e.nativeEvent.target.value;
        setStepPtr(parseInt(stepIdx));
        setLineNo(stepFunc(parseInt(stepIdx)));

        setStepMode(true);
      }}
      disabled={!hasHistory}
      aria-label="replay-slider"
    ></input>
    <div
      onClick={() => {
        if (stepPtr < stepListSize() - 1 && hasHistory) {
          setStepPtr(stepPtr + 1);
          setLineNo(stepFunc(stepPtr + 1));
          setStepMode(true);
        }
      }}
      style={{ marginTop: "2px", cursor: "pointer" }}
    >
      <IoIosArrowDroprightCircle color={isDisabled ? disabledSliderColor : sliderColor} />
    </div>

    <div
      onClick={() => {
        setStepMode(false);
      }}
      style={{
        cursor: "pointer",
        visibility: !stepMode ? "hidden" : "visible",
        marginTop: "2px",
      }}
    >
      <FaWindowClose color={sliderColor} />
    </div>
  </div>
}



export const StartStopButton = ({
  isRunning,
  startStopButtonClicked,
  isKarel,
  defaultKarelWorldState,
  karelWorldState,
  editable
}) => {
  const { isPyodideLoading } = useContext(PyodideContext)
  const atStarterWorld = areWorldsEqual(defaultKarelWorldState, karelWorldState)
  const startStopRef = createRef();
  let buttonText = "";
  let buttonIcon;
  if (isKarel) {
    buttonText = isRunning
      ? "Stop"
      : !atStarterWorld
        ? "Reset"
        : "Run";

    buttonIcon = isRunning ? (
      <FaStop />
    ) : !atStarterWorld ? (
      <FaSyncAlt />
    ) : (
      <FaPlay />
    );
  } else {
    buttonText = isRunning ? "Stop" : "Run";
    buttonIcon = isRunning ? <FaStop /> : <FaPlay />;
  }

  const isDisabled =  isPyodideLoading

  if (isPyodideLoading) {
    buttonText = "Loading"
    buttonIcon = <></>
  }


  return (
    <div>
      <button
        id="start-stop-button"
        style={{
          width: 80,
          backgroundColor: runBtnColor,
          borderColor: runBtnColor,
        }} // keep a constant width as you change text
        className="btn btn-primary btn-sm mr-2"
        disabled={isDisabled}
        onClick={() => {
          startStopRef.current.blur()
          startStopButtonClicked()
        }}
        ref={startStopRef}
      >
        {buttonIcon} {buttonText}
      </button>
    </div>
  );
};

