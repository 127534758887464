import { useCourseId } from "hooks/router/useUrlParams";
import { navBackgroundColor } from "ide/NavIDE";
import { FaBars } from "react-icons/fa";
import { useProSidebar } from "react-pro-sidebar";
import { useWindowSize } from "react-use-size";
import styled from "styled-components";
import { Nav, Navbar as BootstrapNavbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

export const IdeLandingNav = () => {
  return (
    <CustomNavbar>
      <ToggleAside />
    </CustomNavbar>
  );
};

const CustomNavbar = ({ children }) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768
  const courseId = useCourseId();
  return (
    <NavbarWrapper bg="light" variant="light">
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-center"
      >
        <div
          className="d-flex justify-content-between align-items-center w-100"
        >
          {/* left size */}
          <Nav>
            <BootstrapNavbar.Brand>
              <Link to={`/`}>
                <Brand title={"Stanford IDE"} course={courseId}/>
              </Link>
            </BootstrapNavbar.Brand>
          </Nav>


          {/* right side */}
          {children && (
            <NavIcons
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                // TODO: @TJ can you make this a ref so that this width matches left size -- Still need to do this maybe... quick fix implemented
                minWidth: isMobile ? "0px": "260px",
              }}
            >
              {children}
            </NavIcons>
          )}
        </div>
      </BootstrapNavbar.Collapse>
    </NavbarWrapper>
  );
};

const ToggleAside = (props) => {
  const { broken, toggleSidebar } = useProSidebar();
  return (
    <div>
      {broken && (
        <button
          className="btn btn-outline-light"
          onClick={() => toggleSidebar()}
        >
          <FaBars />
        </button>
      )}
    </div>
  );
};

const Brand = ({title, course}) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      
      <span  style={{color:'black'}}>{title} / {course}</span>
    </div>
  );
};

const NavbarWrapper = styled(BootstrapNavbar)`
  background-color: ${navBackgroundColor} !important;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 1;
  height: 45px;
  width:100%;
`;

const NavIcons = styled(Nav)`
  display: flex;
  alignitems: center;
`;