import { getApp } from "firebase/app";
import { collection, getFirestore, doc } from "firebase/firestore";

import type {
  CollectionReference,
  FirestoreDataConverter,
} from "firebase/firestore";

export const getCollectionRef = <T>(
  collectionName: string,
  converter: FirestoreDataConverter<T>
): CollectionReference<T> => {
  return collection(getFirestore(getApp()), collectionName).withConverter(
    converter
  );
};
