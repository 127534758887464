import { getCornerSize, getCornerX, getCornerY, isValidRowCol, isValidWall, parseWallString } from "../util";

export const InnerWalls = ({state}) => {

    const worldState = state.worldState
    // console.log(worldState)
    var walls = [];
    // gentle fail if there are no walls
    if (!("walls" in worldState) || !worldState.walls) return <></>;

    for (var wallStr in worldState.walls) {
        // sometimes the value is false!
        const value = worldState.walls[wallStr]
        // undefined is ok, but false means it shouldnt be drawn
        if(value === false) continue

        const [r,c,d] = parseWallString(wallStr)
        // console.log(r, c, d)
        if(!isValidWall(state, r, c, d)){
            continue
        }
        if (d === "North") {
            walls.push(<NorthWall key={wallStr}
                r={r}
                c={c}
                state={state}
            />);
        } else if (d === "East") {
            walls.push(<EastWall key={wallStr}
                r={r}
                c={c}
                state={state}
            />);
        } else {
            console.error("Walls must be North or East");
        }
    }
    return <div>{walls}</div>;
}

export const NorthWall = ({r,c,state}) => {
    // console.log(r, c, 'NORTH')
    let x = getCornerX(state,r, c);
    let y = getCornerY(state,r, c);
    return <div
    style={{
        marginLeft: x,
        marginTop: y,
        width: getCornerSize(state),
        height: 2,
        position: "absolute",
        backgroundColor: "black"
    }}
    ></div>
}

export const EastWall = ({r,c,state}) => {
    let x = getCornerX(state,r,c + 1);
    let y = getCornerY(state,r,c);
    return <div
    style={{
        marginLeft: x,
        marginTop: y,
        width: 2,
        height: getCornerSize(state),
        position: "absolute",
        backgroundColor: "black"
    }}
    ></div>
}
