import React from "react";
import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

import VisuallyHidden from "../VisuallyHidden";

import "./loading.css";

export const Loading = () => (
  <div id="loader-wrapper">
    <div id="loader"></div>
    {/* <div
      className="splashCard"
      style={{
        maxWidth: 700,
        marginTop: "200px",
        fontSize: 24,
        textAlign: "center",
      }}
    >
      <b>Coding Pro Tip:</b> Take time off the screen to think about the problem you are trying to solve.
    </div> */}
  </div>
);

/**
 * Alternative loading component to use when loading a portion of a page
 * (such as a single page within the Course content wrapper) to avoid
 * taking over the entire viewport.
 */
export const PartialLoading = () => {
  return (
    <PartialLoadingWrapper>
      <PartialLoadingSpinner />
      <VisuallyHidden>Loading...</VisuallyHidden>
    </PartialLoadingWrapper>
  );
};

const PartialLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // TODO: height 100% might not be appropriate for all usecases,
  // since it might take over more of the viewport than desired
  height: 100%;
`;

const PartialLoadingSpinner = styled(Spinner)`
  width: 4rem;
  height: 4rem;
`;
