import React, { useEffect } from "react";
import { FirebaseAuthProvider } from "./FirebaseAuthProvider";
import firebase from "firebase/compat/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseConfig } from "../../config";
import { Login } from "./Login";
import { Loading } from "../loading/Loading";
import { ToastLayer } from "../toasts/ToastLayer";
import { EventsProvider } from "../../course/contexts/EventsContext";
import { useLocation, useParams } from "react-router-dom";
import { useActiveRoom } from "../../firebase/realtime/Rooms";
import { database } from "firebaseApp.js";
import { ApplicationVersionMonitor } from "./ApplicationVersionMonitor";
import { useDebouncedCallback } from "use-debounce";
import { ExperimentProvider } from "contexts/ExperimentContext";
import { useIdleTimer } from "react-idle-timer";



export const Authenticated = ({ component: Component, ...props }) => {
  return (
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <LoginHelper component={Component} />
    </FirebaseAuthProvider>
  );
};

// For published page in particular, where you don't have to be authenticated, but if you are, you can edit it!
export const AuthenticatedOptional = ({ component: Component, ...props }) => {
  return (
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <AuthenticatedOptionalHelper component={Component} />
    </FirebaseAuthProvider>
  );
};

export const AuthenticatedOptionalHelper = ({
  component: Component,
  ...props
}) => {
  const [user, loading] = useAuthState(firebase.auth());

  return <Component user={user} />;
};

// TODO: make events optional
const LoginHelper = ({ component: Component, ...props }) => {
  const [user, loading] = useAuthState(firebase.auth());
  const { courseId } = useParams();
  const activeRoom = useActiveRoom(user?.uid, courseId);
  useUserPresence(user, courseId)
  if (loading) {
    return <Loading />;
  }

  if (!user || !user.emailVerified) {
    return <Login />;
  }

  return (
    <EventsProvider courseId={courseId}>
      <ApplicationVersionMonitor />
      <ToastLayer user={user} activeRoom={activeRoom}>
        <ExperimentProvider>
        <Component user={user} />
        </ExperimentProvider>
      </ToastLayer>
    </EventsProvider>
  );
};

// time before user is considered idle
const IDLE_TIMEOUT_MS = 1000 * 60 * 15; // 15 minutes

// update user engagnement every 5 seconds
const THROTTLE_ACTIVITY_MS = 1000 * 5; // 5 seconds

const useUserPresence = (user, courseId) => {

  const location = useLocation();

  const shouldLog = (courseId === 'cip3' || courseId === "public" || "cip4") && user
  const userStatusRef = user ? database.ref(`/${courseId}/status/${user.uid}`) : null;

  const onIdle = (event, idleTimer) => {
    if(userStatusRef && shouldLog) {
      userStatusRef.update({
        is_idle: true,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        timestampLocal: Date.now(),
      });
    }
  }

  const onAction = (event, idleTimer) => {
    if(userStatusRef && shouldLog) {
      userStatusRef.set({
        is_idle: false,
        last_engaged_loc: location.pathname,
        last_engaged_time: firebase.database.ServerValue.TIMESTAMP,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        timestampLocal: Date.now(),
        // need this to track people's time differences if ever needed
      });
    }
  }

  // throttle makes surre you fire only once every THROTTLE_ACTIVITY_MS milliseconds
  useIdleTimer({ onIdle, onAction, timeout: IDLE_TIMEOUT_MS, throttle: THROTTLE_ACTIVITY_MS})
};
