import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { doc, getFirestore, getDoc, getDocs, collection, updateDoc, setDoc } from "firebase/firestore";
import { alertError } from 'components/completion/Alerts';
import { getAuth } from 'firebase/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';

// Define the type for the context value

interface ProgressObject { [key: string]: any }


/**
 * TODO: refactor to use data instead of predicate functions
 * (so that folks can react to changes)
 */

/*
* Lessons, assignment, and section progress all have designated documents in firestore for each user to determine there progress.
* For selfCheck necessary progresses, one idea is all self check, anselfCheck idea is, if they are coded in, give them an attribute in 
* some selfCheck user specific document to add a completed boolean. Not sure how to police this.
*/


interface CompletionContextType {
  isCompletionLoading: boolean;
  isLessonCompleted: (lessonId) => boolean;
  isLessonSlideCompleted: (lessonId, slideId) => boolean;
  isAssnCompleted: (assnId) => boolean;
  isSectionCompleted: (sectionAndWeekId) => boolean;
  isSelfCheckCompleted: (completedKey) => boolean;
  setLessonCompleted: (lessonId) => any;
  setLessonSlideCompleted: (lessonId, slideId) => any;
  setAssnCompleted: (assnId) => any;
  setSectionCompleted: (sectionAndWeekId) => any;
  setSelfCheckCompleted: (completedKey, isSet) => any;
  // if you need something responsive, listen to these
  lessonsProgress: any;
  assnProgress: any;
  selfCheckProgress: any;
  sectionProgress: any;
  completionUpdateBit: boolean;
}

const defaultCompletion: CompletionContextType = {
  isCompletionLoading: true,
  isLessonCompleted: () => {return false},
  isLessonSlideCompleted: () => {return false},
  isAssnCompleted: () => {return false},
  isSectionCompleted: () => {return false},
  isSelfCheckCompleted: () => {return false},
  setLessonCompleted: (a) => {},
  setLessonSlideCompleted: (a,b) => {},
  setAssnCompleted: (a) => {},
  setSectionCompleted: (a) => {},
  setSelfCheckCompleted: (a) => {},
  lessonsProgress: {},
  assnProgress: {},
  selfCheckProgress: {},
  sectionProgress: {},
  completionUpdateBit: false
}

// Create the context with initial undefined value
export const CompletionContext = createContext<CompletionContextType | undefined>(defaultCompletion);


interface CompletionProviderProps {
  children: ReactNode;
  courseId: string;
}


export const CompletionProvider: React.FC<CompletionProviderProps> = ({ courseId, children }) => {
  const db = getFirestore()
  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;
  
  // Document Refs:
  const trackablesPath = `/users/${userId}/${courseId}`
  const lessonsProgressDocRef = doc(db, `${trackablesPath}/lessonsProgress`);
  const assnProgressDocRef = doc(db, `${trackablesPath}/assnProgress`);
  const sectionProgressDocRef = doc(db, `${trackablesPath}/sectionAttendance`);
  const selfCheckProgressDocRef = doc(db, `${trackablesPath}/selfCheckProgress`);
  
  // progress lookups (now with useDocumentData)
  const [lessonsProgress, lessonsLoading] = useDocumentData(lessonsProgressDocRef);
  const [assnProgress, assnLoading] = useDocumentData(assnProgressDocRef);
  const [sectionProgress, sectionLoading] = useDocumentData(sectionProgressDocRef);
  const [selfCheckProgress, selfCheckLoading] = useDocumentData(selfCheckProgressDocRef);
  const [completionUpdateBit, setCompletionUpdateBit] = useState(false)
  
  const isCompletionLoading = lessonsLoading || assnLoading || selfCheckLoading || sectionLoading;


  // This useEffect lazy syncs the user's public progress with their cip4 progress
  // it should only run once
  useEffect(() => {
    // if not cip4, return
    if(courseId !== "cip4") { return; }


    const copyLessonsData = async () => {
      const publicLessonsPath = `/users/${userId}/public/lessonsProgress`
      const publicLessonsProgressDocRef = doc(db, publicLessonsPath);
      await copyData(publicLessonsProgressDocRef, lessonsProgressDocRef)
    }

    const copyAssnData = async () => {
      // For assns we copy both the progress and the map
      const publicAssnPath = `/users/${userId}/public/assnProgress`
      const publicAssnProgressDocRef = doc(db, publicAssnPath);
      const publicAssnMapPath = `/users/${userId}/public/assnMap`
      const publicAssnMapDocRef = doc(db, publicAssnMapPath);
      await copyData(publicAssnProgressDocRef, assnProgressDocRef)
      await copyData(publicAssnMapDocRef, doc(db, `${trackablesPath}/assnMap`))
    }

    // copy data from source to dest
    const copyData = async (source, dest) => {
      const docInfo = await getDoc(source)
      if(!docInfo.exists()) { return; }
      const sourceData = docInfo.data();
      await setDoc(dest, sourceData, { merge: true })
    }

    // if the user has no progress, copy it from public
    if(!lessonsLoading && !assnLoading) {
      if(!lessonsProgress || !lessonsProgress["welcome-to-karel"]) {
        // only copy if the user has no progress
        // Every user should have completed welcome-to-karel to get into the course
        copyLessonsData()
      }
      if(!assnProgress) {
        // only copy if the user has no progress
        copyAssnData()
      }
    }
  }, [lessonsLoading, assnLoading])

  const isLessonCompleted = (lessonId: string) => {
    // the lesson is only considered completed if it has been
    // explicitly marked as completed, even if all slides are completed
    if(!lessonsProgress) { return undefined; }
    if (Object.keys(lessonsProgress).includes(lessonId)) {
      if (lessonsProgress[lessonId]) {
        return true;
      }
    }

    return false
  }

  const isLessonSlideCompleted = (lessonId, slideId) => {
    if(!lessonsProgress) { return undefined; }
    const lessonSlideKey = `${lessonId}/${slideId}`
    if (!(Object.keys(lessonsProgress).includes(lessonSlideKey))) {
      return false;
    }
    return lessonsProgress[lessonSlideKey]
  }

  const setLessonSlideCompleted = async (lessonId, slideId) => {
    try {
      const lessonSlideKey = `${lessonId}/${slideId}`
      setDoc(lessonsProgressDocRef, {
        [lessonSlideKey]: true
      }, { merge: true })
    } catch (e) {
      alertError(e);
    }
    return false
  } 

  const isAssnCompleted = (assnId) => {
    if(!assnProgress) { return undefined; }
    if (!(Object.keys(assnProgress).includes(assnId))) {
      return false;
    }
    return assnProgress[assnId]
  }

  const isSectionCompleted = (sectionAndWeekId) => {
    if(!sectionProgress) { return undefined; }
    if (!(Object.keys(sectionProgress).includes(sectionAndWeekId.toString()))) {
      return false;
    }
    return sectionProgress[sectionAndWeekId]
  }

  const isSelfCheckCompleted = (selfCheckKey) => {
    if (!selfCheckKey) { return false; }
    if(!selfCheckProgress) { return undefined; }
    if (!(Object.keys(selfCheckProgress).includes(selfCheckKey))) {
      return false;
    }
    return selfCheckProgress[selfCheckKey]
  }


  const setLessonCompleted = async (lessonId) => {
    if (!lessonId) { return false; }

    try {
      setDoc(lessonsProgressDocRef, {
        [lessonId]: true
      }, { merge: true })
    } catch (e) {
      alertError(e);
    }
    setCompletionUpdateBit(!completionUpdateBit)
  }



  const setAssnCompleted = async (assnId) => {
    if (assnProgress[assnId]) { return; }

    try {
      setDoc(assnProgressDocRef, {
        [assnId]: true
      }, { merge: true })
    } catch (e) {
      alertError(e);
    }
    setCompletionUpdateBit(!completionUpdateBit)
  }


  // TODO! Finish these two 
  const setSectionCompleted = async (sectionAndWeekId) => {

    setSectionCompleted(newSectionProgress => {
      return {
        ...newSectionProgress,
        [sectionAndWeekId]: true
      }
    })
    setCompletionUpdateBit(!completionUpdateBit)

  }

  const setSelfCheckCompleted = async (selfCheckKey, isSet = true) => {
    if (selfCheckProgress && selfCheckProgress[selfCheckKey] === isSet) { return; }
    try {
      await setDoc(selfCheckProgressDocRef, {
        [selfCheckKey]: isSet
      }, { merge: true })
    } catch (e) {
      alertError(e);
    }
    setCompletionUpdateBit(!completionUpdateBit)
  }
  return (
    <CompletionContext.Provider value={{
      isCompletionLoading,
      isLessonCompleted,
      isLessonSlideCompleted,
      isAssnCompleted,
      isSectionCompleted,
      isSelfCheckCompleted,
      setLessonCompleted,
      setLessonSlideCompleted,
      setAssnCompleted,
      setSectionCompleted,
      setSelfCheckCompleted,
      lessonsProgress: lessonsProgress || {},
      assnProgress : assnProgress || {},
      selfCheckProgress: selfCheckProgress || {},
      sectionProgress : sectionProgress || {},
      completionUpdateBit
    }}>
      {children}
    </CompletionContext.Provider>
  )
}