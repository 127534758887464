///users/9fLSKXY6QXdMSRpl2osilNDoKV73/docs/uiTraining

import {
  collection,
  doc,
  getDoc,
  getFirestore,
  query,
  setDoc,
} from "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import { useUserId } from "hooks/user/useUserId";
import {
  useCollection,
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { useCourseId } from "hooks/router/useUrlParams";
import { firebaseStringToRole } from "./ProfileUtil";

const defaultData = {
  uiTraining: {},
  markComplete: (e) => setComplete(e),
  hasTraining: (e) => {return false},
  isLoadingUiTraining: true,
  errorLoadingUiTraining: undefined,
};

export const UiTrainingContext = createContext(defaultData);

export const UiTrainingProvider = ({ children }) => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null); // State to store the user's ID

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid); // Set userId if user is logged in
      } else {
        setUserId(null); // Reset userId if user is logged out
      }
    });

    return () => unsubscribe(); // Cleanup the subscription on component unmount
  }, []); // The empty array ensures this effect only runs once when the component mounts

  // Define the collection reference dynamically based on the userId
  // Note: We don't need to create a reference if userId is null
  const uiTrainingRef = userId ? doc(getFirestore(), `users/${userId}/docs/uiTraining`) : null;

  // console.log('uiTrainingRef', uiTrainingRef)

  // Use the useCollection hook only if userRolesRef is not null
  // This prevents trying to fetch a doc when there is no user
  const [trainingDoc, isLoadingUiTraining, errorLoadingUiTraining] = useDocumentData(uiTrainingRef);

  useEffect(() => {
    if (userId) {
      setIsLoading(false);
    }
  }, [isLoadingUiTraining])

  function hasTraining(e) {
    if (trainingDoc) {
      return trainingDoc[e];
    }
    return false;
  }


  return (
    <UiTrainingContext.Provider
      value={{
        markComplete: defaultData.markComplete,
        hasTraining,
        uiTraining:trainingDoc,
        isLoadingUiTraining:isLoading,
        errorLoadingUiTraining
      }}
    >
      {children}
    </UiTrainingContext.Provider>
  );
};

function setComplete(e) {
  const db = getFirestore();
  const auth = getAuth();
  const userId = auth.currentUser.uid;
  const documentPath = `users/${userId}/docs/uiTraining`;
  setDoc(doc(db, documentPath), {
    [e]: true,
  }, { merge: true });
}