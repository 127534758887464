import { useCourseId } from "hooks/router/useUrlParams"
import { FaBars } from "react-icons/fa"
import { useProSidebar } from "react-pro-sidebar"
import { Link } from "react-router-dom"
import styled from "styled-components"

export const AboutIde = () => {
  const courseId = useCourseId()

  return <AboutOuter>
    <NavLayout>
      <h3 style={{margin:0}}>Stanford <code>IDE</code></h3>
      <button className="btn btn-light">
        <ToggleAside/>
      </button>
    </NavLayout>
    <CustomHr />
    <InnerWide>
      <InnerNarrow>
        <Content>
          <p>Created by: Thomas Jefferson, Chris Piech, Sierra Wang, Joseph Tey, Juliette Woodrow, Ali Malik and more!</p>

          <p>Ways to get started:

            <ol>
              <li>View  <Link to={`/${courseId}/examples`}>Worked Examples</Link>.</li>
              <li>Try solving <Link to={`/${courseId}/challenges`}>Challenges</Link>.</li>

              <li>Create your own <Link to={`/${courseId}/create`}>Project</Link>.</li>
              <li>See a complete solution: <Link to={`/${courseId}/a/programmingisawesome`}>Programming is Awesome</Link>.</li>
              <li>Preview a <a target="_blank" href="https://codeinplace.stanford.edu/cip3/share/R1Dn2mnPeuUdbgSneeSt">Shared project</a>.</li>
            </ol>
          </p>

          <CustomHr />
          <p className="mt-4">The technology behind this IDE is open sourced. You can use it in your own course or learning experience:</p>
          <p className="mt-2">[1] Jefferson, Thomas, Chris Gregg, and Chris Piech. "<a href="https://dl.acm.org/doi/pdf/10.1145/3626252.3630913">PyodideU: Unlocking Python Entirely in a Browser for CS1.</a>" Proceedings of the 55th ACM Technical Symposium on Computer Science Education V. 1. 2024.</p>
          <p>[2] Wang, Sierra, John Mitchell, and Chris Piech. "<a href="https://dl.acm.org/doi/10.1145/3626252.3630764">A large scale RCT on effective error messages in CS1.</a>" Proceedings of the 55th ACM Technical Symposium on Computer Science Education V. 1. 2024.</p>
          <p>[3] Woodrow, Juliette, Ali Malik, and Chris Piech. "<a href="https://dl.acm.org/doi/abs/10.1145/3626252.3630773">AI Teaches the Art of Elegant Coding: Timely, Fair, and Helpful Style Feedback in a Global Course.</a>" Proceedings of the 55th ACM Technical Symposium on Computer Science Education V. 1. 2024.</p>
          <p>Special thanks to <a href="https://www.carina.fund/" target="_blank">Carina Initiatives</a> and Stanford University for funding development of this IDE. We are grateful that we get to work on what we love. If you are interested in incorporating this IDE into your class, please get in touch.</p>
          <StanfordLogo src="/stanford.png" />
          
        </Content>
      </InnerNarrow>
    </InnerWide>
  </AboutOuter>
}

const ToggleAside = (props) => {
  const { broken, toggleSidebar } = useProSidebar();
  return (
      <>{broken && (
        <button
          className="btn btn-sm btn-light"
          onClick={() => toggleSidebar()}
        >
          <FaBars />
        </button>
      )}
      </>
  );
};

const Content = styled.div`
  padding-top: 10px;
`

const CustomHr = styled.hr`
  width: 100%;
  padding: 0;
  margin: 0;
`

const InnerWide = styled.div`
  overflow: auto;
  `

const InnerNarrow = styled.div`
max-width: 800px;

`

const NavLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const AboutOuter = styled.div`
  display: flex;
  flex-direction: column;
  
  overflow: hidden;
`
const StanfordLogo = styled.img`
  max-width: 200px;
`

const IdeScreenshot = styled.img`
  max-width: 100%;
  margin-bottom: 10px;
`