import { firestore } from "firebaseApp";
import { serverTimestamp } from "firebase/firestore";
import { extractJSON } from "ide/UnitTest/gptAutograder/utils"

// Log the results of this run to firebase - can always add more to this
export const logCodeRun = ({
  userId,
  projectData,
  code,
  unitTestResults = undefined,
}) => {
  let result = {
    projectData: projectData,
    code: code,
    serverTimestamp: serverTimestamp(), // This is the real timestamp to use
  }

  // conditionally build the log object based on what information is given
  if (unitTestResults) {
    result.unitTestResults = unitTestResults
  }

  firestore.collection('ide_logs_v2')
    .doc(userId)
    .collection('logs')
    .doc(new Date().toISOString())
    .set(result)
    .then(() => {
      console.log('Document successfully updated/appended!');
    })
    .catch((error) => {
      console.error('Error updating/appending document to ide_logs_v2: ', error);
    });
}