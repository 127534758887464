import React, { useState, useEffect, useContext, useRef } from "react";
import "firebase/compat/firestore";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createNewProjectForAssn, createNewProjectWithType } from "../utils/createNewProject";
import { loadUserAssnMap } from "../../utils/general";
import { useRoomInfoOnce } from "../../firebase/realtime/Rooms";
import { useParams } from "react-router";
import { IDEContext } from "ide/contexts/IDEContext";
import { useUserId } from "../../hooks/user/useUserId";




export function useStandardIdeProjectLoader(assnContext) {
  /**
   * This effect loads the project data and the assignment data for
   * the ide. Importantly, it either takes a projectId or an assignmentId
   * Current that is passed in as urlFormat (which is "p" or "a") and
   * urlKey which is the corresponding id.
   * @param {string} urlFormat
   * @param {string} urlKey
   * @returns
   */
  const { courseId, urlFormat, urlKey } = useParams();
  if (urlFormat == "p") {
    return useProjectFirst(courseId, urlKey);
  } else if (urlFormat == "a") {
    // check to make sure that its not a diagnostic question (urlKey has diagnostic as substring)
    // if (urlKey.includes("diagnostic")) {
    //   return []
    // }

    return useAssnFirstWithContext(assnContext, courseId, urlKey);
  } else if (urlFormat == "c") {
    const projectType = urlKey;
    return useNewProject(assnContext, projectType, courseId);
  }
}

function useNewProject(assnContext, projectType, courseId) {
  const { creativeMetaData, creativeProjects, creativeLoaded } = assnContext

  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [error, setError] = useState(null);

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  useEffect(() => {
    if(!creativeMetaData || !creativeLoaded) {
      return
    }

    /*
    * creativeMetaData is a dictionary where the keys are projectIds and
    * the values are dictionaries containing type and lastEdited. Find
    * the last edited project of the given type (or undefined if none exist)
    */
    let lastEditedProjectId = undefined
    let lastEditedEpochTime = undefined
    for (const projectId in creativeMetaData) {
      const project = creativeMetaData[projectId]
      const isDeleted = !creativeProjects.includes(projectId)
      if (!isDeleted && project.type === projectType) {
        // lastEdited is a dateTime string
        const projectEditedEpochTime = Date.parse(project.lastEdited)
        if (!lastEditedEpochTime || projectEditedEpochTime < lastEditedEpochTime) {
          lastEditedProjectId = projectId
          lastEditedEpochTime = projectEditedEpochTime
        }
      }
    }
    console.log("lastEditedProjectId", lastEditedProjectId)

    if (lastEditedProjectId) {
      // you found an already existing project! load it up
      loadProjectDataAsync(
        lastEditedProjectId,
        (r) =>
          setProjectData({
            ...r,
            uid: lastEditedProjectId,
          }),
        (e) => setError(e)
      );
    } else {
      // you need to create a new project
      const projectTypeTitle = projectType.charAt(0).toUpperCase() + projectType.slice(1)
      const projectTitle = `My ${projectTypeTitle}`
      createNewProjectWithType(
          projectType,
          projectTitle,
          courseId,
          userId,
          (projectId) => {
            console.log("Created new project with id", projectId)
            loadProjectDataAsync(
              projectId,
              (r) =>
                setProjectData({
                  ...r,
                  uid: projectId,
                }),
              (e) => setError(e)
            );
          }
      )
    }
  }, [creativeLoaded])

  useEffect(() => {
    if(projectData) {
      setIsLoading(false)
    }
  }, [projectData])

  return [projectData, null, error, isLoading];
}

export function diagnosticProjectLoader() {
  const { courseId, questionId } = useParams();
  return useAssnFirst(courseId, questionId);
}

export function pairProjectLoader() {
  const { courseId, roomId } = useParams();
  return loadGroupFirst(courseId, roomId)
}

// for folks who just want the assignment data
export function loadAssnData(courseId, assnId, onError) {
  const [isLoading, setIsLoading] = useState(true);
  const [assnData, setAssnData] = useState(null);
  useEffect(() => {
    loadAssnDataAsync(
      courseId,
      assnId,
      (r) => setAssnData(r),
      (e) => onError(e)
    );
  }, []);

  useEffect(() => {
    if (assnData) {
      setIsLoading(false);
    }
  }, [assnData]);

  return [isLoading, assnData, setAssnData];
}

function useAssnFirstWithContext(assnContext, courseId, assnId) {
  const { assnMap, assnLoaded } = assnContext

  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [assnData, setAssnData] = useState(null);
  const [error, setError] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  useEffect(() => {
    console.log('Errror', error)
  }, [error])

  // to start, load the assn data
  useEffect(() => {
    setIsLoading(true);
    loadAssnDataAsync(
      courseId,
      assnId,
      (r) => setAssnData(r),
      (e) => setError(e)
    );
  }, [assnId]);

  useEffect(() => {
    if (assnLoaded && assnData && !projectData) {
      console.log('assnMap loaded', assnMap)
      if (assnMap && assnId in assnMap) {
        const projectId = assnMap[assnId]
        loadProjectDataAsync(
          projectId,
          (r) =>
            setProjectData({
              ...r,
              uid: projectId,
            }),
          (e) => setError(e)
        );
      } else {
        createNewProjectForAssn(
          userId,
          courseId,
          assnId,
          assnData,
          (projectId) => {
            loadProjectDataAsync(
              projectId,
              (r) =>
                setProjectData({
                  ...r,
                  uid: projectId,
                }),
              (e) => setError(e)
            );
          }
        );
      }
    }
  }, [assnLoaded, assnData])

  // you are done only when both of project and assn are populated
  useEffect(() => {
    if (projectData && assnData) {
      setIsLoading(false);
    }
  }, [projectData, assnData]);

  return [projectData, assnData, error, isLoading];
}

function useAssnFirst(courseId, assnId) {
  /**
   * Strategy:
   * Two parallel loads to start: load assn data and load
   * user assnId -> projectId map.
   * when we know the projectId for this user's instance
   * load the project. If the user doesn't have a project
   * for this assignment, create one!
   */
  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [assnData, setAssnData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  const auth = getAuth();
  const user = auth.currentUser;
  const userId = user.uid;

  // to start, load the user assn map and the assn data
  useEffect(() => {
    setIsLoading(true);
    loadAssnDataAsync(
      courseId,
      assnId,
      (r) => setAssnData(r),
      (e) => setError(e)
    );
  }, [assnId]);

  useEffect(() => {
    if (assnData) {
      loadUserAssnMap(userId, courseId, (r) => setUserData(r));
    }
  }, [assnData]);

  // when the first stage is ready, we can either load the project or make it
  useEffect(() => {
    if (userData && assnData) {
      // if project hasn't been created
      if (!(assnId in userData)) {
        createNewProjectForAssn(
          userId,
          courseId,
          assnId,
          assnData,
          (projectId) => {
            let newUserData = { ...userData };
            newUserData[assnId] = projectId;
            setUserData(newUserData);
          }
        );
      } else {
        const projectId = userData[assnId];
        loadProjectDataAsync(
          projectId,
          (r) =>
            setProjectData({
              ...r,
              uid: projectId,
            }),
          (e) => setError(e)
        );
      }
    }
  }, [userData, assnData]);

  // you are done only when both of project and assn are populated
  useEffect(() => {
    if (projectData && assnData) {
      setIsLoading(false);
    }
  }, [projectData, assnData]);

  // you are also done if u hit an error
  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  return [projectData, assnData, error, isLoading];
}

export function loadGroupFirst(courseId, roomId) {
  const roomInfo = useRoomInfoOnce(courseId, roomId);
  return useProjectFirst(courseId, roomInfo?.projId);
}

export function useProjectFirst(courseId, projectId) {
  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [assnData, setAssnData] = useState(null);
  const [error, setError] = useState(null);

  // load the project metaData
  useEffect(() => {
    if (projectId === undefined) {
      return
    }
    loadProjectDataAsync(
      projectId,
      (r) => setProjectData(r),
      (e) => setError(e)
    );
  }, [projectId]);


  // when project is done loading, load assn data
  // TODO: speed this up by using the assnMap in the assnContext!
  // you should already know if it is an assignment :)
  useEffect(() => {
    if (projectData == null) return;
    // inject the projectId into the data!
    projectData.uid = projectId;
    if (projectData.assnId) {
      loadAssnDataAsync(
        courseId,
        projectData.assnId,
        (r) => setAssnData(r),
        (e) => setError(e)
      );
    } else {
      setIsLoading(false);
    }
  }, [projectData]);

  // when the assignment is done loading, were finished
  useEffect(() => {
    if (assnData == null) return;
    setIsLoading(false);
  }, [assnData]);

  // you are also done if u hit an error
  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  return [projectData, assnData, error, isLoading];
}

async function loadAssnDataAsync(courseId, assnId, onResponse, onError) {
  const responseData = {};

  const db = getFirestore();
  const metaDataRef = doc(db, `assns/${courseId}/assignments/${assnId}`)
  const metaDataResponse = await getDoc(metaDataRef);

  if (!metaDataResponse) {
    onError(getAssnDoesNotExistError(assnId, courseId))
    return
  }
  responseData['metaData'] = metaDataResponse.data()
  if (!responseData['metaData']) {
    onError(getAssnDoesNotExistError(assnId, courseId))
    return
  }

  const collectionRef = collection(db, `assns/${courseId}/assignments/${assnId}/docs`);
  const response = await getDocs(collectionRef);

  response.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    responseData[doc.id] = doc.data();
  });
  if (Object.keys(responseData).length === 0) {
    onError(getAssnDoesNotExistError(assnId, courseId));
  } else {
    responseData['metaData'].uid = assnId
    onResponse(responseData);
  }
}

async function loadProjectDataAsync(projectId, onResponse, onError) {
  const db = getFirestore();
  const docRef = doc(db, `projects/${projectId}`);
  const response = await getDoc(docRef);
  if (response.exists()) {
    const data = response.data();
    onResponse(data);
  } else {
    onError("Project ID is invalid.");
    console.error("Failed to load project data");
  }
}


function getAssnDoesNotExistError(assnId, courseId) {
  return `Assignment ${assnId} does not exist in course ${courseId}`
}


// export function loadNeedsTutorial() {
//   // Checks if user is visiting IDE for the first time
//   // If it is, it will run the IDE Tutorial
//   const {
//     helpMode,
//     setHelpMode,
//   } = useContext(IDEContext);

//   useEffect(() => {
//     const checkToActiveHelpMode = async () => {
//       const seenIDE = await hasUserSeenIDE();

//       if (!seenIDE || !seenIDE.hasSeenIDETraining) {
//         setHelpMode(true);
//         await writeUserIDESeen();
//       }
//     };
//     checkToActiveHelpMode();
//   }, []);

//   return helpMode;
// }



export const loadIdeSettings = async () => {
  const { setScreenReadableEditorOn, setKarelType } = useContext(IDEContext);
  const db = getFirestore();
  const userId = useUserId()


  useEffect(() => {
    const getIdeSettings = async () => {
      const docRef = doc(db, "users", userId, "settings", "ide");
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        return;
      }
      const screenReadable = docSnap.data().screenReadableEditorOn ? docSnap.data().screenReadableEditorOn : false;
      const karelType = docSnap.data().karelType ? docSnap.data().karelType : "classic";


      setScreenReadableEditorOn(screenReadable)
      setKarelType(karelType)
    }

    getIdeSettings()
  }, [])


  return

}