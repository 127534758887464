// import firebase from "firebase";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

// set up the style
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.scss";
import {
  Authenticated
} from "./components/auth/Authenticated";
import ErrorBoundary from "components/errors/ErrorBoundary";
import PyodideProvider from "./components/pyodide/PyodideProvider";
import { IDEWithProvider } from "./ide/IDE";
import { StyleSplashPage } from "course/styleFeedback/StyleFeedbackSplash";
import { FileHistory } from "ide/fileHistory/FileHistory";
import { CourseProvider } from "contexts/CourseContext";
import { IdeLandingPage } from "ide/landing/IdeLandingPage";
import { IdePage } from "ide/landing/IdePage";
import { CodeSplash } from "course/code/CodeSplash";
import { PracticeProblemsSplash } from "course/code/PracticeProblemsSplash";
import { AboutIde } from "ide/landing/AboutIde";

/*
* This contains the broader route initialization.
* If a route does not belong to a course, put it here.
*/

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />}>
      {/* Landing website */}
      <Route index element={<Navigate to="cs106a" replace />} />
      <Route
        path=":courseId"
        element={
          <PyodideProvider>
            <CourseProvider>
                <Outlet />
            </CourseProvider>
          </PyodideProvider>

        }
      >
        {/* The course page (with side bar and navbar) */}
        <Route path="*" element={<Authenticated component={IdePage} />}>
      
          <Route element={<IdeLandingPage/>}>
            <Route index element={<AboutIde/>} />
            <Route path="create" element={<CodeSplash creative={true} />} />
            <Route path="examples" element={<PracticeProblemsSplash />} />
            <Route path="challenges" element={<CodeSplash />} />
            <Route path="about" element={<AboutIde/>} />
          </Route>

          {/* IDE Routes */}
          <Route>
            <Route path="styleFeedback/:projectId/:feedbackId" element={<StyleSplashPage />} />
            <Route path="history/:projectId/:fileId" element={<FileHistory />} />
            <Route path=":urlFormat/:urlKey" element={<IDEWithProvider/>} />
          </Route>

          {/* IDE Routes */}
          <Route>
            <Route path="ide/styleFeedback/:projectId/:feedbackId" element={<StyleSplashPage />} />
            <Route path="ide/history/:projectId/:fileId" element={<FileHistory />} />
            <Route path="ide/:urlFormat/:urlKey" element={<IDEWithProvider/>} />
          </Route>

        </Route>
        
      </Route>

    </Route>
  )
);

export const RouterIDE = () => {
  return <RouterProvider router={router} />;
};
