import React, { useContext, useEffect, useRef, useState } from "react"; // every page needs to import react

// if you want to use the auth state
import { getAuth } from "firebase/auth";

// if you want to interact with the database
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "react-tabs/style/react-tabs.css";
import { IDEContext } from "../contexts/IDEContext";
import { doc, getFirestore } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import useMediaQuery, { Breakpoints } from "utils/useMediaQuery";
import { useDebounce } from "use-debounce";
import { CollabContext } from "../sync/CollabContext";
import { ResizableCodeInput } from "./ResizableCodeInput";
import { useMonacoBinding } from "../sync/hooks/useMonacoBinding";
import { de } from "date-fns/locale";

const WEBRTC_PROVIDERS = [
  'wss://y-webrtc.codeinplace.org'
]

export const CollabEditor = ({ firebaseDocPath, ideOnFileChange, currFile }) => {
  // we should make an uneditable version!
  const { editable } = useContext(IDEContext);
  // tell if you are in mobile view
  const isTablet = useMediaQuery(Breakpoints.TAB);
  const auth = getAuth();
  const user = auth.currentUser;

  const db = getFirestore();
  const docRef = doc(db, firebaseDocPath);

  const [serverData, serverDataLoading, serverDataError] =
    useDocumentDataOnce(docRef);
  if (!firebaseDocPath) {
    return <>No firebaseDocPath</>;
  }

  if (serverDataLoading) return <></>;
  // if (serverDataError) return <p>Error loading data</p>;

  if (serverDataError)
    return <></>;

  if(!user) {
    return <></>
  }

  // this will force a refresh when someone changes the code (like a reset)
  const versionNumber = serverData?.codeVersionNumber || 0;

  // force the component to un-mount if any of these change
  const userId = user.uid 
  const uniqueKey = `${firebaseDocPath}-${isTablet}-${editable}-${versionNumber}=${userId}`


  return (
    <CollabEditorInner
      key={uniqueKey}
      serverData={serverData}
      firebaseDocPath={firebaseDocPath}
      ideOnFileChange={ideOnFileChange}
      user={user}
      currFile={currFile}
    />
  );
};


const CollabEditorInner = ({
  firebaseDocPath,
  ideOnFileChange,
  user,
  currFile,
  serverData
}) => {
  // STABILITY CHECK:
  // I want to make sure that the firebaseDocPath never changes in this doc
  // and same with the collaborative state. TipTap should have managed this
  const docPathRef = useRef(firebaseDocPath)
  useEffect(() => {
    if(!firebaseDocPath) {
      throw new Error("firebaseDocPath is null in TipTapSafe")
    }
    if(docPathRef.current !== firebaseDocPath) {
      throw new Error("firebaseDocPath changed in TipTapSafe")
    }
  }, [firebaseDocPath])

  const { setStepMode, projectData, setHasHistory, setErrorLineNo, editable } = useContext(IDEContext);
  // for writing to the firebase. ready changes are the json to save
  // which gets debounced to prevent too many writes.
  const [readyChanges, setReadyChanges] = useState(null);
  const [debouncedChanges] = useDebounce(readyChanges, 250);
  const isFirstDebounce = useRef(true);
  const { sessionRef, isSessionRefLoaded } = useContext(CollabContext)
  const isReadOnly = !editable;
  const isFallback = true;



  useMonacoBinding(currFile?.id, firebaseDocPath, serverData, () => {}, isFallback)



  const onUpdate = (text) => {
    const session = sessionRef.current

    if(!session) {
      console.error("Web provider is not set up")
      return
    }

    // the underlying json has changed
    if (isFallback || isLoggingUser(session.provider.awareness, projectData.editors, user.uid)) {
      setReadyChanges(text);
    }
    setHasHistory(false);
    setStepMode(false);
    ideOnFileChange(text);
    setErrorLineNo(0)
  };

  

  useEffect(() => {
    // there are two ways to know you are ready
    // debouncedChanges are not null and ydocRef.current is not undefined
    if (isSessionRefLoaded && editable && debouncedChanges && !isFirstDebounce.current) {
      // only save if this isn't the data we just loaded from the db
      // option for optimization: you could short circuit the deepequal.
      // dont save if there hasn't been a change
      sessionRef.current.logCode(debouncedChanges, firebaseDocPath);
      isFirstDebounce.current = false;
    } else {
      isFirstDebounce.current = false;
    }
  }, [debouncedChanges]);



  

  return (
      <ResizableCodeInput
        isReadOnly={isReadOnly}
        onChange = {(e) => onUpdate(e)}
      />

  );
};



const getUniqueProviderRoom = (firebaseUrl) => {
  const SALT = "codeinplace250419829";
  return SALT + firebaseUrl.replaceAll("/", "-");
};


const isLoggingUser = (awareness, editors, userId) => {
  /**
   * Update: April 28th
   * If one of the users has write permissions on the project, then chose one
   * of them randomly to be the loggingUser. Otherwise default to the
   * old method. In general editors and admins have write permissions.
   */


  /**
   * GOAL: I would like this to alternate answers among users to add some
   * robustness
   * Ways that I could possibly do this include:
   *  - take the time and mod it by half a second, and use this
   *    to chose which person should write (edit: wont work)
   *  - in the awareness dicitonary, we could record the last time
   *    that the person wrote to the database. Person who is supposed
   *    to write is the one who has written the least recently (with
   *    some code to manage ties and to manage users who never write)
   *  - everyone debounces every 250 * N_USERS ms and we somehow make
   *    sure that they are well stratified (edit: wont work)
   */

  // are you in charge of saving to the database?
  let states = awareness.getStates();


  let userArray = Array.from(states).map((state) => state[1]["user"]);

  // fitler userArray to all people with user.database_user_id in editors
  const editorsOnlyUserArray = userArray.filter((user) => {
    return editors.includes(user.database_user_id);
  });

  // if there are no editors, then console error
  if (editorsOnlyUserArray.length == 0) {
    // important to returnf alse here
    return false;
  }

  // Should be a list of at least one
  // get min user Id of editorsOnlyUserrArray
  // const minUserId = Math.min(...editorsOnlyUserArray.map((user) => parseInt(user.database_user_id));

  // finds min of an array of anything that can be compared
  const arrayMinGeneric = (arr) => {
    return arr.reduce((p, v) => (p <= v ? p : v));
  }
  const minUserId = arrayMinGeneric(editorsOnlyUserArray.map(user => user.database_user_id));
  return minUserId === userId;
};



const OfflineEditor = ({ ideOnFileChange }) => {	
  const [code, setCode] = useState("# offline editor (changes are not saved)");	

  const onUpdate = (newCode) => {	
    setCode(newCode);	
    ideOnFileChange(newCode);	
  };	

  return (	
    <ResizableCodeInput
      isReadOnly={false}
      onChange={onUpdate}
      code={code}
      isOffline={true}
    />	
  );	
};