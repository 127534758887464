import { IDEContext } from "ide/contexts/IDEContext"
import { useContext } from "react"




export const AccessibleTerminal = () => {
    const { terminalRef } = useContext(IDEContext)


    
    const onSubmit = (e) => {
        e.preventDefault()
        const val = e.target[0].value
        e.target[0].value = ""
        const terminal = terminalRef.current
        if (!terminal) return
        terminal._write(val, false)
        terminal._handleEnter(val)
    }



    return (
        <div className="w-100 h-100 bg-dark text-light">
            <div className="d-flex w-100" style={{height: "80%"}}>
                <label htmlFor="terminal-acc" className="visually-hidden" >Terminal Output</label>
                <textarea id='terminal-acc' className="w-100 bg-dark text-light"  wrap="off"
                ></textarea>
                <label htmlFor="clear-btn" className="visually-hidden">Clear Terminal</label>
                <button className="btn" id='clear-btn' onClick={() => console.log("clear")} >Clear</button>
            </div>

            <div className="d-flex ">
                <span>{">"}</span>
                <form onSubmit={onSubmit} className="h-100 w-100">
                    <label htmlFor="terminal-prompt" className="visually-hidden">Terminal Input</label>
                    <input 
                        id="terminal-prompt" 
                        className="w-100 bg-dark text-light" 
                        style={{height: "10%"}} 
                        autoComplete="off"
                    />
                </form>

            </div>

        </div>
    )
}