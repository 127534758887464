import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const gptCreateCompletion = httpsCallable(functions, "callGPTCompletions");
const gptCreateChat = httpsCallable(functions, "callGPTChat");
const gptCreateEdit = httpsCallable(functions, "callGPTEdit");
const gptStyleFeedbackRequest = httpsCallable(functions, "styleFeedbackRequest");
const gptAutograderRequest = httpsCallable(functions, "autograderRequest")

export const callGptChat = async (model, messages) => {
  try {
    const response = await gptCreateChat({ model: model, messages: messages });
    console.log(response.data)
    return response.data;
  } catch (e) {
    console.log(e)
    return ""
  }
}

// NOTE - none of the functions below this comment will work anymore
// - Sierra, 2-29-2024 (Leap day!)

export const callGPT3Edit = async (code, prompt, model = "code-davinci-edit-001", temp = 0) => {
  const response = await gptCreateEdit({ code: code, prompt: prompt, model: model, temp: temp });
  return response.data;
}

export const callGPT3Completion = async (prompt, maxTokens, model, temp = 0.5) => {
  const response = await gptCreateCompletion({ prompt: prompt, maxTokens: maxTokens, model: model, temp: temp });
  return response.data;
}

export const callStyleFeedbackRequest = async (messages, loggingLocation, loggingType, pathToProjectFeedbackRequests, model = "gpt-3.5-turbo") => {
  const response = await gptStyleFeedbackRequest({ messages: messages, model: model, loggingLocation: loggingLocation, loggingType: loggingType, pathToProjectFeedbackRequests: pathToProjectFeedbackRequests });
  return response.data;
};

export const callGptAutograder = async (callParams) => {
  const { assnId, userId, courseId, studentCode } = callParams
  const response = await gptAutograderRequest({
    assnId,
    userId,
    courseId,
    studentCode
  })
  return response.data
}
