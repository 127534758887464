// renders a karel world based on state

import { useContext, useEffect, useState } from "react";
import { useComponentSize } from "react-use-size";
import useMediaQuery, { Breakpoints } from "utils/useMediaQuery";
import { EXAMPLE_WORLD_STATE, getCornerSize, isValidWorldState } from "./util";
import { Agent } from "./worldComponents/Agent";
import { Beepers } from "./worldComponents/Beepers";
import { GridBackground } from "./worldComponents/GridBackgroud";
import { InnerWalls } from "./worldComponents/InnerWalls";
import { Paint } from "./worldComponents/Paint";
import { IDEContext } from "ide/contexts/IDEContext";
import ScreenReader, { getWorldDescription } from "./worldComponents/ScreenReader";

/**
 * KarelWorld
 * This is a state driven component. It doesn't have
 * any logic of how karel should function, but can
 * draw a world based on state
 *
 * See EXAMPLE_STATE in ./util to see what is expected
 */

export const KarelWorld = ({ worldState, alignment = "left" }) => {
  const canvasState = useComponentSize();
  const isLargeScreen = useMediaQuery(Breakpoints.XS);
  const [worldHeight, setWorldHeight] = useState(0);
  const { karelType } = useContext(IDEContext);


  // package the state into a variable
  const state = {
    // worldState is karel relevant variables. This is currently
    // a fixed example :-)
    worldState,
    // canvas state are {width, height, ref} of the canvas
    canvasState,
  };

  // react to change in the dimension of the "canvas"
  useEffect(() => {
    // don't crash if its not a valid world
    if (isValidWorldState(worldState)) {
      let cornerSize = getCornerSize(state);
      setWorldHeight(worldState.nRows * cornerSize);
    }
  }, [canvasState, worldState]);

  if (!isValidWorldState(worldState)) return <>Invalid Karel state</>;

  const worldDescription = getWorldDescription(worldState, false)
  return (
    <div
      tabIndex={0}
      aria-label={worldDescription}
      style={{flexGrow:2}}
      className={` d-flex flex-column gap-2 ${
        alignment == "center" ? "justify-content-center align-items-center" : ""
      }`}
    >
      <div
        ref={canvasState.ref}
        style={{
          height: worldHeight,
          width: !isLargeScreen
            ? "100%"
            : alignment == "center"
            ? worldState.nCols * 80
            : "auto",
          maxWidth: worldState.nCols * 80,
          backgroundColor: "white",
          position: "relative",
          userSelect: "none",
          border: "solid 1px",
        }}
      >
        <GridBackground state={state} />
        <Paint state={state} />
        <InnerWalls state={state} />
        <Beepers state={state} />
        <Agent state={state} karelType={karelType} />
      </div>
      {/* <ScreenReader textDescription = {worldDescription} /> */}
    </div>
  );
};


