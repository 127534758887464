import { database, auth, functions } from "firebaseApp.js";

import { ERROR_CODES, safeFirebase } from "./Errors";
import { FBReturn } from "./types";
import { useEffect, useState } from "react";
import { serverTimestamp } from "firebase/database";


interface SLCareTicket {
    ticketId: string,
    slId: string,
    slIntent: 'help' | 'random',
    timeCreatedMS: number,
    timeCreatedMSv2: any,
    numTried: number   // how many students we have tried (including the current one)
    // these values are only set if we are currently trying to match the SL with a student
    currStudentId?: string,  // id of current student we are nudging
    currStudentStatus?: "requesting" | "rejected" | "accepted" | "timedout",
    projId? : string,
    assnId? : string,
  }


const _createSLCareTicket = async (slId: string, courseId: string, slIntent: 'help' | 'random') : Promise<FBReturn> => {
    const ticketRef = database.ref(`${courseId}/care/sls/${slId}`);
    const ticketCurr = await ticketRef.once('value');
    const ticketId = crypto.randomUUID()
    if (ticketCurr.exists()) {
        return {
            success: false,
            code: ERROR_CODES.QUEUE.ALREADY_IN_QUEUE,
            error: "SL ticket already exists",
        };
    }

    try {
        const ticket: SLCareTicket = {
            ticketId,
            slId,
            slIntent,
            timeCreatedMS: Date.now(),
            timeCreatedMSv2: serverTimestamp(),
            numTried: 0
          }
          await ticketRef.set(ticket);
          return {success: true};

    } catch (error) {
        return {
            success: false,
            code: ERROR_CODES.GENERIC,
            error: "Generic error: " + error,
        }

    }

}

const _removeSLCareTicket = async (slId: string, courseId: string) : Promise<FBReturn> => {
    // TODO: be careful about this since this could be done while we are in the middle of matching a student
    try {
        const ticketRef = database.ref(`/${courseId}/care/sls/${slId}`);
        const ticket = await ticketRef.once('value');
        if (!ticket.exists()) return {
            success: false,
            code: ERROR_CODES.QUEUE.NOT_IN_QUEUE,
            error: 'Trying to remove sl ticket but no ticket exists'
        };
        await ticketRef.remove();
        return {
            success: true
        }
    } catch (error) {
        return {
            success: false,
            code: ERROR_CODES.GENERIC,
            error: "Generic error: " + error,
        }
    }
}

export const useSLHasCareTicket = (slId: string, courseId: string) : boolean | undefined => {
    const [slTicketExists, setSLTicketExists] = useState(undefined);
    useEffect(() => {
        database.ref(`/${courseId}/care/sls/${slId}`).on("value", (snap) => {
            if(!snap.exists()) {
                setSLTicketExists(false);
            } else {
                setSLTicketExists(true);
            }
        })
    }, [courseId, slId]);

    return slTicketExists
}

const _setSLCareTicketStatus = async (
    uid: string,
    slId: string,
    courseId: string,
    status: "rejected" | "accepted" | "timeout",
    projId: undefined | string = undefined,
    assnId: undefined | string = undefined,   // If undefined, then they are in /p/projId and we redirect back to /p/projId. Otherwise redirect to /a/assnId
    ) : Promise<FBReturn> => {

    const ticketRef = database.ref(`/${courseId}/care/sls/${slId}`);
    const ticket = await ticketRef.once('value');
    if (!ticket.exists()) {
        return {
            success: false,
            code: 'TODO',
            error: 'SL ticket does not exist, likely they left'
        }
    }
    const ticketVal = ticket.val();
    if (ticketVal.currStudentId !== uid) {
        return {
            success: false,
            code: 'TODO',
            error: 'SL ticket does not have this student as the current student. Investigate...'
        }
    }
    if (ticketVal.currStudentStatus !== 'requesting') {
        return {
            success: false,
            code: 'TODO',
            error: 'SL ticket does not have requesting as status. Investigate...'
        }
    }
    await ticketRef.update(
        projId === undefined ? {
            currStudentStatus: status
        } : assnId === undefined ?
            {
                currStudentStatus: status,
                projId,
            } : {
                currStudentStatus: status,
                projId,
                assnId
            }
        )
    return {
        success: true
    }
}

export const joinSLCareHelp = (uid: string, slId: string, projId: string, courseId: string, assnId: string | undefined = undefined) => {
    return _setSLCareTicketStatus(uid, slId, courseId, 'accepted', projId, assnId);
}

export const rejectSLCareHelp = (uid: string, slId: string, courseId: string) => {
    return _setSLCareTicketStatus(uid, slId, courseId, 'rejected');
}

export const timeoutSLCareHelp = (uid: string, slId: string, courseId: string) => {
    return _setSLCareTicketStatus(uid, slId, courseId, 'timeout');
}

export const createSLCareTicket = safeFirebase(_createSLCareTicket);
export const removeSLCareTicket = safeFirebase(_removeSLCareTicket);
