import { database } from "firebaseApp.js";
import { ERROR_CODES, safeFirebase } from "./Errors";
import { useEffect, useState } from "react";
import { RoomInfo } from "./types";
import { firestore } from "../../firebaseApp";
import { serverTimestamp } from "firebase/firestore";

export const ROOM_WAITING = 'waiting';
export const ROOM_REJECTED = 'rejected';
export const ROOM_TIMEOUT = 'timeout';
export const ROOM_READY = 'ready';
export const ROOM_JOINED = 'joined';
export const ROOM_EXITED = 'exited';

const roomStatuses = [ROOM_WAITING, ROOM_REJECTED, ROOM_TIMEOUT, ROOM_READY, ROOM_JOINED, ROOM_EXITED];

const _setRoomStatus = async (uid, courseId, roomId, newStatus) => {
    try {
        // if new status is not a valid status, return error
        if (!Object.values(roomStatuses).includes(newStatus)) {
            return {
                success: false,
                code: ERROR_CODES.ROOMS.INVALID_STATUS,
                error: "Invalid room status",
            };
        }

        const userStatusRef = database.ref(`/${courseId}/rooms/members/${roomId}/${uid}/status`);
        const userStatusSnap = await userStatusRef.once('value');
        if (!userStatusSnap.exists()) return {
            success: false,
            code: ERROR_CODES.ROOMS.INVALID_ROOM,
            error: 'Trying to set status for user but they are not in the room'
          };
        await userStatusRef.set(newStatus);
        return {
            success: true
        }
    } catch (error) {
        return {
            success: false,
            code: ERROR_CODES.GENERIC,
            error: "Generic error: " + error,
        }
    }
}

export const setRoomStatus = safeFirebase(_setRoomStatus);

export const useActiveRoom = (uid, courseId): string | undefined => {
    const [activeRoom, setActiveRoom] = useState(undefined);

    useEffect(() => {
        if(uid) {
            const activeRoomRef = database.ref(`/${courseId}/learners/${uid}/activeRoom`)
            activeRoomRef.on("value", (snap) => {
                const val = snap.exists() ? snap.val() : "";
                setActiveRoom(val);
            })
            return () => {
                activeRoomRef.off();
            }
        }
    }, [uid, courseId]);
    return activeRoom;
}


export const useRoomInfoOnce = (courseId: string, roomId: string): RoomInfo => {
    const [roomInfo, setRoomInfo] = useState(undefined);

    useEffect(() => {
        const roomInfoRef = database.ref(`/${courseId}/rooms/info/${roomId}`);
        // get room info once

        roomInfoRef.get().then((snap) => {
            if (snap.exists()) {
                setRoomInfo(snap.val());
            }
        });
    }, [courseId, roomId]);

    return roomInfo;
}

export const useRoomStatus = (courseId, roomId, uid) => {
    const [status, setStatus] = useState(undefined);

    useEffect(() => {
        const userStatusRef = database.ref(`/${courseId}/rooms/members/${roomId}/${uid}/status`);
        const userStatusSnap = userStatusRef.on('value', (snap) => {
            if (snap.exists()) {
                setStatus(snap.val());
            }
        });
        return () => {
            userStatusRef.off('value', userStatusSnap);
        }
    }, [courseId, roomId, uid]);

    return status;
}

export const useRole = (courseId, roomId, uid) => {
    const [role, setRole] = useState(undefined)

    useEffect(() => {
        if(uid && roomId) {
            const pilotRef = database.ref(`/${courseId}/rooms/members/${roomId}/${uid}/role`)
            pilotRef.on("value", (snap) => {
                if(snap.exists()) {
                    // Roles: 'pilot', 'copilot', 'sl' (pilot/copilot for peer pilot/sl for office hours)
                    setRole(snap.val())
                }
            })
        }
    }, [uid, roomId])
    return role;
}

export const useRoomMembers = (courseId, roomId) => {
    const [memberInfo, setMemberInfo] = useState(undefined)

    useEffect(() => {
        const membersRef = database.ref(`/${courseId}/rooms/members/${roomId}`);
        const memberSnap = membersRef.on("value", (snap) => {
            if(snap.exists()) {
                const membersDict = snap.val()
                const membersLst = Object.keys(membersDict)
                const membersRequesting = membersLst.filter((m) => membersDict[m]['isRequesting'])
                const membersDisplayName = membersLst.map((m) => membersDict[m]['displayName'])
                const uidToPresenceData = {}
                const uidToDisplayName = {}
                membersLst.forEach((m) => {
                    uidToDisplayName[m] = membersDict[m]['displayName']
                    uidToPresenceData[m] = membersDict[m]['presence']
                })
                const d = {
                    'members': membersLst,
                    'requesters': membersRequesting,
                    'displayNames': membersDisplayName,
                    'uidToDisplayName': uidToDisplayName,
                    'uidToPresenceData': uidToPresenceData,
                }

                console.log("Room members: ", d)

                setMemberInfo(d)
            }
        })
    }, [courseId, roomId])

    return memberInfo
}

const _addTeachingTeamToRoom = (courseId, roomId, uid, displayName) => {
    const teachingTeamRef = database.ref(`/${courseId}/rooms/members/${roomId}/${uid}`)
    return teachingTeamRef.set({
        'displayName': displayName,
        'isRequesting': false,
        'role': 'extra-sl',
        'status': 'joined'
    }).then(() => {
        return true
    }).catch((error) => {
        return false
    })
}

export const addTeachingTeamToRoom = safeFirebase(_addTeachingTeamToRoom)

export const useIsInRoom = (courseId, roomId, uid) => {
    const [isInRoom, setIsInRoom] = useState(undefined);

    useEffect(() => {
      const membersRef = database.ref(`/${courseId}/rooms/members/${roomId}/`);
      const onValueChange = (snap) => {
        if (snap.exists() && snap.hasChild(uid)) {
            setIsInRoom(true);
        } else {
            setIsInRoom(false);
        }
      };

      // Attach the listener
      membersRef.on('value', onValueChange);

      // Cleanup the listener when the component is unmounted or courseId, roomId, or uid change
      return () => {
        membersRef.off('value', onValueChange);
      };
    }, [courseId, roomId, uid]);

    return isInRoom;
};

const _setPilotStatus = async (courseId, roomId, newPilotUid, currentPilotUid) => {
    const newPilotRoleRef = database.ref(`/${courseId}/rooms/members/${roomId}/${newPilotUid}/role`)
    const newPilotRoleSnap = await newPilotRoleRef.once('value');
    await newPilotRoleRef.set('pilot')
    // Turn off the newPilotUid requesting
    _setRequestStatus(courseId, roomId, newPilotUid, false)

    // Set current pilot to be a learner
    const currentPilotRoleRef = database.ref(`/${courseId}/rooms/members/${roomId}/${currentPilotUid}/role`)
    const currentPilotRoleSnap = await currentPilotRoleRef.once('value');
    await currentPilotRoleRef.set('learner')
    return {
        success: true
    }
}

export const setPilotStatus = safeFirebase(_setPilotStatus);

const _setRequestStatus = async (courseId, roomId, uid, updatedVal) => {
    const isRequestingRef = database.ref(`/${courseId}/rooms/members/${roomId}/${uid}/isRequesting`)
    const isRequestingSnap = await isRequestingRef.once('value');
    await isRequestingRef.set(updatedVal);
    return {
        success: true
    }
}

export const setRequestStatus = safeFirebase(_setRequestStatus)

export const submitSLFeedback = async (feedback, feedbackText, comment, roomId, uid, courseId) => {
    const ref = firestore
      .collection("matching")
      .doc(courseId)
      .collection("rooms")
      .doc(roomId)
      .collection("slFeedback")
      .doc(uid);

    return ref.set({
      rating: feedback,
      ratingText: feedbackText,
      version: 'v1',
      additionalComments: comment,
      timestamp: Date.now(),
      timestampV2: serverTimestamp(),
    });
}



export const submitGratitude = async (gratitude, roomId, uid, courseId) => {
    console.log(gratitude, roomId, uid, courseId)
    const ref = firestore
        .collection("matching")
        .doc(courseId)
        .collection("rooms")
        .doc(roomId)
        .collection("gratitude")
        .doc(uid);

    return ref.set({
        gratitude: gratitude,
        version: 'v1',
        timestamp: Date.now(),
        timestampV2: serverTimestamp(),
    });
};

export const updateStudentComment = async (comment, roomId, uid, courseId) => {
    const ref = firestore
        .collection("matching")
        .doc(courseId)
        .collection("rooms")
        .doc(roomId)
        .collection("gratitude")
        .doc(uid);

    return ref.update({
        messageToSL: comment
    });
}

