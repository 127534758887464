import { getErrorMessage } from "../../ErrorMessage/errorhint.js";

// Init terminal term

export class AccessibleTerminalView {
  constructor(userSpecifiedShellprompt, runCode, onEnd, raiseStop, isKarel,setReplMode, runReplCode) {
    this.shellprompt = `${userSpecifiedShellprompt} `;
    this._currCmd = "";
    this._input = false;
    this._stdinPromise = {};
    this._runCode = runCode;
    this._onEnd = onEnd;
    this.buffer = ''
    this.flushTimer = null
    this._raiseStop = raiseStop;
    this._cmdBuffer = []
    this._cmdPtr = 0;
    this.replMode = false
  }


  /**
   * @name initTerm
   * @description This function initializes the terminal. It is run everytime the Accessible terminal is first loaded
   * @returns {void}
   */
  initTerm() {
    const term = this._getTerminal();
    if(!term) return;
    term.addEventListener("keydown", this._onKey.bind(this));
    term.value = this.shellprompt
  }


  /**
   * @name focusTerm
   * @description This function focuses the terminal. It is run everytime the Accessible terminal is first loaded
   * @returns {void}
   */
  focusTerm() {
    const input = this._getTermInput();
    if (input) {
      input.focus();
    }
  }         

  /**
   * @name blur
   * @description This function blurs the terminal. It is run everytime the Accessible terminal is first loaded
   * @returns {void}
   */
  blur() {
    const input = this._getTermInput();
    if (input) {
      input.blur();
    }
  }

  // This function is here for consistency with the other terminal model
  refreshTerm() {}


  /**
   * @name _prompt
   * @description This function writes the shell prompt to the terminal
   * @returns {void}
   */
  _prompt(_=true) {
    const term = this._getTerminal();
    if(!term) return;
    this._write("\r\n" + this.shellprompt);
  }

  /**
   * @name _prepareOutput
   * @description This function prepares the terminal for output by adding a new line
   * @returns {void}
   */
  _prepareOutput() {
    this._write("\n");
  }


  /**
   * @name writeCommand
   * @description This function writes a users command to the terminal
   * @param {string} cmd - The command to be written to the terminal
   */
  writeCommand(cmd) {
    this._write(this.shellprompt + cmd)

  }


  /**
   * @name _onKey
   * @description This function handles key presses on the terminal
   * @param {*} e 
   * @returns {Promise<void>}
   */
  async _onKey(e) {
    const term = this._getTerminal();
    if(!term) return;
    const keyCode = e.keyCode;
    const ctrlPressed = e.ctrlKey;
    if(keyCode === 67 && ctrlPressed) {
      e.preventDefault();
      this._write("^C")
      this._currCmd = ""
      this._raiseStop()
      this.endStdin();
    }


    if ( e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key !== 'Tab') {
        e.preventDefault(); // Prevent typing or deletion before the prompt
        return;
    }

  }


  /**
   * @name _handleEnter
   * @description This function handles when the user hits enter.
   * @param {string} cmd - The command to be handled.
   * @returns {Promise<void>}
   */
  async _handleEnter(cmd) {
    const args = cmd.split(/\s+/);
    const firstArg = args[0];

    // Check for keywords, else print command unknown
    if (this._input && this._stdinPromise.promise) {
      this._stdinPromise.resolve(cmd);
      return
    }
    if (firstArg === "python") {
      if (args[1]) {
        this._runCode();
      } else {
        this.writeAndScroll("\r\nPython 3.9");
        this.writeAndScroll("\r\nCode In Place 2023 Repl Mode");
        this.writeAndScroll(`\r\nType "quit()" to quit repl mode`);
        await this.toggleRepl();
      }
    } else if (firstArg === "clear") {
      this.clear();
      this._prompt();
    }else if (firstArg === "cd") {
      this.writeAndScroll("\r\n");
      this.writeAndScroll("No file directory here!");
      this._prompt();
    }else if (firstArg === "ls") {
      this.writeAndScroll("\r\n");
      this.writeAndScroll("What files do we have ");
      this._prompt();
    } else if (firstArg === "pip") {
      this.writeAndScroll("\r\n");
      this.writeAndScroll("You can't install packages, but you can import numpy!");
      this._prompt();
    } else if (firstArg === "echo") {
      this.writeAndScroll("\r\n");
      args.shift()
      for(var i of args) {
        this.writeAndScroll(i + " ");

      }
      this._prompt();
    }else {
      for (let unknownArg of args) {
        if (unknownArg.trim().length !== 0) {
          this.writeAndScroll("\r\n");
          this.writeAndScroll("command not found: ");
          this.writeAndScroll(unknownArg);
        }
      }
      this._prompt();
    }

    
  }


  /**
   * @name _handleReplEnter
   * @description This function handles when the user hits enter in repl mode.
   * @param {string} cmd - The command to be handled.
   * @returns {Promise<void>}
   * @todo 
   */   
  async _handleReplEnter(cmd) {
    /** @TODO */
  }


  /**
   * @name endStdin
   * @description This function ends the stdin promise
   * @returns {void}
   */
  endStdin() {
    this._stdinPromise.resolve()
  }


  /**
   * @name handleStdin
   * @description This function handles stdin
   * @param {string} pmt - The prompt to be displayed
   * @returns {Promise<string>} - The user input
   */
  async handleStdin(pmt) {
    const term = this._getTerminal();
    if(!term) return;
    term.focus();
    this._stdinPromise = this.getNewPromise();
    this._input = true;
    const returnedInput = await this._stdinPromise.promise;
    this._input = false;
    // Reset promise
    return returnedInput;
  
  }


  /**
   * @name handleStdout
   * @description This function handles stdout
   * @param {string} stdout - The stdout to be displayed
   * @param {boolean} nl - Whether or not to add a new line
   * @returns {void}
   */
  handleStdout(stdout, nl=true) {
    let term = this._getTerminal();
    if(!term) return;
    if (stdout !== "Python initialization complete") {
      this.buffer += `${nl ? "\n": ""}${stdout}` 
      this.scheduleFlush()
      
    }
   
  }


  /**
   * @name scheduleFlush
   * @description This function schedules a flush of the buffer to the terminal
   * @returns {void}
   */
  scheduleFlush() {
    if(!this.flushTimer) {
        this.flushTimer = setTimeout(this.flushBufferToTextArea.bind(this)  , 100)
    }
  }


  /**
   * @name flushBufferToTextArea
   * @description This function flushes the buffer to the terminal
   * @param {boolean} isEnd - Whether or not this is the end of the buffer
   * @returns {void}
   */
  flushBufferToTextArea(isEnd=false) {
    this._write(this.buffer, !isEnd)
    this.buffer = ''
    this.flushTimer = null
  }

  /**
   * @name handleStderr
   * @description This function handles stderr
   * @param {string} code 
   * @param {string} stderr 
   * @returns {Promise<string>} - The error message
   */
  async handleStderr(code, stderr) {
    // Use the selectedErrorMessageType to get an error message
    const error_message = await getErrorMessage(
      code,
      stderr,
      this
    );

    // Output error message to the terminal
    this._prepareOutput();
    this.writeAndScroll(error_message);

    return error_message;
  }


  /**
   * @name handleEnd
   * @description This function handles the end of the terminal
   * @returns {void}
   */
  handleEnd() {
    this.flushBufferToTextArea()
    this._prompt()

  }



  getNewPromise() {
    let resolveFunc;
    // Returns object with promise member and resolve member
    return {
      promise: new Promise(function (resolve, reject) {
        resolveFunc = resolve;
      }),
      resolve: resolveFunc,
    };
  }


  /**
   * @name writeAndScroll
   * @description This function writes to the terminal and scrolls to the bottom
   */
  writeAndScroll(str) {
    const term = this._getTerminal();
    if(!term) return;
    this._write(str)
  }


  /**
   * @name _getTerminal
   * @description This function gets the terminal output element
   * @returns {HTMLElement} - The terminal output
   */
  _getTerminal() {
    return document.getElementById("terminal-acc");
  }


  /**
   * @name _getTermInput
   * @description This function gets the terminal input element
   * @returns {HTMLElement} - The terminal input
   */
  _getTermInput() {
    return document.getElementById("terminal-prompt");
  }


  /**
   * @name _write
   * @description This function writes to the terminal
   * @param {string} str - The string to be written to the terminal
   * @param {boolean} _ - this is a dummy variable, added to maintain consistency with the other terminal model
   * @returns {void}
   */
  _write(str, _=true) {
    const term = this._getTerminal();
    if(!term) return;
    let termVal = term.value;
    let buffer = termVal.split("\n");
    if(buffer.length > 2000) {
        buffer = buffer.slice(-1000); // Keep only the last 1000 lines
        termVal = buffer.join("\n");
    }

    term.value = termVal + str;

    term.scrollTop = term.scrollHeight;
  }


  /**
   * @name clear
   * @description This function clears the terminal
   * @returns {void}
   */
  clear() {
    const term = this._getTerminal();
    if(!term) return;
    term.value = "";
  }


  /**
   * @name _debounce
   * @description This function debounces a given function (for maintaining the terminal buffer)
   * @param {function} func - The function to be debounced
   * @param {number} delay - The delay
   * @returns {function} - The debounced function
   */
  _debouce(func, delay) {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }


  /**
   * @name getCursorPosition
   * @description This function gets the cursor position
   * @returns {object} - The cursor position
   */
  getCursorPosition() {
    const textarea = this._getTerminal();
    if(!textarea) return;
    const text = textarea.value;
    const caretPos = textarea.selectionStart;
    const lines = text.substring(0, caretPos).split("\n");
    const row = lines.length;
    const col = lines[lines.length - 1].length;
    return { row, col };
  }


  // These three functions exist to maintain consistency with the other (non-accessible) terminal model
  updateCols(newWidth) {}
  updateRows(newHeight) {}
  async toggleRepl() {}
  dispose() {}


}
