import { MonacoBinding } from "./y/YMonaco";
import * as Y from "yjs";
import { User } from "../../firebase/models";
import { createMutex } from "lib0/mutex";
import { WebrtcProvider } from "./y/YWebrtc";
import { FirestoreCodeLogger } from "./fire/FirestoreLogger";
import { MonacoBindingSafe } from "./fire/FireMonaco";

const WEBRTC_PROVIDERS = []

export class CollabSession {
    public  readonly ydoc: Y.Doc;
    public readonly provider: WebrtcProvider;

    private binding: MonacoBinding | MonacoBindingSafe;
    private mux: any;
    private onUpdate: any;
    private yDocInitialized: boolean = false;
    private firestoreLogger: FirestoreCodeLogger;
    private lastUpdateCode: string = "";


    constructor (
        roomName: string,
        user: any,
        onUpdate: any,
        projectData: any
    ) {
        const userData = {
            database_user_id: user.uid,
            name: user.displayName,
            color: generateRandomDarkColorHex()
        }
        this.ydoc = new Y.Doc();
        this.provider = new WebrtcProvider(roomName, this.ydoc, {signaling: WEBRTC_PROVIDERS}, (x) => console.log(x));
        this.mux = createMutex();
        this.firestoreLogger = new FirestoreCodeLogger(this);
        this.onUpdate = onUpdate;
        onUpdate("clientId", this.ydoc.clientID);


        if(projectData && projectData.ydoc) {
            const ydocArray = projectData.ydoc.toUint8Array();
            Y.applyUpdateV2(this.ydoc, ydocArray);
            this.yDocInitialized = true;
        }

        this.provider.awareness.setLocalStateField("user", userData);
        this.provider.awareness.on("update", this.onAwarenessUpdate.bind(this));
    }



    onAwarenessUpdate({ added, updated, removed }) { 
        // If client added update collab list
        added.forEach(clientId => { 
            const state = this.provider.awareness.getStates().get(clientId);

            const info = {
                clientId: clientId,
                userData: state?.user
            }
            this.onUpdate("joined", info);
        });

        updated.forEach(clientId => {
            if(clientId === this.ydoc.clientID) { return; }
            const state = this.provider.awareness.getStates().get(clientId)
            if(!state) { return; }
            //print type of clientId
            for(let key of Object.keys(state)) {
                if(key !== "user") { 
                    this.onUpdate(key, state[key]);
                }
            }
        });
      
        // Remove user from collab list
        removed.forEach(clientId => {
            this.onUpdate("left", clientId);
        });

    }



    setMonacoBinding(currFileId: string, monacoModel: any, monacoEditors: any, serverData: any, firestorePath: string, isFallback: boolean = false) {
        if(this.binding) {
            this.binding.destroy();
        }
        if(isFallback) {
            this.binding = new MonacoBindingSafe(this.ydoc.getText(currFileId), monacoModel, monacoEditors, firestorePath, this);
            if(this.provider) {
                this.provider.destroy();
            }
        } else {
            this.binding = new MonacoBinding(this.ydoc.getText(currFileId), monacoModel, monacoEditors, this.provider.awareness);
            if(this.provider) {
                this.provider.awareness.setLocalStateField("file", currFileId);
            }
        }

        if(!this.yDocInitialized) {
            const content = serverData.content;
            this.ydoc.getText(currFileId).insert(0, content);
            this.logCode(content, firestorePath);
            monacoModel.setValue(content);
        }
    }


    logCode(debouncedChanges: any, firestorePath: string) {
        this.mux(() => {
            this.firestoreLogger.logCode(debouncedChanges, firestorePath, this.binding instanceof MonacoBindingSafe)
        })
    }

    getLastUpdateCode() {
        return this.lastUpdateCode;
    }

    setLastUpdateCode(code: string) {  
        this.lastUpdateCode = code;
    }


    destroyBinding() {
        if(!this.binding) { return; }
        this.binding.destroy();
    }



    destroy() {
        if(this.provider) { 
            this.provider.destroy();
        }
        if(this.binding) { this.binding.destroy(); }
    }

}


function generateRandomDarkColorHex() {
    const colors = [
        "red",
        "blue",
        "green",
        "purple",
        "orange",
        "yellow",
    ]
    return colors[Math.floor(Math.random()*6)]
}