import * as Y from 'yjs'
import * as monaco from "monaco-editor"
import { createMutex } from 'lib0/mutex'
import { Awareness } from 'y-protocols/awareness' // eslint-disable-line
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import { CollabSession } from '../Session'


export class MonacoBindingSafe {
  /**
   * @param {Y.Text} ytext
   * @param {monaco.editor.ITextModel} monacoModel
   * @param {string} firestorePath
   * @param { CollabSession } session
   */
  constructor (ytext, monacoModel, editors, firestorePath, session) {
    this.doc = /** @type {Y.Doc} */ (ytext.doc)
    this.ytext = ytext
    this.session = session
    this.monacoModel = monacoModel
    this.mux = createMutex()
    const db = getFirestore()
    const fileDocRef = doc(db, firestorePath)


    // Setup listener to currently opened file
    this.unsubscribe = onSnapshot(fileDocRef, snapshot => {
      // if local change, ignore
      if(snapshot.metadata.hasPendingWrites) {
        return;
      }
      const code = snapshot.data().content;
      const client = snapshot.data().updatingClient;
      // if is change made by the client, ignore
      if(client === this.doc.clientID || !code) {
        return;
      }
      // set the monaco value if the updated code is different from the current code
      this.mux(() => {
        this.doc.transact(() => {
          const monacoModel = this.monacoModel
          const monacoValue = monacoModel.getValue()
          if (monacoValue !== code) {
            this.session.setLastUpdateCode(code)
            monacoModel.setValue(code)
          }
        }, this)
      })
    })


    
    /**
     * @param {Y.YTextEvent} event
     */
    // When monaco value changes, update ydoc with changes
    this._monacoChangeHandler = monacoModel.onDidChangeContent(event => {
      // apply changes from right to left
      this.mux(() => {
        this.doc.transact(() => {
          event.changes.sort((change1, change2) => change2.rangeOffset - change1.rangeOffset).forEach(change => {
            ytext.delete(change.rangeOffset, change.rangeLength)
            ytext.insert(change.rangeOffset, change.text)
          })
        }, this)
      })
    })
    this._monacoDisposeHandler = monacoModel.onWillDispose(() => {
      this.destroy()
    })
  }

  destroy () {
    this.unsubscribe()
    this._monacoChangeHandler.dispose()
    this._monacoDisposeHandler.dispose()
  }
}